import outSolutionVideo1 from "../../assets/ourSolutionVideo1.svg";
import outSolutionVideo2 from "../../assets/ourSolutionVideo2.svg";

function SolutionVideoCrm({ videoSrc1, videoTitle1, videoSrc2, videoTitle2, videoSrc3, videoTitle3 }) {
    return (
        <section className="py-20 custom-container">
            <div className="flex flex-col items-center justify-center gap-x-10 gap-y-4">
                <div className="flex flex-col items-center justify-center  gap-3 lg:max-w-[800px] max-w-[400px] font-['lato']">
                    <h1 className="flex gap-2 text-4xl font-semibold lg:text-5xl">
                        <div>Our</div>
                        <div className="text-primary">Solution</div>
                        <div>Video</div>
                    </h1>
                    <p className="">
                        Watch our videos to see our solution in action and learn how they can
                        benefit you.
                    </p>
                </div>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mt-5 items-center justify-center max-w-full custom-gap w-[100%]">
                    <div className="flex flex-col items-center">
                        <iframe
                            src={videoSrc1}
                            title={videoTitle1}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                            className="w-full rounded-lg h-[200px] sm:h-[250px] lg:h-[350px]"
                        ></iframe>
                        <p className="mt-2 lg:mb-0 mb-3 text-center">({videoTitle1})</p>
                    </div>

                    <div className="flex flex-col items-center">
                        <iframe
                            src={videoSrc2}
                            title={videoTitle2}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                            className="w-full rounded-lg h-[200px] sm:h-[250px] lg:h-[350px]"
                        ></iframe>
                        <p className="mt-2 lg:mb-0 mb-3 text-center">({videoTitle2})</p>
                    </div>

                    <div className="flex flex-col items-center">
                        <iframe
                            src={videoSrc3}
                            title={videoTitle3}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                            className="w-full rounded-lg h-[200px] sm:h-[250px] lg:h-[350px]"
                        ></iframe>
                        <p className="mt-2 text-center">({videoTitle3})</p>
                    </div>
                </div>


            </div>
        </section>
    );
}

export default SolutionVideoCrm;
