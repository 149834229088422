import NavBar from "./components/NavBar.component";
import Slider from "./components/Slider.component";
import Backgrounds from "./components/Career/Backgrounds.component";
import JoinUs from "./components/Career/JoinUs.component";
import JoiningProcess from "./components/Career/JoiningProcess.component";
import CurrentOpening from "./components/Career/CurrentOpening.component";
import ApplyHere from "./components/Career/ApplyHere.component";
import Footer from "./components/Footer.component";

import Banner7 from "./assets/Banner7.svg";
import { useEffect, useRef } from "react";
import CustomScrollbar from "./components/CustomScrollbar.component";

function Career() {
    const sliderRef = useRef(null);

    // Scroll to slider on first render
    useEffect(() => {
        if (sliderRef.current) {
            window.scrollTo({
                top: sliderRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    }, []);
    return (
        <section className="">
            {/* NAVIGATION BAR */}
            <section className="">
                <NavBar />
            </section>

            {/* NAV-CONTACT */}
            <section>{/* <NavContact/> */}</section>

            {/* HERO SLIDER */}
            <section ref={sliderRef}>
                <Slider images={[Banner7]} />
            </section>

            {/* BACKGROUND */}
            <section>
                <Backgrounds />
            </section>

            {/* JOIN US */}
            <section>
                <JoinUs />
            </section>

            {/* JOINING PROCESS */}
            <section>
                <JoiningProcess />
            </section>

            {/* CURRENT OPENING */}
            <section>
                <CurrentOpening />
            </section>

            {/* APPLY HERE */}
            <section>
                <ApplyHere />
            </section>

            {/* FOOTER */}
            <section>
                <Footer />
            </section>

            <CustomScrollbar />
        </section>
    );
}

export default Career;
