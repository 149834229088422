// MUI
import * as React from "react";
import Button from "@mui/material/Button";
import { BookDemoAside, open } from "../BookDemoAside.component";

function BookYourDemo() {
    return (
        <div className="w-full py-20 custom-container text-primary bg-[url('./pages/assets/bg1.svg')] bg-center">
            <h1 className="pt-10 text-left text-4xl font-['lato']">
                LET'S BRING YOUR PROJECT TO LIFE !
            </h1>
            <p className="mt-5 font-['lato'] max-w-[50%] md:max-w-[500px] ">
                Contact us today to see how our expert team and cutting-edge software solutions can
                help you achieve your goals.
            </p>
            <div className="my-8">
                <Button variant="contained" className="text-white capitalize bg-primary">
                    Book Your Demo
                    <div className="absolute top-0 left-0 w-full h-full" onClick={open}></div>
                </Button>
            </div>
            <BookDemoAside />
        </div>
    );
}

export default BookYourDemo;
