import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need1 from "../../assets/need-1.svg";
import NeedImg1 from "../../assets/NeedImg1.svg";
import BasicCrmSales from './BasicCrmSales.component'
import BasicCrmService from './BasicCrmService.component'
import SswayamCrmPro from './SswayamCrmPro.component'
import Clientele from '../Home/Clientele.component'
import SolutionVideoCrm from './SolutionVideoCrm.component'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;
export default function SswayamCrm() {
    // let scrollRef = useRef();
    // const serviceBoxRef = useRef(null);

    // // Scroll to the ServiceBox section when the page loads
    // useEffect(() => {
    //     serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    // }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section>
                    <BasicCrmSales />
                    <BasicCrmService />
                    <SswayamCrmPro />
                </section>

                {/* SOLUTION VIDEO */}
                <section>
                    <SolutionVideoCrm
                        videoSrc1="https://www.youtube.com/embed/9oAO2tMpw2U"
                        videoTitle1="Basic Sswayam CRM (Sales)"

                        videoSrc2="https://www.youtube.com/embed/84TMoz6bsew"
                        videoTitle2="Basic Sswayam CRM (Service)"

                        videoSrc3="https://www.youtube.com/embed/lURUkpOVa7M"
                        videoTitle3="Sswayam CRM Pro+"
                    />
                </section>

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
