import * as React from "react";
import Button from "@mui/material/Button";

import Service1 from "../../assets/service-1.svg";
import Service2 from "../../assets/service-2.svg";
import Service3 from "../../assets/service-3.svg";
import Service4 from "../../assets/service-4.svg";
import Service5 from "../../assets/service-5.svg";
import Service6 from "../../assets/service-6.svg";
import Service7 from "../../assets/service-7.svg";
import Service8 from "../../assets/service-8.svg";
import { useNavigate } from "react-router-dom";

function ServiceTab(props) {

    const navigate = useNavigate(); // React Router hook for navigation

    function clickHandlerSubmit(link) {
        if (link && link.startsWith("/")) {
            // Internal link: Use React Router's navigate to open in the same page
            navigate(link);
        } else {
            // External link: Open in a new tab
            window.open(link || "https://youtu.be/HcqblRPsBes", "_blank");
        }
    }

    const videoLinks = {
        "AWS": "https://www.youtube.com/watch?v=HhlWDXjX9kM&t=11s",
        "Data Science": "https://www.youtube.com/watch?v=WyJ-cBPiGxU&t=9s",
        "Advanced Analytics": "https://www.youtube.com/watch?v=xfnT2mLaNuQ&t=12s",
        "Iot/Robotics": "https://www.youtube.com/watch?v=8MVQkTVC8Pw&t=3s",
        "Basic Analytics": "https://www.youtube.com/watch?v=opTn9otZMXY",
        "Mobile Application": "https://www.youtube.com/watch?v=x7zr_kCujAs&t=3s",
        "Consulting": "https://www.youtube.com/watch?v=ITyukBvhEVU&t=13s",
        "Web Application": "https://www.youtube.com/watch?v=DkmpIZP-Ohw"
    };

    function clickHandlerSubmitVideo(serviceTitle) {
        const link = videoLinks[serviceTitle] || "https://youtu.be/HcqblRPsBes";
        window.open(link, "_blank");
    }

    if (props.side === "left") {
        return (
            <div className="flex flex-row-reverse max-w-[500px] my-5">
                <div className="flex">
                    <img src={props.icon} className="min-w-[70px]" alt="" />
                </div>
                <div className="flex flex-col items-end mx-5">
                    <h2 className="font-bold text-primary font-['lato']">{props.title}</h2>
                    <p className="my-2 text-xs text-right font-['montserrat']">{props.content}</p>
                    <div className="flex flex-row-reverse justify-between w-full">
                        <button
                            className="text-xs text-gray-500 border-b-2 border-gray-500 press"
                            onClick={() => clickHandlerSubmit(props.href)}
                        >
                            See More
                        </button>
                        <Button
                            variant="outlined"
                            size="small"
                            className="py-1 text-xs capitalize border-primary text-primary whitespace-nowrap"
                        >
                            Watch Video
                            <div
                                className="absolute top-0 left-0 w-full h-full"
                                onClick={() => clickHandlerSubmitVideo(props.title)}
                            ></div>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
    if (props.side === "right") {
        return (
            <div className="flex max-w-[500px] my-5">
                <div className="flex">
                    <img src={props.icon} className="min-w-[70px]" alt="" />
                </div>
                <div className="flex flex-col mx-5">
                    <h2 className="font-bold text-primary font-['lato']">{props.title}</h2>
                    <p className="my-2 text-xs font-['montserrat']">{props.content}</p>
                    <div className="flex justify-between">
                        <button
                            className="text-xs text-gray-500 border-b-2 border-gray-500 press"
                            onClick={() => clickHandlerSubmit(props.href)}
                        >
                            See More
                        </button>
                        <Button
                            variant="outlined"
                            size="small"
                            className="py-1 text-xs capitalize border-primary text-primary whitespace-nowrap"
                        >
                            Watch Video
                            <div
                                className="absolute top-0 left-0 w-full h-full"
                                onClick={() => clickHandlerSubmitVideo(props.title)}
                            ></div>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

function Service() {
    return (
        <div className="py-20 h-fit custom-container">
            <h1 className="text-center heading font-['lato']">SERVICES</h1>
            <p className="mt-2 text-center mx-auto max-w-[1000px] text-sm font-['montserrat']">
                Accelerate your progress with all the tools you need in one go. Your one-stop-shop,
                Analytics Valley, offers a wide range of services to help you advance throughout
                time.
            </p>

            {/* DESKTOP */}
            <div className="items-stretch justify-center hidden mt-10 md:flex">
                {/* LEFT */}
                <div>
                    <ServiceTab
                        side="left"
                        icon={Service1}
                        title="AWS"
                        content="Integrate cloud computing services that allow businesses to scale and manage their IT infrastructure more efficiently and cost-effectively."
                        href="/services/aws" 
                    ></ServiceTab>
                    <ServiceTab
                        side="left"
                        icon={Service2}
                        title="Advanced Analytics"
                        content="Leverage machine learning and statistical techniques to analyze large and complex datasets, providing valuable insights for informed decision-making."
                        href="/services/advanced-analytics"
                    ></ServiceTab>
                    <ServiceTab
                        side="left"
                        icon={Service3}
                        title="Basic Analytics"
                        content="Get simple data analysis techniques like descriptive statistics to understand trends, patterns, and relationships in data."
                        href="/services/basic-analytics"
                    ></ServiceTab>
                    <ServiceTab
                        side="left"
                        icon={Service4}
                        title="Consulting"
                        content="Plan, implement, and manage IT projects, ensuring alignment with business goals and maximizing return on investment."
                        href="/services/consulting"
                    ></ServiceTab>
                </div>
                {/* CENTER */}
                <div className="relative flex justify-center mx-5">
                    <div className="absolute w-[2px] h-full bg-primary"></div>
                    <div className="flex flex-col justify-around h-full">
                        <div
                            className="w-[20px] h-[20px] rounded-full bg-primary"
                            icon={""}
                            title=""
                            content=""
                        ></div>
                        <div
                            className="w-[20px] h-[20px] rounded-full bg-primary"
                            icon={""}
                            title=""
                            content=""
                        ></div>
                        <div
                            className="w-[20px] h-[20px] rounded-full bg-primary"
                            icon={""}
                            title=""
                            content=""
                        ></div>
                        <div
                            className="w-[20px] h-[20px] rounded-full bg-primary"
                            icon={""}
                            title=""
                            content=""
                        ></div>
                    </div>
                </div>
                {/* RIGHT */}
                <div>
                    <ServiceTab
                        side="right"
                        icon={Service5}
                        title="Data Science"
                        content="Visualize data for better decision-making, providing expertise in data analysis, modeling, and science."
                        href="/services/data-science"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service6}
                        title="Iot/Robotics"
                        content="Automate tasks, enhance efficiency, improve safety, and create new possibilities in fields like manufacturing, healthcare, and exploration."
                        href="/services/iot-robotics"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service7}
                        title="Mobile Application"
                        content="Increase customer engagement, enhance user experience, provide convenience, and open new revenue streams for businesses in the digital age."
                        href="/services/mobile-application"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service8}
                        title="Web Application"
                        content="Access seamless scalability, cross-platform compatibility, and cost-effective development for businesses to reach a wider audience."
                        href="/services/web-application"
                    ></ServiceTab>
                </div>
            </div>

            {/* MOBILE */}
            <div className="flex items-stretch justify-center mt-10 md:hidden">
                <div className="relative flex justify-center mx-5">
                    <div className="absolute w-[2px] h-full bg-primary"></div>
                    <div className="flex flex-col justify-around h-full">
                        <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
                        <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
                        <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
                        <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
                        <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
                        <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
                        <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
                        <div className="w-[20px] h-[20px] rounded-full bg-primary"></div>
                    </div>
                </div>
                <div>
                    <ServiceTab
                        side="right"
                        icon={Service1}
                        content="Integrate cloud computing services that allow businesses to scale and manage their IT infrastructure more efficiently and cost-effectively."
                        title="AWS"
                        href="/services/aws" 
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service2}
                        content="Leverage machine learning and statistical techniques to analyze large and complex datasets, providing valuable insights for informed decision-making."
                        title="Advanced Analytics"
                        href="/services/advanced-analytics"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service3}
                        content="Get simple data analysis techniques like descriptive statistics to understand trends, patterns, and relationships in data."
                        title="Basic Analytics"
                        href="/services/basic-analytics"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service4}
                        content="Plan, implement, and manage IT projects, ensuring alignment with business goals and maximizing return on investment."
                        title="Consulting"
                        href="/services/consulting"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service5}
                        content="Visualize data for better decision-making, providing expertise in data analysis, modeling, and science."
                        title="Data Science"
                        href="/services/data-science"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service6}
                        content="Automate tasks, enhance efficiency, improve safety, and create new possibilities in fields like manufacturing, healthcare, and exploration."
                        title="Iot/Robotics"
                        href="/services/iot-robotics"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service7}
                        content="Increase customer engagement, enhance user experience, provide convenience, and open new revenue streams for businesses in the digital age."
                        title="Mobile Application"
                        href="/services/mobile-application"
                    ></ServiceTab>
                    <ServiceTab
                        side="right"
                        icon={Service8}
                        content="Access seamless scalability, cross-platform compatibility, and cost-effective development for businesses to reach a wider audience."
                        title="Web Application"
                        href="/services/web-application"
                    ></ServiceTab>
                </div>
            </div>
        </div>
    );
}

export default Service;
