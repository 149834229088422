import { useEffect } from "react";

function CustomSelect1(props) {
    useEffect(function () {
        props.setState(-1);
    }, []);

    return (
        <div className="flex flex-col">
            <label htmlFor="id" className="[font-weight:500;]">
                {props.label}
            </label>
            <div className="px-2 py-2 mt-2 text-xs border rounded">
                <select
                    id="id"
                    className={` ${props.className} outline-none w-full bg-transparent `}
                    value={props.state}
                    onChange={(e) => props.setState(e.target.value)}
                >
                    <option className="text-gray-400" value="-1">
                        {props.placeholder}
                    </option>
                    {props.children}
                </select>
            </div>
        </div>
    );
}

export default CustomSelect1;
