import WhatYouGet1 from "../../assets/WhatYouGet1.svg";
import WhatYouGet2 from "../../assets/WhatYouGet2.svg";
import WhatYouGet3 from "../../assets/WhatYouGet3.svg";
import WhatYouGet4 from "../../assets/WhatYouGet4.svg";
import WhatYouGet5 from "../../assets/WhatYouGet5.svg";
import WhatYouGet6 from "../../assets/WhatYouGet6.svg";

function WhatYouGet() {
    function Card(props) {
        return (
            <div className="flex gap-3">
                <div>
                    <img src={props.icon} alt="" className="h-[100px]" />
                </div>
                <div>
                    <h1 className="font-semibold tex-2xl text-primary font-['lato']">
                        {props.title}
                    </h1>
                    <p className="w-[250px] text-xs mt-2 font-['montserrat']">
                        {props.content}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <section>
            <div className="py-16 custom-container">
                <h1 className="text-center heading font-['lato']">
                    What you get
                </h1>
                <p className="text-center font-['Montserrat'] text-sm mt-2">
                    {" "}
                    Innovative business solutions for your unique challenges
                </p>
                <div className="flex flex-wrap justify-center mt-10 gap-x-5 gap-y-16">
                    <Card
                        icon={WhatYouGet1}
                        title="Dashboard"
                        content="We bring you an interface that displays key performance indicators (KPIs) and other relevant data in an organized and visually appealing manner."
                    />
                    <Card
                        icon={WhatYouGet2}
                        title="Expense Data Management"
                        content="We collect, organize and analyze expense-related data to monitor and control expenses effectively."
                    />
                    <Card
                        icon={WhatYouGet3}
                        title="Client Data Management"
                        content="We organize and maintain information about clients to ensure effective communication and relationship management"
                    />
                    <Card
                        icon={WhatYouGet4}
                        title="Ticket Data Management"
                        content="We systematically handle information generated from customer interactions, such as inquiries, issues, complaints, or requests for assistance.
                        "
                    />
                    <Card
                        icon={WhatYouGet5}
                        title="Administration"
                        content="We process the activities involved in managing and maintaining a software application, including user management, security, and system configuration."
                    />
                    <Card
                        icon={WhatYouGet6}
                        title="Contract Data Management"
                        content="We create and manage legal contracts and related documents to ensure compliance and mitigate risks."
                    />
                </div>
            </div>
        </section>
    );
}

export default WhatYouGet;
