import React from 'react';

function OurAddress() {
    return (
        <section>
            <div className="py-16 custom-container px-4 md:px-0">
                <h1 className="text-center heading text-2xl md:text-3xl lg:text-4xl font-bold mb-12">Our Address</h1>
                <div className=" custom-container grid grid-cols-1 gap-y-12 gap-x-8 md:grid-cols-2 lg:grid-cols-[60%_40%]">
                    <div className="overflow-hidden space-y-8">
                        {/* India Office Map */}
                        <div className="map-container">
                            <h2 className="text-lg md:text-xl font-bold mb-4">India Office Location</h2>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2365961717383!2d77.69572287585702!3d12.95670671520309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13cebb953861%3A0x26aa2b10287f88d9!2sSigma%20Arcade!5e0!3m2!1sen!2sin!4v1726202607016!5m2!1sen!2sin"
                                width="100%"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                        {/* USA Office Map */}
                        <div className="map-container">
                            <h2 className="text-lg md:text-xl font-bold mb-4">USA Office Location</h2>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3302.258929986038!2d-84.247568!3d34.1397171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f59dbd3f9379b1%3A0x9e6f7525ced63443!2s5905%20Atlanta%20Hwy%20101%2088%2C%20Alpharetta%2C%20GA%2030004%2C%20USA!5e0!3m2!1sen!2sin!4v1726207625895!5m2!1sen!2sin"
                                width="100%"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                    <div className="flex flex-col justify-around  space-y-10">
                        {/* India Office Details */}
                        <div>
                            <h1 className="text-xl md:text-2xl font-bold">India (Head Office)</h1>
                            <p className="mt-2 text-md md:text-lg">
                                #12, Sigma Arcade, 1st Floor, Next to Tulsi Theater Marathahalli,
                                Bangalore - 560037
                            </p>
                            <p className="mt-2">
                                <span className="font-semibold">Email:</span> enquiry@analyticsvalley.com
                            </p>
                            <p>
                                <span className="font-semibold">Phone:</span> +91 97273 97555
                            </p>
                        </div>
                        {/* USA Office Details */}
                        <div>
                            <h1 className="text-xl md:text-2xl font-bold">USA</h1>
                            <p className="mt-2 text-md md:text-lg">
                                5905 Atlanta Highway, Suite 101 #88, Alpharetta GA 30004 USA
                            </p>
                            <p className="mt-2">
                                <span className="font-semibold">Email:</span> info@analyticsvalley.com
                            </p>
                            <p>
                                <span className="font-semibold">Phone:</span> +1678-231-0583
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurAddress;
