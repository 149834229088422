// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";

// Import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectCreative } from "swiper";
import CustomButton1 from "./CustomButton1.component";
import { open } from "./BookDemoAside.component";

function Slider({ images, showDemoButton }) {
    return (
        <div className="lg:h-[86vh] mt-[14vh]">
            <Swiper
                modules={[Autoplay, Pagination, EffectCreative]}
                grabCursor={true}
                centeredSlides={true}
                speed={5000}
                loop={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                effect={"creative"}
                creativeEffect={{
                    prev: {
                        shadow: true,
                        translate: [0, 0, -400],
                    },
                    next: {
                        translate: ["100%", 0, 0],
                    },
                }}
                className="mySwiper"
            >
                {images.map((image, index) => {
                    return (
                        <SwiperSlide
                            key={index}
                            className="relative flex items-center justify-center lg:h-[86vh]"
                        >
                            <img
                                src={image}
                                alt=""
                                className="object-fill w-full h-full"
                            />
                            {showDemoButton && (
                                <div className="absolute bottom-10 custom-button-left w-full flex z-10">
                                    <CustomButton1
                                        label={<div className="mx-2 my-1 custom-button-left-font ">Book Your Demo</div>}
                                        className="bg-primary custom-button-left-size "
                                        onClick={open}
                                    />
                                </div>
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}

export default Slider;
