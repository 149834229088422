import CustomChecBox1 from "../CustomCheckBox1";
import CustomButton1 from "../CustomButton1.component";
import CustomFileUpload1 from "../CustomFileUpload1.component";

import benefit1 from "../../assets/benefits1.svg";
import benefit2 from "../../assets/benefits2.svg";

import { BiSearchAlt2 } from "react-icons/bi";
import CloudUpload from "../../assets/cloud-upload.svg";

import { useState, useRef } from "react";
import configObj from "../../email/config.js";
import ReCAPTCHA from "react-google-recaptcha";

import {
    validateSelectOpetion,
    validateString,
    validateEmail,
    validePhoneNumber,
    validateFile,
    validateCaptcha,
} from "../../validation/validation.js";

import toast from "react-hot-toast";

function JoinUs() {
    let [checkbox1, setCheckbox1] = useState(false);
    let [checkbox2, setCheckbox2] = useState(false);
    let [checkbox3, setCheckbox3] = useState(false);
    let [checkbox4, setCheckbox4] = useState(false);
    let [checkbox5, setCheckbox5] = useState(false);

    let [checkbox6, setCheckbox6] = useState(false);
    let [checkbox7, setCheckbox7] = useState(false);
    let [checkbox8, setCheckbox8] = useState(false);
    let [checkbox9, setCheckbox9] = useState(false);
    let [checkbox10, setCheckbox10] = useState(false);

    let [checkbox11, setCheckbox11] = useState(false);
    let [checkbox12, setCheckbox12] = useState(false);
    let [checkbox13, setCheckbox13] = useState(false);
    let [checkbox14, setCheckbox14] = useState(false);
    let [checkbox15, setCheckbox15] = useState(false);

    let [cv, setCv] = useState();
    let [cvName, setCvName] = useState();

    let recaptchaRef = useRef();
    let recaptchaContainerRef = useRef();

    if (cvName && recaptchaContainerRef) {
        recaptchaContainerRef.current.style.display = "block";
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    async function sendMail() {
        let dateTime = new Date();
        let date = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        let time = `${dateTime.getHours()}:${dateTime.getMinutes()}`;

        let cvBase64 = await toBase64(cv);

        let body = `
            <h1>Job Application</h1>
            <p>Date: ${date}</p>
            <p>Time: ${time}</p>
        `;

        let mailstatus = window.Email.send({
            ...configObj,
            Subject: "Drop your CV [form website] ",
            Body: body,
            Attachments: [
                {
                    name: cvName,
                    data: cvBase64,
                },
            ],
        });

        toast.promise(
            mailstatus,
            {
                loading: "Sending Email...",
                success: "Email sent successfully, Analytic Valley Team will contact you soon.",
                error: "Failed to send Email",
            },
            {
                duration: 10000,
            }
        );

        mailstatus.then((status) => {
            if (status === "OK") {
                setCv("");
                setCvName("");
            }
        });
    }

    function clickHandlerSubmit() {
        if (!validateFile("Please upload CV", cv)) return;
        if (!validateCaptcha("Please fill the Captcha", recaptchaRef.current.getValue())) return;

        sendMail();
    }

    return (
        <>
            <section className="font-['lato']">
                {/* BENIFITS */}
                <section>
                    <div className="py-16 bg-[#cce7ef] custom-container">
                        <h1 className="text-4xl text-center">Benefits at Analytics valley</h1>
                        <div className="flex justify-center gap-5 mt-10 md:gap-16 lg:gap-20">
                            {/* LEARING & DEVELOPMENT */}
                            <div className="flex flex-col items-center max-w-[300px]">
                                <img src={benefit1} className="w-[50px]" alt="benefit1" />
                                <h2 className="text-xl text-center">Learning & Development</h2>
                                <p className="font-light text-center">
                                    Learning and Development- Improve skills and knowledge of
                                    employees to enhance performance and drive organizational growth
                                    and success
                                </p>
                            </div>

                            {/* EMPLOYEE RECOGNITION */}
                            <div className="flex flex-col items-center max-w-[300px]">
                                <img src={benefit2} className="w-[50px]" alt="benefit1" />
                                <h2 className="text-xl text-center">Employee Recognition</h2>
                                <p className="font-light text-center">
                                    Employee Recognition- Acknowledge employee contributions to
                                    boost morale, retention, and productivity, and foster a positive
                                    work culture.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* SEARCH + CATEGORY + OTHER*/}
                <section className="flex-col justify-center hidden py-5 bg-tertiary lg:row-span-2">
                    {/* SEARCH + CATEGORY */}
                    <div className="flex flex-wrap justify-center gap-7">
                        {/* SEARCH */}
                        <div className="w-[150px] flex items-center px-2 py-2 bg-primary justify-between">
                            <input
                                type="text"
                                placeholder="Search"
                                className="w-[165px] text-white bg-transparent outline-none placeholder:text-white"
                            />
                            <BiSearchAlt2 className="text-white" />
                        </div>

                        {/* CATEGORY */}
                        <div className="w-[150px] flex items-center px-2 py-2 bg-primary">
                            <select
                                className="text-white bg-transparent outline-none grow"
                                name=""
                                id=""
                            >
                                <option value="">Categories</option>
                            </select>
                        </div>
                    </div>

                    {/* OTHER */}
                    <div className="flex flex-wrap justify-center gap-8 mt-12 text-white lg:justify-between lg:px-10">
                        <div className="">
                            <h1 className="text-xl font-semibold">Data Posted</h1>
                            <div className="flex flex-col gap-3 mt-4">
                                <CustomChecBox1
                                    state={checkbox1}
                                    setState={setCheckbox1}
                                    label={"Last Hour"}
                                />
                                <CustomChecBox1
                                    state={checkbox2}
                                    setState={setCheckbox2}
                                    label={"Last 24 hours"}
                                />
                                <CustomChecBox1
                                    state={checkbox3}
                                    setState={setCheckbox3}
                                    label={"Last 7 days"}
                                />
                                <CustomChecBox1
                                    state={checkbox4}
                                    setState={setCheckbox4}
                                    label={"Last 14 days"}
                                />
                                <CustomChecBox1
                                    state={checkbox5}
                                    setState={setCheckbox5}
                                    label={"Last 30 days"}
                                />
                            </div>
                        </div>
                        <div className="">
                            <h1 className="text-xl font-semibold">Job Type</h1>
                            <div className="flex flex-col gap-3 mt-4">
                                <CustomChecBox1
                                    state={checkbox6}
                                    setState={setCheckbox6}
                                    label={"Contract Base"}
                                />
                                <CustomChecBox1
                                    state={checkbox7}
                                    setState={setCheckbox7}
                                    label={"Freelancer"}
                                />
                                <CustomChecBox1
                                    state={checkbox8}
                                    setState={setCheckbox8}
                                    label={"Full Time"}
                                />
                                <CustomChecBox1
                                    state={checkbox9}
                                    setState={setCheckbox9}
                                    label={"Remote"}
                                />
                                <CustomChecBox1
                                    state={checkbox10}
                                    setState={setCheckbox10}
                                    label={"Internship"}
                                />
                            </div>
                        </div>
                        <div className="">
                            <h1 className="text-xl font-semibold">Experience</h1>
                            <div className="flex flex-col gap-3 mt-4">
                                <CustomChecBox1
                                    state={checkbox11}
                                    setState={setCheckbox11}
                                    label={"Fresher"}
                                />
                                <CustomChecBox1
                                    state={checkbox12}
                                    setState={setCheckbox12}
                                    label={"+1 Year"}
                                />
                                <CustomChecBox1
                                    state={checkbox13}
                                    setState={setCheckbox13}
                                    label={"+2 Year"}
                                />
                                <CustomChecBox1
                                    state={checkbox14}
                                    setState={setCheckbox14}
                                    label={"+3 Year"}
                                />
                                <CustomChecBox1
                                    state={checkbox15}
                                    setState={setCheckbox15}
                                    label={"+5 Year"}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* JOIN US */}
                <section>
                    <div className="py-16 custom-container">
                        <h1 className="text-4xl text-center">Join Us</h1>
                        <p className="mt-10 font-['lato'] text-xl text-justify">
                            Our joining process is designed to make your transition as smooth as
                            possible. From application to onboarding, we offer streamlined steps for
                            seamless integration into our team. We provide clear guidance and
                            support throughout the process, ensuring that you have all the
                            information and resources you need to succeed. Join our team with
                            confidence, knowing that we prioritize your success from day one.
                        </p>
                        <div className="flex flex-col items-center mt-5 gap-y-2">
                            <img src={CloudUpload} className="w-[40px]" alt="" />
                            <CustomFileUpload1
                                label1=""
                                label2={
                                    <div className="flex items-center justify-center text-white w-[200px]">
                                        Drop Your CV Here
                                    </div>
                                }
                                className="mt-2 text-white bg-primary"
                                state={cv}
                                setState={setCv}
                                fileName={
                                    cvName ? (
                                        <div className="flex items-center justify-center text-white w-[200px]">
                                            {cvName}
                                        </div>
                                    ) : null
                                }
                                setFileName={setCvName}
                            />
                            <div ref={recaptchaContainerRef} className="hidden">
                                <div className="flex justify-center w-full col-span-2 mt-5">
                                    <ReCAPTCHA
                                        sitekey="6LchLL8mAAAAAC4y_7vYBWdN-U1z0OYHWMmOVKXz"
                                        ref={recaptchaRef}
                                    />
                                </div>
                                <div className="flex justify-center col-span-2 mt-5">
                                    <CustomButton1
                                        label="Send Message"
                                        className="text-white bg-primary"
                                        onClick={clickHandlerSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}

export default JoinUs;
