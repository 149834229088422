import React, { useEffect, useRef, useState } from 'react'
import { FaAngleUp } from "react-icons/fa6";

export default function CustomScrollbar() {
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    // Scroll to slider on first render
    useEffect(() => {
        // Handle scroll events
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            {/* SCROLL TO TOP ICON */}
            {showScrollToTop && (
                <div
                    onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        cursor: 'pointer',
                        background: 'lightgray',
                        borderRadius: '50%',
                        padding: '10px',
                        fontSize:"20px"
                    }}
                >
                    <FaAngleUp />
                </div>
            )}
        </>
    )
}
