import React, { useEffect, useRef, useState } from 'react'
import NavBar from '../NavBar.component';
import Slider from '../Slider.component';
import BookDemo from '../BookDemo.component';
import Footer from '../Footer.component';
// import Banner5 from "../../assets/SolutionsImage/40.jpg"
import Banner5 from "../../assets/Banner5.svg"
import ServiceBox6 from "../../assets/ServiceBox6.svg";
import Bg5 from "../../assets/bg5.svg";
import ServiceBox from '../ServiceBox.component';
import ServiceSlider from './ServiceSlider.component';
import ServicesVideo from './ServicesVideo.component';
import CustomScrollbar from '../CustomScrollbar.component';
let css = ``;

export default function Lot_Robotics() {
  const serviceBoxRef = useRef(null);

  // Scroll to the ServiceBox section when the page loads
  useEffect(() => {
    serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <section className="">
      {/* NAVIGATION BAR */}
      <section className="">
        <NavBar />
      </section>

      {/* NAV-CONTACT */}
      <section>{/* <NavContact/> */}</section>

      {/* HERO SLIDER */}
      <section>
        <Slider images={[Banner5]} showDemoButton={true} />
      </section>

      {/* BOOK DEMO */}
      {/* <section>
        <BookDemo
          bgImage={Bg5}
          title={
            <>
              Book a <span className="text-primary">Demo</span>{" "}
            </>
          }
        />
      </section> */}

      {/* SERVICE */}
      <section ref={serviceBoxRef}>
        <style>{css}</style>
        <section>
          <style>{css}</style>
          <div className="py-20 custom-container">
            <h1 className="text-center heading pt-5">SERVICES</h1>
            <p className="mx-auto max-w-[700px] text-center text-sm mt-2 font-['montserrat']">
              Accelerate your progress with all the tools you need in one go. Your
              one-stop-shop, Analytics Valley, offers a wide range of services to help you
              advance throughout time.
            </p>
            <div className="mt-16">
              <ServiceBox
                thumbnail={ServiceBox6}
                position={"right"}
                title={"Iot/Robotics"}
                content={
                  "We provide technologies that enable the interconnection and automation of physical devices and machines. LoT involves connecting everyday objects to the internet to collect and exchange data, enabling remote monitoring and control.Robotics encompasses the design, development, and operation of robots that can perform tasks autonomously or with minimal human intervention. These technologies have vast applications in sectors such as manufacturing, healthcare, agriculture, and logistics, driving efficiency, productivity, and innovation."
                }
              />
            </div>

            <section>
              <ServicesVideo
                videoSrc="https://www.youtube.com/embed/8MVQkTVC8Pw"
                videoTitle="IoT and Robotics Overview"
              />
            </section>

            <div className='pt-10'>
              <ServiceSlider />
            </div>
          </div>
        </section>
      </section>

      {/* FOOTER */}
      <section>
        <Footer />
      </section>

      <CustomScrollbar />
    </section>
  );
}
