import getInTouch1 from "../../assets/get-in-touch-1.svg";

import CloudUpload from "../../assets/cloud-upload.svg";
import CustomTextField1 from "../CustomTextField2.component";
import CustomFileUpload1 from "../CustomFileUpload1.component";
import CustomCaptcha2 from "../CustomCaptcha2.component";
import CustomButton1 from "../CustomButton1.component";

import configObj from "../../email/config.js";
import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import {
    validateSelectOpetion,
    validateString,
    validateEmail,
    validePhoneNumber,
    validateFile,
    validateCaptcha,
} from "../../validation/validation.js";
import toast from "react-hot-toast";

function GetInTouch() {
    let [name, setName] = useState();
    let [phone, setPhone] = useState();
    let [email, setEmail] = useState();
    let [message, setMessage] = useState();

    const recaptchaRef = useRef();

    async function sendMail() {
        let dateTime = new Date();
        let date = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        let time = `${dateTime.getHours()}:${dateTime.getMinutes()}`;

        let body = `
            <h1>Get In Touch</h1>
            <p>Date: ${date}</p>
            <p>Time: ${time}</p>
            <p>Name: ${name}</p>
            <p>Phone: ${phone}</p>
            <p>Email: ${email}</p>
            <p>Message: ${message}</p>
        `;

        let mailstatus = window.Email.send({
            ...configObj,
            Subject: "Get In Touch [form website] ",
            Body: body,
        });

        toast.promise(
            mailstatus,
            {
                loading: "Sending Email...",
                success: "Email sent successfully, Analytic Valley Team will contact you soon.",
                error: "Failed to send Email",
            },
            {
                duration: 10000,
            }
        );

        mailstatus.then((status) => {
            if (status === "OK") {
                setName("");
                setPhone("");
                setEmail("");
                setMessage("");
            }
        });
    }

    function clickHandlerSubmit() {
        if (!validateString("Please enter valid Name", name, 3, 50)) return;
        if (!validePhoneNumber("Please enter a valid Phone number", phone)) return;
        if (!validateEmail("Please enter valid Email Address", email)) return;
        if (!validateString("Message lenght should be between 10 to 1000 character", name, 3, 50))
            return;
        if (!validateCaptcha("Please fill the Captcha", recaptchaRef.current.getValue())) return;

        sendMail();
    }

    return (
        <section className="grid lg:grid-cols-2">
            <div className="py-16 custom-container bg-primary">
                <h1 className="text-center text-white heading">Get In Touch</h1>
                <div className="grid grid-cols-2 gap-5 mt-10 text-white gap-y-7">
                    <CustomTextField1
                        label="Your Full Name"
                        placeholder="First Last"
                        state={name}
                        setState={setName}
                    />
                    <CustomTextField1
                        label="Phone Number"
                        placeholder="Enter"
                        state={phone}
                        setState={setPhone}
                    />
                    <div className="col-span-2">
                        <CustomTextField1
                            label="Email"
                            placeholder="Enter"
                            state={email}
                            setState={setEmail}
                        />
                    </div>

                    <div className="col-span-2">
                        <CustomTextField1
                            label="Message"
                            placeholder="Enter"
                            state={message}
                            setState={setMessage}
                        />
                    </div>
                    <div className="flex justify-center w-full col-span-2 mt-5">
                        <ReCAPTCHA
                            sitekey="6LchLL8mAAAAAC4y_7vYBWdN-U1z0OYHWMmOVKXz"
                            ref={recaptchaRef}
                        />
                    </div>

                    <div className="flex justify-center col-span-2 mt-2">
                        <CustomButton1
                            label="Send Message"
                            className="text-black bg-white"
                            onClick={clickHandlerSubmit}
                        />
                    </div>
                </div>
            </div>
            <div className="hidden overflow-hidden lg:block">
                <img src={getInTouch1} className="object-cover w-full h-full" alt="" />
            </div>
        </section>
    );
}

export default GetInTouch;
