import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";

import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectCreative } from "swiper";
import ServiceBox1 from "../../assets/ServiceBox1.svg";
import ServiceBox2 from "../../assets/ServiceBox2.svg";
import ServiceBox3 from "../../assets/ServiceBox3.svg";
import ServiceBox4 from "../../assets/ServiceBox4.svg";
import ServiceBox5 from "../../assets/ServiceBox5.svg";
import ServiceBox6 from "../../assets/ServiceBox6.svg";
import ServiceBox7 from "../../assets/ServiceBox7.svg";
import ServiceBox8 from "../../assets/ServiceBox8.svg";
import ServiceBoxLinks from './ServiceBoxLinks.component';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom

export default function ServiceSlider() {
    const location = useLocation(); // Get the current URL
    const currentPath = location.pathname;

    return (
        <>
            <Swiper
                modules={[Autoplay, Pagination, EffectCreative]}
                grabCursor={true}
                slidesPerView={5} // Default for larger screens
                spaceBetween={30}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                className="mySwiper"
                speed={2000}
                loop={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                slidesPerGroup={1}
                breakpoints={{
                    // When the window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    // When the window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    // When the window width is >= 768px
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    // When the window width is >= 1024px
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    // When the window width is >= 1280px
                    1480: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },

                    1680: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },

                    1880: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                    },
                }}
            >
                <div className="p-5">
                    {currentPath !== '/services/aws' && (
                        <SwiperSlide>
                            <ServiceBoxLinks
                                img={ServiceBox1}
                                title="AWS"
                                content="We provide a comprehensive cloud computing..."
                                redirectUrl="/services/aws"
                            />
                        </SwiperSlide>
                    )}

                    {currentPath !== '/services/advanced-analytics' && (
                        <SwiperSlide>
                            <ServiceBoxLinks
                                img={ServiceBox2}
                                title="Advanced Analytics"
                                content="We provide sophisticated techniques and algorithms to extract valuable..."
                                redirectUrl="/services/advanced-analytics"
                            />
                        </SwiperSlide>
                    )}

                    {currentPath !== '/services/basic-analytics' && (
                        <SwiperSlide>
                            <ServiceBoxLinks
                                img={ServiceBox3}
                                title="Basic Analytics"
                                content="We provide sophisticated techniques and algorithms to..."
                                redirectUrl="/services/basic-analytics"
                            />
                        </SwiperSlide>
                    )}

                    {currentPath !== '/services/consulting' && (
                        <SwiperSlide>
                            <ServiceBoxLinks
                                img={ServiceBox4}
                                title="Consulting"
                                content="Consulting services involve providing expert advice and guidance..."
                                redirectUrl="/services/consulting"
                            />
                        </SwiperSlide>
                    )}

                    {currentPath !== '/services/data-science' && (
                        <SwiperSlide>
                            <ServiceBoxLinks
                                img={ServiceBox5}
                                title="Data Science"
                                content="Data science is an interdisciplinary field that combines..."
                                redirectUrl="/services/data-science"
                            />
                        </SwiperSlide>
                    )}

                    {currentPath !== '/services/iot-robotics' && (
                        <SwiperSlide>
                            <ServiceBoxLinks
                                img={ServiceBox6}
                                title="Iot/Robotics"
                                content="We provide technologies that enable the interconnection and automation of..."
                                redirectUrl="/services/iot-robotics"
                            />
                        </SwiperSlide>
                    )}

                    {currentPath !== '/services/mobile-application' && (
                        <SwiperSlide>
                            <ServiceBoxLinks
                                img={ServiceBox7}
                                title="Mobile Application"
                                content="A mobile application, commonly known as a mobile app, is a software..."
                                redirectUrl="/services/mobile-application"
                            />
                        </SwiperSlide>
                    )}

                    {currentPath !== '/services/web-application' && (
                        <SwiperSlide>
                            <ServiceBoxLinks
                                img={ServiceBox8}
                                title="Web Application"
                                content="A web application is a software application accessed and used through..."
                                redirectUrl="/services/web-application"
                            />
                        </SwiperSlide>
                    )}
                </div>

            </Swiper>
        </>
    );
}
