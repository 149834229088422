import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need7 from "../../assets/need-7.svg";
import NeedImg7 from "../../assets/NeedImg7.svg";
import Clientele from '../Home/Clientele.component'
import thumbImg from '../../assets/SolutionsImage/vectorImages/Online Tax Management/23.jpg'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function OnlineTaxManagement() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={6}
                                    icon={Need7}
                                    thumbnail={thumbImg}
                                    title={"Online Tax Management"}
                                    content={[
                                        `Our Online and offline tax management software is designed to help individuals and businesses manage their taxes and finance efficiently. While both types of software serve the same purpose, they differ in how they operate and the benefits they offer.`,
                                        `
                                       Online tax management software is cloud-based and accessible through the Internet. This software allows users to access their tax information from any device with an internet connection. Online software offers website and dashboard support to manage one-time services, manage recurring services admin, manage users, manage addresses, and online registered users; additionally, online software offers features such as e-filing management faster and more efficiently. With our online interface, you will get pan India access and an instant payment feature.`,
                                    ]}
                                    image={NeedImg7}
                                    caption={["CA Industry"]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>

                {/* SOLUTION VIDEO */}
                <section>
                    <SolutionVideo
                        videoSrc="https://www.youtube.com/embed/oJ_NIuUSijU"
                        videoTitle="Online Tax Management"
                    />
                </section>

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
