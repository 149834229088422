import Clientele1 from "../../assets/clientele-1.svg";
import Clientele2 from "../../assets/clientele-2.svg";

function Clientele(){
    return(
        <div className="py-20 bg-quaternary">
        <h1 className="text-center heading text-['lato']">CLIENTELE</h1>
        <p className="mt-2 text-sm text-center custom-container font-['montserrat'] ">
            Behind every great company lies a story, scroll and
            discover the clients who have made our success possible.
        </p>

        {/* MOBILE */}
        <img
            src={Clientele1}
            className="block mx-auto mt-16 md:hidden"
            alt=""
        />

        <img
            src={Clientele2}
            className="hidden mx-auto mt-16 md:block"
            alt=""
        />
    </div>
    )
}

export default Clientele;