import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need1 from "../../assets/need-1.svg";
import NeedImg1 from "../../assets/NeedImg1.svg";
import thumbImg from '../../assets/SolutionsImage/vectorImages/Swayam CRM Sales/fdgdf (1).jpg'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function BasicCrmSales() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={0}
                                    icon={Need1}
                                    thumbnail={thumbImg}
                                    title={"Basic Sswayam CRM (Sales)"}
                                    content={[
                                        "Sswayam CRM for Sales is a top-of-the-line CRM software designed specifically for sales teams. With this software, businesses can streamline their sales operations and keep everything unified in one platform. Managers can track the work of field agents, including expenses, daily sales reports, locations, leads collected, and more. The software provides real-time data and insights, empowering businesses to make data-driven decisions and improve their bottom line. Sswayam CRM is easy to use and has powerful features, including customizable dashboards, automated reports, and integrations with popular business tools.",
                                    ]}
                                    image={NeedImg1}
                                    caption={[
                                        "IT infrastructure support, Networking and Security.",
                                    ]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>
            </section>
        </>
    )
}
