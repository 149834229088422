import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const marks = [
    {
        value: 2,
    },
    {
        value: 3,
    },
    {
        value: 4,
    },
    {
        value: 5,
    },
    {
        value: 6,
    },
    {
        value: 7,
    },
    {
        value: 8,
    },
    {
        value: 9,
    },
    {
        value: 10,
    },
    {
        value: 11,
    },
    {
        value: 12,
    },
    {
        value: 13,
    },
    {
        value: 14,
    },
    {
        value: 15,
    },
    {
        value: 16,
    },
    {
        value: 17,
    },
    {
        value: 18,
    },
    {
        value: 19,
    },
    {
        value: 20,
    },
    {
        value: 21,
    },
    {
        value: 22,
    },
    {
        value: 23,
    },
];

function CustomRange1(props) {
    const [value, setValue] = React.useState([0, 24]);
    props.setState1(value[0]);
    props.setState2(value[1]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="flex flex-col">
            <label htmlFor="id" className="[font-weight:500;]">
                {props.label}
            </label>
            <Slider
                size="small"
                value={value}
                onChange={handleChange}
                className="mt-4"
                min={1}
                max={24}
                step={1}
                marks={marks}
                style={{
                    color: "#174997",
                }}
            />
        </div>
    );
}

export default CustomRange1;
