import { useRef } from "react";

import OurStory1 from "../../assets/OurStory1.svg";

let css = `
    .custom-gradient-1{
        background: linear-gradient(135deg, #aaafd8 0%, #9fd2f2 100%);
    }
`;

function OutStory() {
    let sectionRef = useRef(null);
    let clientSatisfactionRef = useRef(null);
    let projectCompleteRef = useRef(null);
    let started = false;

    function StartCount(element, goal, increment, decimalDegit, time) {
        let interval = (increment * time) / goal;

        let timer = setInterval(() => {
            element.textContent = (
                Number(element.textContent) + increment
            ).toFixed(decimalDegit);
            if (element.textContent >= goal) {
                clearInterval(timer);
            }
        }, interval);
    }

    window.addEventListener("scroll", function () {
        if (window.scrollY >= sectionRef.current?.offsetTop - 500 && !started) {
            started = true;
            StartCount(clientSatisfactionRef.current, 4.8, 0.1, 1, 6 * 1000);
            StartCount(projectCompleteRef.current, 150, 1, 0, 6 * 1000);
        }
    });

    return (
        <section ref={sectionRef}>
            <style>{css}</style>
            <div className="py-16 custom-container">
                <h1 className="text-center heading">Our Story</h1>
                <p className="text-sm font-['montserrat'] text-center max-w-[700px] mx-auto mt-2 ">
                    Get to know us: The faces behind our inspiring vision, our
                    purpose and our services to impart excellence and innovation
                    in the technology world
                </p>
                <div className="flex flex-col sm:flex-row gap-5 mt-16 md:gap-16 max-w-[1000px] mx-auto ">
                    <div className="">
                        <img
                            src={OurStory1}
                            className="min-w-[25vw] block mx-auto"
                            alt=""
                        />
                    </div>
                    <div className="">
                        <div className="text-xs lg:text-base font-['montserrat'] text-justify">
                            <p>
                                Analytics Valley is your answer to big data
                                challenges whether it is in providing real-time
                                data access, data integration from multiple
                                sources and in varied formats or deriving value
                                through business intelligence and data
                                analytics. Whether you operate in the domains of
                                Health Care, Telecom, Retail, Manufacturing and
                                Public Services, we leverage our cutting-edge
                                technologies and industry expertise to help your
                                organization unlock the business intelligence
                                and value from big data.
                            </p>
                            <p className="mt-4">
                                If you are looking to get a definitive edge
                                against your competitors in today's data-driven
                                economy, then look no further. We are a one-stop
                                shop to all your big data analytics solutions
                                and services, providing customised data
                                strategies and technologies to suit your
                                industry requirements. We collaborate with you
                                to capture and transform data for better market
                                insights thereby helping you serve your
                                customers better!
                            </p>
                        </div>
                        <div className="flex justify-around py-4 mt-10 rounded-tr-xl rounded-bl-xl custom-gradient-1">
                            <div>
                                <h1 className="text-4xl font-semibold text-center">
                                    <span ref={projectCompleteRef}>0</span>+
                                </h1>
                                <p className="mt-2 text-xs md:text-sm">
                                    Project Complete
                                </p>
                            </div>
                            <div>
                                <h1 className="text-4xl font-semibold text-center">
                                    <span ref={clientSatisfactionRef}>
                                        {" "}
                                        0.0{" "}
                                    </span>{" "}
                                    /5
                                </h1>
                                <p className="mt-2 text-xs md:text-sm">
                                    {" "}
                                    Client Satisfaction
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OutStory;
