function Card(props) {
    return (
        <div className="text-white border rounded-lg bg-tertiary">
            {/* HEADING LINE */}
            <div className="flex gap-3">
                {/* PROFILE PHOTO */}
                <div className="translate-y-[-50%]">
                    <img src={props.image} className="w-[100px]" alt="" />
                </div>

                {/* NAME + DESIGNATION */}
                <div className="mt-3">
                    {/* NAME */}
                    <div className="text-xl font-bold font-['lato']"> <span className="mr-2" >|</span> {props.name}</div>

                    {/* DESIGNATION */}
                    <div className="text-xs mt-2 font-['montserrat']">{props.designation}</div>
                </div>
            </div>

            {/* CONTENT */}
            <div className="translate-y-[-10px] px-7 pb-8 text-xs text-justify md:text-base font-['montserrat']">
                {props.content}
            </div>
        </div>
    );
}

export default Card;