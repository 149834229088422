import outSolutionVideo1 from "../../assets/ourSolutionVideo1.svg";
import outSolutionVideo2 from "../../assets/ourSolutionVideo2.svg";

function ServicesVideo({ videoSrc, videoTitle }) {
    return (
        <section className="py-20 custom-container">
            <div className="flex flex-col items-center justify-center gap-x-10 gap-y-4 lg:flex-row-reverse">
                <div className="flex items-center lg:items-start gap-3 lg:flex-col max-w-[400px] font-['lato']">
                    <h1 className="text-4xl font-semibold lg:text-5xl">
                        <div>{videoTitle}</div>
                    </h1>
                    <p>
                        Watch our videos to see how our {videoTitle} service can benefit you.
                    </p>
                </div>
                <div className="flex items-center justify-center max-w-full shrink">
                    <iframe
                        src={videoSrc}
                        title={videoTitle}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        className="shrink w-[420px] lg:w-[600px] rounded-lg h-[250px] lg:h-[350px]"
                    ></iframe>
                </div>
            </div>
        </section>
    );
}

export default ServicesVideo;
