import { useRef, useState } from "react";

import CustomTextField1 from "./CustomTextField1.component";
import CustomSelect1 from "./CustomSelect1.component";
import CustomPhone1 from "./CustomPhone1.component";
import CustomTextArea1 from "./CustomTextArea1.component";
import CustomButton1 from "./CustomButton1.component";
import ReCAPTCHA from "react-google-recaptcha";

import { RxCross2 } from "react-icons/rx";

import {
    validateSelectOpetion,
    validateString,
    validateEmail,
    validateCountryCode,
    validePhoneNumber,
    validateNumber,
    validateCaptcha,
} from "../validation/validation.js";

import configObj from "../email/config.js";
import toast from "react-hot-toast";

let asideRef;
let overlayRef;

let open = function () {
    console.log("Book Demo Aside Componet Open function");
    asideRef.current.style.width = "460px";
    overlayRef.current.style.width = "100%";
};
let close = function () {
    asideRef.current.style.width = "0px";
    overlayRef.current.style.width = "0px";
};

function BookDemoAside() {
    asideRef = useRef();
    overlayRef = useRef();
    const recaptchaRef = useRef();

    let [name, setName] = useState("");
    let [industry, setIndustry] = useState("");
    let [phone1, setPhone1] = useState("");
    let [phone2, setPhone2] = useState("");
    let [remarks, setRemarks] = useState("");

    async function sendMail() {
        let dateTime = new Date();
        let date = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        let time = `${dateTime.getHours()}:${dateTime.getMinutes()}`;
        let body = `
             <h1>Book a Demo</h1>
             <p>Date: ${date}</p>
             <p>Time: ${time}</p>
             <p>Name: ${name}</p>
             <p>Industry: ${industry}</p>
             <p>Phone: ${phone1} ${phone2}</p>
             <p>Remarks: ${remarks || ""}</p>
        `;
        let mailstatus = window.Email.send({
            ...configObj,
            Subject: "Book a Demo [form website] ",
            Body: body,
        });
        toast.promise(
            mailstatus,
            {
                loading: "Sending Email...",
                success: "Email sent successfully, Analytic Valley Team will contact you soon.",
                error: "Failed to send Email",
            },
            {
                duration: 10000,
            }
        );
        mailstatus.then((status) => {
            if (status === "OK") {
                setTimeout(() => {
                    setName("");
                    setIndustry(-1);
                    setPhone1("");
                    setPhone2("");
                    setRemarks("");
                    close();
                }, 0);
            }
        });
    }

    function clickHandlerSubmit() {
        if (!validateString("Please enter valid Name", name, 3, 50)) return;
        if (!validateSelectOpetion("Please Select Industry", industry)) return;
        if (!validateCountryCode("Please enter a valid Country code", phone1)) return;
        if (!validePhoneNumber("Please enter a valid Phone number", phone2)) return;
        if (!validateString("Remarks can not container more than 1000 character", remarks, 0, 1000))
            return;
        if (!validateCaptcha("Please fill the Captcha", recaptchaRef.current.getValue())) return;

        sendMail();
    }

    return (
        <>
            <section
                className="fixed left-0 top-0 w-[0px] h-screen bg-primary z-[52] bg-[url('./pages/assets/bg3.svg')] bg-contain bg-no-repeat [background-position:200px_-100px] overflow-hidden transition-all"
                ref={asideRef}
            >
                <div className="py-5 text-white">
                    <div className="flex justify-end px-5">
                        {" "}
                        <RxCross2
                            onClick={close}
                            className="p-1 text-2xl bg-white rounded-full text-primary press"
                        ></RxCross2>{" "}
                    </div>
                    <div className="px-10 mt-2 overflow-y-auto max-h-[90vh]">
                        <div className="text-2xl font-semibold">Book Demo</div>
                        {/* <div className="mt-2 text-xs">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt
                            dolores quos provident hic. Temporibus iste eaque beatae, nulla eum
                            corporis
                        </div> */}
                        <div className="mt-5">
                            <div className="mt-5">
                                <CustomTextField1
                                    label="Name"
                                    placeholder="Name"
                                    state={name}
                                    setState={setName}
                                    className={"capitalize"}
                                />
                            </div>
                            <div className="mt-5">
                                <CustomSelect1
                                    label="Industry"
                                    placeholder="Industry"
                                    state={industry}
                                    setState={setIndustry}
                                >
                                    <option className="text-gray-400">E-LEARNING</option>
                                    <option className="text-gray-400">AUTOMOTIVE</option>
                                    <option className="text-gray-400">DIAGONISTIC</option>
                                    <option className="text-gray-400">ENTERTAINMENT</option>
                                    <option className="text-gray-400">EDUCATION</option>
                                    <option className="text-gray-400">FASHION & LIFESTYLE</option>
                                    <option className="text-gray-400">HEALTHCARE</option>
                                    <option className="text-gray-400">RETAIL</option>
                                    <option className="text-gray-400">TOURISM</option>
                                </CustomSelect1>
                            </div>
                            <div className="mt-5">
                                <CustomPhone1
                                    label="Phone Number"
                                    placeholder1="Eg. +91"
                                    placeholder2="Phone Number"
                                    state1={phone1}
                                    setState1={setPhone1}
                                    state2={phone2}
                                    setState2={setPhone2}
                                />
                            </div>
                            <div className="mt-5">
                                <CustomTextArea1
                                    label="Remarks"
                                    state={remarks}
                                    setState={setRemarks}
                                />
                            </div>
                            <div className="flex justify-center mt-5">
                                <ReCAPTCHA
                                    sitekey="6LchLL8mAAAAAC4y_7vYBWdN-U1z0OYHWMmOVKXz"
                                    ref={recaptchaRef}
                                />
                            </div>
                            <div className="flex justify-center mt-5">
                                <CustomButton1
                                    label="Submit"
                                    className={"bg-white text-primary"}
                                    onClick={clickHandlerSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                ref={overlayRef}
                className="fixed left-0 top-0 w-[0px] h-screen bg-[rgba(0,0,0,0.5)] z-[51]"
            ></section>
        </>
    );
}

export { BookDemoAside, open, close };
