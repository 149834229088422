import React from 'react';
import pageNotFound from './assets/pageNotFound/1.jpg';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';

export default function PageNotFound() {
    return (
        <>
            <section className="flex items-center justify-center min-h-screen bg-primary text-white">
                <div className="custom-container grid lg:grid-cols-2 gap-8 items-center px-4 py-16 lg:px-16">
                    
                    {/* Text Section */}
                    <div className="flex flex-col items-start">
                        <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
                        <p className="mb-6 text-lg">
                            Oops! The page you are looking for doesn't exist or has been moved.
                        </p>
                        <Button variant="contained" className="capitalize bg-white text-tertiary relative">
                            <div className="px-4">Go To Home</div>
                            <NavLink
                                to="/home"
                                className="absolute inset-0 w-full h-full"
                            ></NavLink>
                        </Button>
                    </div>

                    {/* Image Section */}
                    <div className="hidden lg:block overflow-hidden">
                        <img src={pageNotFound} className="object-cover w-full h-full rounded-lg shadow-lg" alt="Page Not Found" />
                    </div>
                </div>
            </section>
        </>
    );
}
