import { useState } from "react";

import CustomButton1 from "../CustomButton1.component";

import ServiceBox1 from "../../assets/ServiceBox1.svg";
import ServiceBox2 from "../../assets/ServiceBox2.svg";
import ServiceBox3 from "../../assets/ServiceBox3.svg";
import ServiceBox4 from "../../assets/ServiceBox4.svg";
import ServiceBox5 from "../../assets/ServiceBox5.svg";
import ServiceBox6 from "../../assets/ServiceBox6.svg";
import ServiceBox7 from "../../assets/ServiceBox7.svg";
import ServiceBox8 from "../../assets/ServiceBox8.svg";

let css = ``;

function Services() {
    function ServiceBox(props) {
        let [isOpened, setIsOpened] = useState(false);
        return (
            <section
                className={`flex ${
                    props.position === "left" ? "" : "flex-row-reverse"
                } gap-5 my-10`}
            >
                <div>
                    <img src={props.thumbnail} className="w-[30vw] max-w-[320px]" alt="" />
                </div>
                <div>
                    <h1 className="text-2xl font-semibold text-primary font-['lato']">
                        {props.title}
                    </h1>
                    <p
                        className={`mt-3  ${
                            isOpened ? "h-auto" : " h-[130px] max-h-[150px] "
                        } overflow-hidden text-xs md:text-xl lg:text-2xl text-justify`}
                    >
                        {props.content}
                    </p>
                    <div
                        className={`mt-5 flex ${props.position === "left" ? " justify-end " : ""} `}
                    >
                        <CustomButton1
                            label={isOpened ? "View Less" : "View More"}
                            className="bg-primary"
                            onClick={() => setIsOpened(!isOpened)}
                        />
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section>
            <style>{css}</style>
            <div className="py-20 custom-container">
                <h1 className="text-center heading pt-5">SERVICES</h1>
                <p className="mx-auto max-w-[700px] text-center text-sm mt-2 font-['montserrat']">
                    Accelerate your progress with all the tools you need in one go. Your
                    one-stop-shop, Analytics Valley, offers a wide range of services to help you
                    advance throughout time.
                </p>
                <div className="mt-16">
                    <ServiceBox
                        thumbnail={ServiceBox1}
                        position={"left"}
                        title={"AWS"}
                        content={
                            "We provide a comprehensive cloud computing platform that provides a wide range of scalable and cost-effective services. It enables businesses to leverage cloud infrastructure, storage, databases, analytics, and other tools to build, deploy, and manage applications securely. With its extensive service offerings, AWS empowers organizations to enhance flexibility, scalability, and agility while reducing the need for on-premises infrastructure."
                        }
                    />
                    <ServiceBox
                        thumbnail={ServiceBox2}
                        position={"right"}
                        title={"Advanced Analytics"}
                        content={
                            "We provide sophisticated techniques and algorithms to extract valuable insights from large and complex datasets. It encompasses various statistical modeling, data mining, machine learning, and predictive analytics techniques. By leveraging advanced analytics, businesses can uncover hidden patterns, trends, and correlations in their data, enabling them to make informed decisions, optimize processes, mitigate risks, and gain a competitive edge in their industry."
                        }
                    />
                    <ServiceBox
                        thumbnail={ServiceBox3}
                        position={"left"}
                        title={"Basic Analytics"}
                        content={
                            "We provide the fundamental analysis of data using traditional statistical methods and tools. It includes descriptive analytics that focuses on summarizing and visualizing data to gain basic insights into trends, distributions, and relationships. Basic analytics provides a foundation for understanding historical data and making simple data-driven decisions. Basic analytics is very crucial to derive all the important metrics and significant data points for businesses."
                        }
                    />
                    <ServiceBox
                        thumbnail={ServiceBox4}
                        position={"right"}
                        title={"Consulting"}
                        content={
                            "Consulting services involve providing expert advice and guidance to organizations to solve specific business challenges or achieve strategic goals. Consultants leverage their domain expertise and industry knowledge to assess existing processes, identify areas for improvement, and develop tailored solutions. Consulting services can span various areas, including technology, operations, finance, marketing, and more. The goal of consulting is to drive organizational growth, improve efficiency, and enhance overall performance."
                        }
                    />
                    <ServiceBox
                        thumbnail={ServiceBox5}
                        position={"left"}
                        title={"Data Science"}
                        content={
                            "Data science is an interdisciplinary field that combines statistical analysis, machine learning, and domain expertise to extract insights and knowledge from data. Data scientists employ techniques such as data mining, predictive modeling, and data visualization to discover patterns, solve complex problems, and make data-driven decisions. Data science plays a crucial role in areas like business intelligence, risk assessment, customer behavior analysis, and optimizing processes."
                        }
                    />
                    <ServiceBox
                        thumbnail={ServiceBox6}
                        position={"right"}
                        title={"Iot/Robotics"}
                        content={
                            "We provide technologies that enable the interconnection and automation of physical devices and machines. LoT involves connecting everyday objects to the internet to collect and exchange data, enabling remote monitoring and control.Robotics encompasses the design, development, and operation of robots that can perform tasks autonomously or with minimal human intervention. These technologies have vast applications in sectors such as manufacturing, healthcare, agriculture, and logistics, driving efficiency, productivity, and innovation."
                        }
                    />
                    <ServiceBox
                        thumbnail={ServiceBox7}
                        position={"left"}
                        title={"Mobile Application"}
                        content={
                            " A mobile application, commonly known as a mobile app, is a software application designed to run on mobile devices such as smartphones and tablets. Mobile apps offer specialized functionalities and user experiences tailored to the mobile platform. They can range from productivity tools, social media platforms, gaming, e-commerce, and more. Mobile applications enable businesses to reach a wider audience, enhance customer engagement, and provide on-the-go access to services and information."
                        }
                    />
                    <ServiceBox
                        thumbnail={ServiceBox8}
                        position={"right"}
                        title={"Web Application"}
                        content={
                            "A web application is a software application accessed and used through web browsers. It typically consists of a server-side backend and a client-side frontend. Web applications can range from simple websites to complex platforms with advanced functionalities such as e-commerce, content management systems, online banking, and collaboration tools. Web applications provide easy access to services across different devices and platforms, enabling businesses to reach global audiences, enhance user experiences, and facilitate seamless interactions on the internet."
                        }
                    />
                </div>
            </div>
        </section>
    );
}

export default Services;
