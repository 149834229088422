import NavBar from "./components/NavBar.component";
import Slider from "./components/Slider.component";
import Footer from "./components/Footer.component";
import BlogSection from "./components/Blog/BlogSection.component";

import Banner12 from "./assets/Banner12.svg";
import CustomScrollbar from "./components/CustomScrollbar.component";

function Blogs() {
    return (
        <section className="">
            {/* NAVIGATION BAR */}
            <section className="">
                <NavBar />
            </section>

            {/* NAV-CONTACT */}
            <section>{/* <NavContact /> */}</section>

            {/* HERO SLIDER */}
            <section>
                <Slider images={[Banner12]} />
            </section>

            {/* BLOGS */}
            <section>
                <BlogSection />
            </section>

            {/* FOOTER */}
            <section>
                <Footer />
            </section>

            <CustomScrollbar />
        </section>
    );
}

export default Blogs;
