import NavBar from "./components/NavBar.component";
import Slider from "./components/Slider.component";
import TechnicalAdvisory from "./components/BoardOfAdvisory/TechnicalAdvisory.component";
import AcademicsAdvisory from "./components/BoardOfAdvisory/AcademicsAdvisory.component";
import NewsLetter from "./components/NewsLetter.component";
import Footer from "./components/Footer.component";

import Banner14 from "./assets/Banner14.svg";
import { useEffect, useRef } from "react";
import CustomScrollbar from "./components/CustomScrollbar.component";

function BoardOfAdvisory() {
    const sliderRef = useRef(null);

    // Scroll to slider on first render
    useEffect(() => {
        if (sliderRef.current) {
            window.scrollTo({
                top: sliderRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    }, []);

    return (
        <section className="">
            {/* NAVIGATION BAR */}
            <section className="">
                <NavBar />
            </section>

            {/* NAV-CONTACT */}
            <section>{/* <NavContact/> */}</section>

            {/* HERO SLIDER */}
            <section ref={sliderRef}>
                <Slider images={[Banner14]} />
            </section>

            {/* LEADERSHIP PROFILE */}
            <section>
                <TechnicalAdvisory />
            </section>

            {/* ACADEMICS ADVISORY */}
            <section>
                <AcademicsAdvisory />
            </section>

            {/* NEWS-LETTER */}
            <section>
                <NewsLetter />
            </section>

            {/* FOOTER */}
            <section>
                <Footer />
            </section>

            <section>
                <CustomScrollbar />
            </section>
        </section>
    );
}

export default BoardOfAdvisory;
