import { useState, useRef } from "react";
import CustomButton1 from "../CustomButton1.component";

import blog1 from "../../assets/blog-1.svg";
import blog2 from "../../assets/blog-2.svg";
import blog3 from "../../assets/blog-3.svg";
import blogThumbnail1 from "../../assets/blogThumbnail1.svg";
import blogThumbnail2 from "../../assets/blogThumbnail2.svg";
import blogThumbnail3 from "../../assets/blogThumbnail3.svg";

function BlogSection() {
    function BlogContainer(props) {
        let [isOpened, setIsOpened] = useState(false);

        function clickHandler() {
            setIsOpened(!isOpened);
        }
        return (
            <>
                <div className="transition duration-500 hover:scale-105 hover:bg-white lg:p-5 lg:m-5 p-2 m-2 rounded">
                    <div
                        className={`overflow-hidden  `}
                        style={{ height: isOpened ? props.height2 : props.height1 }}
                    >
                        {props.children}
                    </div>
                    <div className="mt-5">
                        <CustomButton1
                            variant="outlined"
                            label={isOpened ? "View Less" : "View More"}
                            className="border-primary text-primary"
                            onClick={clickHandler}
                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <section>
            <div className="py-16 custom-container bg-secondary">
                <h1 className="text-center heading">BLOG</h1>
                <p className="text-center font-['montserrat'] text-sm tracking-wide mt-2">
                    Read to find out more about our services and solutions
                </p>
                <div className="mt-5">
                    <BlogContainer height1="450px" height2="auto">
                        <div className="text-justify ">
                            {/* HOW... */}
                            <div className="mt-5">
                                <img
                                    src={blog1}
                                    alt=""
                                    className="w-[200px] md:w-[300px] float-left mr-5 mb-5"
                                />
                                <h1 className="pt-10 pb-5 text-2xl font-semibold">
                                    Online Exam Management
                                </h1>
                                <h1 className="text-lg font-semibold">
                                    How to Streamline Your Testing Process
                                </h1>
                                <p>
                                    The world of education has undergone a massive transformation in
                                    the past few years, and the rise of technology has played a
                                    crucial role in this transformation. One of the most significant
                                    changes has been the shift from traditional paper-based exams to
                                    online exams. Online exams offer many benefits, including
                                    greater convenience, improved security, and faster grading.
                                    However, managing online exams can be a daunting task,
                                    especially for educators who are not familiar with the
                                    technology. In this blog post, we will explore the basics of
                                    online exam management and offer tips for streamlining your
                                    testing process.
                                </p>
                            </div>

                            {/* WHAT... */}
                            <div className="mt-5 clear-left">
                                <img
                                    src={blogThumbnail1}
                                    alt=""
                                    className="w-[250px] float-right ml-5 mb-5"
                                />
                                <h1 className="text-lg font-semibold">
                                    How to Streamline Your Testing Process
                                </h1>
                                <p>
                                    The world of education has undergone a massive transformation in
                                    the past few years, and the rise of technology has played a
                                    crucial role in this transformation. One of the most significant
                                    changes has been the shift from traditional paper-based exams to
                                    online exams. Online exams offer many benefits, including
                                    greater convenience, improved security, and faster grading.
                                    However, managing online exams can be a daunting task,
                                    especially for educators who are not familiar with the
                                    technology. In this blog post, we will explore the basics of
                                    online exam management and offer tips for streamlining your
                                    testing process.
                                </p>
                                <p className="mt-3">
                                    The use of online exam management has increased significantly in
                                    recent years due to the convenience, security, and speed that it
                                    offers. One of the key advantages of online exam management is
                                    convenience. With online exams, students can take the exam from
                                    anywhere with an internet connection, making it easier for them
                                    to fit the exam into their busy schedules. This is particularly
                                    useful for students who have other commitments, such as work or
                                    family responsibilities, and may not be able to travel to a
                                    physical testing center. Online exam management also offers
                                    enhanced security features. Online exam software can include
                                    features such as monitoring software and facial recognition
                                    technology to prevent cheating. This can help to ensure the
                                    integrity of the exam and maintain the credibility of the
                                    certification or qualification being tested. Additionally,
                                    online exams can be graded automatically, which saves educators
                                    time and reduces the risk of errors. This means that educators
                                    can spend more time on other tasks, such as teaching, rather
                                    than grading exams manually. Online exam management software can
                                    also provide detailed analytics and performance data, allowing
                                    educators to identify areas where students may need additional
                                    support or where changes to the exam may be needed.
                                </p>
                            </div>

                            {/* BENIFITS... */}
                            <div className="mt-5 clear-left">
                                <h1 className="text-lg font-semibold">
                                    Benefits of Online Exam Management
                                </h1>
                                <p>
                                    Online exam management offers many benefits over traditional
                                    paper-based exams. Some of the key benefits include:
                                </p>
                            </div>

                            {/* BENIFITS... */}
                            <div className="mt-5 clear-left">
                                <h1 className="text-lg font-semibold">Convenience</h1>
                                <p className="mt-3">
                                    With online exams, students can take the exam from anywhere with
                                    an internet connection, making it easier for them to fit the
                                    exam into their busy schedules. One of the key advantages of
                                    online exam management is the convenience it offers to students.
                                    With online exams, students can take the exam from anywhere with
                                    an internet connection, making it easier for them to fit the
                                    exam into their busy schedules. This means that students no
                                    longer have to travel to a physical testing center, which can be
                                    time-consuming and expensive. Instead, they can take the exam
                                    from the comfort of their own home, a library, or even a coffee
                                    shop.
                                </p>
                                <p className="mt-3">
                                    Online exam management also allows students to take the exam at
                                    any time, which provides greater flexibility. This is
                                    particularly useful for students who have other commitments,
                                    such as work or family responsibilities, and may not be able to
                                    take the exam during regular business hours. With online exam
                                    management, students can take the exam at a time that is
                                    convenient for them, which can help to reduce stress and improve
                                    performance.
                                </p>
                                <p className="mt-3">
                                    Improved Security: Online exam software can offer advanced
                                    security features, such as monitoring software and facial
                                    recognition technology, to prevent cheating. One of the biggest
                                    concerns with traditional paper-based exams is the potential for
                                    cheating. Students can easily cheat by copying answers from
                                    other students or bringing notes into the exam room. This can
                                    compromise the integrity of the exam and lead to inaccurate
                                    results.
                                </p>
                            </div>
                        </div>
                    </BlogContainer>

                    <BlogContainer height1="470px" height2="auto">
                        <div className="mt-16 text-justify">
                            {/* TABLE OF CONTENT */}
                            <div className="mt-5">
                                <img
                                    src={blog2}
                                    alt=""
                                    className="w-[200px] md:w-[300px] float-left mr-10 mb-5"
                                />
                                <h1 className="pb-5 text-2xl font-semibold">
                                    CRM for Accounting and Tax firms
                                </h1>
                                <h1 className="text-lg font-semibold">Table of content</h1>
                                <ol className="list-decimal list-inside">
                                    <li>Introduction</li>
                                    <li>Efficient Client Management</li>
                                    <li>Improved Communication with Clients</li>
                                    <li>Increased Collaboration and Efficiency</li>
                                    <li>Enhanced Marketing and Business Development</li>
                                    <li>Improved Data Management</li>
                                    <li>Increased Client Retention</li>
                                    <li>Conclusion</li>
                                </ol>
                            </div>

                            {/* 1. INTRODUCTION */}
                            <div className="mt-5 clear-left">
                                <img
                                    src={blogThumbnail3}
                                    alt=""
                                    className="w-[250px] float-right ml-5 mb-5"
                                />
                                <h1 className="text-lg font-semibold">Introduction</h1>
                                <p>
                                    Customer relationship management (CRM) is an essential tool for
                                    tax and accounting firms of all sizes. It is a system that
                                    allows businesses to manage their interactions with clients and
                                    streamline their internal processes. CRM software can help tax
                                    and accounting firms to automate tasks, enhance communication
                                    with clients, and improve customer service. In this blog post,
                                    we will explore the reasons why CRM is important for tax and
                                    accounting firms and how it can benefit their businesses.
                                </p>
                            </div>
                        </div>
                    </BlogContainer>

                    <BlogContainer height1="540px" height2="auto">
                        <div className="mt-16 text-justify">
                            {/* TABLE OF CONTENT */}
                            <div className="mt-5">
                                <img
                                    src={blog3}
                                    alt=""
                                    className="w-[200px] md:w-[300px] float-left mr-10 mb-5"
                                />
                                <h1 className="pb-5 text-2xl font-semibold">
                                    Benefits for robotic live streaming for hospitals
                                </h1>
                                <h1 className="text-lg font-semibold">Table of content</h1>
                                <ol className="list-decimal list-inside">
                                    <li>Introduction</li>
                                    <li>Efficient Client Management</li>
                                    <li>Improved Communication with Clients</li>
                                    <li>Increased Collaboration and Efficiency</li>
                                    <li>Enhanced Marketing and Business Development</li>
                                    <li>Improved Data Management</li>
                                    <li>Increased Client Retention</li>
                                    <li>Conclusion</li>
                                </ol>
                            </div>

                            {/* 1. INTRODUCTION */}
                            <div className="mt-5 clear-left">
                                <img
                                    src={blogThumbnail2}
                                    alt=""
                                    className="w-[250px] float-right ml-5 mb-5"
                                />
                                <h1 className="text-lg font-semibold">Introduction</h1>
                                <p>
                                    In recent years, the use of robotic technology in healthcare has
                                    become increasingly popular. Robotic live streaming, in
                                    particular, has been found to be beneficial for hospitals. It
                                    allows healthcare professionals to communicate with patients
                                    remotely and provide care in real-time. Analytics Valley
                                    provides one of the best solutions for an interesting problem
                                    with robotic live steaming for healthcare and hospitals. Here
                                    are 5 benefits of robotic live streaming for hospitals
                                </p>
                            </div>
                        </div>
                    </BlogContainer>
                </div>
            </div>
        </section>
    );
}

export default BlogSection;
