import ReactDOM from "react-dom";

import { useState, useRef } from "react";
import { GrFormAdd } from "react-icons/gr";
import { GrFormSubtract } from "react-icons/gr";
import CustomButton1 from "../CustomButton1.component";

function CurrentOpening() {
    function Post(props) {
        return (
            <div className="py-5 border rounded-lg px-7 w-[360px] text-primary bg-quaternary shrink-0 font-['montserrat']">
                <div className="pb-2 text-xl font-semibold border-b border-primary ">
                    {props.title}
                </div>
                {/* <div className="mt-1 text-xs ">Posted {props.date}</div> */}
                <div className="mt-2">
                    {" "}
                    <span className="font-semibold">Required Exp :</span> {props.requireExperience}
                </div>
                <div>
                    <span className="font-semibold"> Location : </span>
                    {props.location}
                </div>
                <div className="mt-5 text-base text-justify text-black">{props.descreption}</div>
            </div>
        );
    }

    return (
        <section>
            <div className="py-16 custom-container">
                <h1 className="text-center heading">Current Opening</h1>
                <p className="text-sm text-center max-w-[1146px] mx-auto mt-2 font-['montserrat']">
                    We believe that with vacancies in software develoment company, you get a chance
                    to impact clients, businesses, and society as a whole. We are looking for IT
                    enthusiasts who share our beliefs and create an impact while growing to their
                    full potential. Find below the right position for you and embrace your career in
                    the best way possible.
                </p>

                {/* POST */}
                <div className="[transform:rotateX(180deg)] mt-16 overflow-auto custom-scrollbar-1">
                    <div className="flex gap-10 pt-7 [transform:rotateX(-180deg)]  lg:flex-row lg:justify-center lg:max-w-4xl lg:mx-auto">
                        <Post
                            title="Full Stack Web Developer (React / Laravel)"
                            requireExperience="3 to 5 Years"
                            location="Bangalore"
                            descreption="We are seeking multiple Full Stack Web Developers to join our Cloud Development team. This role involves working on App Modernization projects. If you're passionate about building innovative solutions, we encourage you to apply!"
                        />
                        <Post
                            title="Dotnet Developer"
                            requireExperience="3 to 5 Years"
                            location="Bangalore"
                            descreption="Join our Cloud Development team as a Dotnet Developer! We are looking for talented individuals to work on App Modernization projects. If you thrive in a collaborative environment, we'd love to hear from you!"
                        />
                        <Post
                            title="Business Development Executive"
                            requireExperience="3 to 5 Years"
                            location="Bangalore"
                            descreption="We are expanding our Cloud Development team and seeking multiple Business Development Executives. If you have a knack for identifying new business opportunities and want to contribute to App Modernization projects, please apply!"
                        />
                    </div>
                </div>

            </div>
        </section>
    );
}

export default CurrentOpening;
