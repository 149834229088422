import Card from "../Card.component";
import profile3 from "../../assets/profile3.svg";
import profile4 from "../../assets/profile4.svg";
import profile5 from "../../assets/profile5.svg";

function AcademicsAdvisory() {
    return (
        <section>
            <div className="py-16 custom-container bg-[#f4f6f9]">
                <h1 className="text-center heading">Academics Advisory</h1>
                <p className="mt-2 max-w-[700px] mx-auto text-center text-sm font-['montserrat']">
                    Our academics team Can handle new advancements and
                    information booklet for operational development
                </p>
                <div className="flex flex-col gap-20 mt-20">
                    {/* GRAY CONTAINER */}
                    <Card
                        image={profile3}
                        name={"Ph.D D. R. Sahu"}
                        designation={"[ Mathematics & Statistics ]"}
                        content={
                            "Prof. Dr. D. R. Sahu is a highly accomplished Mathematician with several contributions in the field of Fixed Point Theory, Operator Theory, Best Approximation Theory, Variational Inequality Theory and Newton-like Methods for solving generalized operator equations. He is an eminent member of our Advisory Board and brings years of experience in Nonlinear Functional Analysis, which is a growing domain in applied mathematics. Having worked with senior academicians from the field of Applied Mathematics, Dr. Sahu has garnered enriched knowledge in topics like Nonlinear Analysis and Optimization, Fixed Point Theory, Hierarchical Minimization Problems and Applications etc., which also helped him in supervising students involved in Ph D studies and research activities. Dr. Sahu is also currently working as an Associate Professor in Mathematics at the Banaras Hindu University and has co-authored several research publications, partnering with esteemed mathematicians from across the world. He has also published books on fixed point theory for Lipschitzian-type mappings with applications, Topological Fixed Point Theory and Its Applications. His contribution to the research community as an Associate Editor of several journals in the field of mathematics and his services to teaching and administration have earned him many accolades across the world. He is passionate about finding solutions to nonlinear problems posed in the real world, which finds greater applicability than linear functional analysis in the field of Science, Social Science, Engineering and Technology. His deep domain knowledge and sharp mind brings immense value-addition to our Advisory Board, which will pave the way for the organization's growth in the coming years."
                        }
                    />
                    <Card
                        image={profile4}
                        name={"Dr. Mohan Lal Verma"}
                        designation={"Physics"}
                        content={
                            "Dr. Mohan Lal Verma is well-known physicist and material scientist, having pioneered work on computational material modeling in India. He is currently working as Professor and Head of the Department of Applied Physics at SSTC and works closely with students and PhD scholars, guiding them on emerging topics and new subjects. He is also spearheading the work on quantum chemistry based software called Siesta and is working to help Indian students and teachers become familiar with this new technology that is ready to be launched. He is a an active life member of several professional bodies like National Society of Solid State Ionics, Material Research Society of India, The Society for Advancement of Electrochemical Science and Technology, Karaikudi etc. and is passionate about contributing to scientific development though his research activities. Dr. Verma is highly accomplished in the field of computational material physics and he works in close collaboration with the faculty of Applied Physics, Michigan University USA for various research projects. He has published 45 research papers in national and international journals, organized several national conferences and international workshops. Having successfully completed two research projects, Dr. Verma is a highly sought after academician and scientist, who brings his expertise in computational/ mathematical modeling, siesta and related post processing, plotting and visualization tools and Quantum Expresso. He has also worked on emerging technologies like polymeric electrolytic materials and designing electrodes in the form of nano ribbons and nano belts for different electrochemical and optoelectronic devices viz solid state battery, supercapacitors organic light emitting diodes and light emitting electrochemical cells."
                        }
                    />
                    <Card
                        image={profile5}
                        name={"Prof. Dr. Smita Selot"}
                        designation={"[ Computer Science ]"}
                        content={
                            "Smita Selot; post graduate from GEC Jabalpur and Doctorate in Faculty of computer applications from CSVTU; is currently working in SSTC as Professor and Head of the Department in Computer Applications. Associated with SSTC fraternity since last 17 years ; her major contributions were in teaching, administration and research. She has taught graduate and post graduate students variety of subjects in computer domain. Apart from administrational work, she has been an active member of various boards of university. Research interest cover fields of Natural language processing, knowledge representation, image processing and machine learning. She has published 46 papers in national and international journals and conferences and chaired various conferences and seminar."
                        }
                    />
                </div>
            </div>
        </section>
    );
}

export default AcademicsAdvisory;
