import { AiFillStar } from "react-icons/ai";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";

import React, { useRef, useState } from "react";
import SwiperCore, { Virtual, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Resplonsive from "../Responsive.function";

SwiperCore.use([Virtual, Navigation, Pagination]);

function Review() {
    const [swiperRef, setSwiperRef] = useState(null);

    function ReviewBox() {
        return (
            <div className="min-w-[390px] md:min-w-[450px] mr-5">
                <div className="flex justify-center gap-3 text-2xl">
                    <AiFillStar className="text-orange-500" />
                    <AiFillStar className="text-orange-500" />
                    <AiFillStar className="text-orange-500" />
                    <AiFillStar className="text-orange-500" />
                    <AiFillStar className="text-white" />
                </div>
                <div className="mt-3 shadow-lg rounded-2xl bg-primary">
                    {/* TITLE */}
                    <div className="flex items-center">
                        {/* PHOTO */}
                        <div>
                            <div className="bg-white h-[100px] w-[100px] rounded-full mx-5 mt-5 translate-y-[0%]"></div>
                        </div>

                        {/* NAME */}
                        <div className="text-white">
                            <div className="font-semibold">Will Max</div>
                            <div className="text-xs">Manager</div>
                        </div>
                    </div>

                    {/* PARAGRAPH */}
                    <div className="p-7">
                        <div>
                            <FaQuoteLeft className="text-white" />
                        </div>
                        <p className="mt-1 text-justify text-white">
                            The CRM solutions provided by Analytics Valley are
                            incredibly user-friendly, making it easy for anyone
                            to get started. The interface is intuitive and
                            well-designed, and all the key features are clearly
                            labelled and easy to find.
                        </p>
                        <div className="flex justify-end">
                            <FaQuoteRight className="text-white " />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="py-20 custom-container bg-secondary">
            <h1 className="text-center heading font-['lato']">REVIEWS</h1>
            <p className="mt-2 text-xs text-center font-['montserrat'] tracking-widest">
                Trusted by those who matter most - our clients speak to our
                unwavering commitment to excellence
            </p>
            <div className="flex w-full mt-10 mb-1 overflow-x-auto">
                <Swiper
                    onSwiper={setSwiperRef}
                    slidesPerView={Resplonsive(1, 1, 1, 2, 2, 2)}
                    spaceBetween={30}
                    navigation={true}
                    virtual
                >
                    <SwiperSlide virtualIndex={0}>
                        <ReviewBox />
                    </SwiperSlide>
                    <SwiperSlide virtualIndex={1}>
                        <ReviewBox />
                    </SwiperSlide>
                    <SwiperSlide virtualIndex={2}>
                        <ReviewBox />
                    </SwiperSlide>
                    <SwiperSlide virtualIndex={2}>
                        <ReviewBox />
                    </SwiperSlide>
                    <SwiperSlide virtualIndex={2}>
                        <ReviewBox />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}

export default Review;
