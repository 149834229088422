import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need3 from "../../assets/need-3.svg";
import NeedImg3 from "../../assets/NeedImg3.svg";
import Clientele from '../Home/Clientele.component'
import thumbImg from '../../assets/SolutionsImage/vectorImages/fdg.jpg'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function HospitalManagement() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className="p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={1}
                                    icon={Need3}
                                    thumbnail={thumbImg}
                                    title={"Hospital Management"}
                                    content={[
                                        `Hospital Management Software (HMS) is designed to streamline and automate various administrative and clinical tasks, including patient records, appointment scheduling, billing, inventory management, etc. This software helps administrators manage the hospital's functions more effectively and efficiently.`,

                                        `One of the most significant benefits of HMS is the time-saving feature. With HMS, hospitals can automate many of the repetitive and time-consuming tasks that were previously performed manually. This helps free up staff time, enabling them to focus on more important tasks requiring attention. HMS also eliminates the need for paperwork, as everything can be stored digitally, which reduces the risk of data loss and damage.`,

                                        `Another important benefit of HMS is the enhanced communication it offers. HMS allows doctors, nurses, patients, and administrative staff to communicate in real time. It offers a platform for patients to access their medical records, schedule appointments, and communicate with healthcare providers. HMS also enables healthcare providers to share feedback with patients, allowing them to work together to improve.`,
                                    ]}
                                    image={NeedImg3}
                                    caption={["Hospitals"]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>

                {/* SOLUTION VIDEO */}
                <section>
                    <SolutionVideo
                        videoSrc="https://www.youtube.com/embed/-8FbJLY6-R8"
                        videoTitle="Hospital Management"
                    />
                </section>

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
