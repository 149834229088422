import CustomButton1 from "./CustomButton1.component";

function NewsLetter(){
    return(
        <section className="py-24 bg-[url('./pages/assets/NewsLetter.svg')] bg-center bg-no-repeat bg-cover" >
            <div className="flex flex-col items-center text-white custom-container" >
                <h1 className="heading" >Join Our Newsletter</h1>
                <p className="mt-6 text-center max-w-[800px] font-['montserrat']" >Stay up-to-date with the latest news, tips, and trends in the industry by joining our newsletter. Sign up now for exclusive content!</p>
                <div className="mt-10" >
                    <CustomButton1 label="Subscribe Now" className="font-semibold bg-white text-primary" />
                </div>
            </div>
        </section>
    )
}
export default NewsLetter;