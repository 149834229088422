import React, { useRef, useState, useEffect } from 'react';
import CustomButton1 from './CustomButton1.component';

export default function TabContent(props) {
    const contentRef = useRef(null); // Ref for the content div
    const [isOpened, setIsOpened] = useState(false);
    const [showViewMore, setShowViewMore] = useState(false);

    // Check if content overflows
    useEffect(() => {
        if (contentRef.current) {
            // If content's scroll height is greater than client height (overflow)
            setShowViewMore(contentRef.current.scrollHeight > contentRef.current.clientHeight);
        }
    }, [props.content]);

    return (
        <>
            <section
                className={`flex ${props.position === "left" ? "" : "flex-row-reverse"} gap-5 my-10`}
            >
                <div>
                    <img src={props.thumbnail} className="w-[30vw] max-w-[320px]" alt="" />
                </div>
                <div>
                    <div className="flex gap-2 text-xl">
                        <h1 className="text-2xl font-semibold text-primary font-['lato']">{props.title}</h1>
                    </div>
                    <div
                        ref={contentRef}
                        className={`mt-5 text-xs lg:text-sm transition-all duration-300 ${isOpened ? "h-auto" : "max-h-[200px]"} overflow-hidden`}
                    >
                        {props.content.map((element, index) => (
                            <div key={index} className={`mt-3 text-xs md:text-xl lg:text-xl text-justify`}>
                                <p>{element}</p>
                            </div>
                        ))}

                        {/* Image below content, controlled by isOpened */}
                        <div className={`mt-10 flex items-center justify-center`}>
                            <img src={props.image} alt="" />
                        </div>
                    </div>

                    {showViewMore && ( // Conditionally render the button if content overflows
                        <div className={`mt-5 flex ${props.position === "left" ? "justify-end" : ""}`}>
                            <CustomButton1
                                label={isOpened ? "View Less" : "View More"}
                                className="bg-primary"
                                onClick={() => setIsOpened(!isOpened)}
                            />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
