import profile2 from "../../assets/profile2.svg";
import Card from "../Card.component";

function TechnicalAdvisory() {
    return (
        <section>
            <div className="py-16 custom-container bg-[#f4f6f9]">
                <h1 className="text-center heading">Technical Advisory</h1>
                <p className="mt-2 max-w-[700px] mx-auto text-center text-sm font-['montserrat']">
                    Our technical team can handle all the backend processes and
                    development
                </p>
                <div className="mt-20">
                    {/* GRAY CONTAINER */}
                    <Card
                        image={profile2}
                        name={"Prakash Kumar"}
                        designation={"Technical Advisor"}
                        content={
                            "With over 20 years of experience in Enterprise Architecture and IT Strategy Planning, Prakash heads Axcess and works as a Client Partner with a dedicated focus on helping our customers define their Technology and Architecture roadmaps. As a practicing architect, Prakash has garnered expertise in building customised solutions for customers thereby bringing immense value to the leadership team. His passion to solve everyday problems using real-time data acquisition and analytics drives the company's philosophy of achieving guaranteed customer satisfaction irrespective of the challenges pertaining to the client's industry. His extensive experience of working with global leaders and multi billion dollar corporations, including Vodafone, IBM, Mobily, Hauwei and Rackspace brings an unparalleled business advantage to the company."
                        }
                    />
                </div>
            </div>
        </section>
    );
}
export default TechnicalAdvisory;
