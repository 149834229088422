// CORE
import { useRef, useState } from "react";

import CustomTextField1 from "./CustomTextField1.component";
import CustomSelect1 from "./CustomSelect1.component";
import CustomPhone1 from "./CustomPhone1.component";
import CustomTextArea1 from "./CustomTextArea1.component";
import CustomButton1 from "./CustomButton1.component";
import { BookDemoAside, open } from "./BookDemoAside.component";

import { RxCross2 } from "react-icons/rx";

function BookDemo(props) {
    return (
        <>
            <section
                className={`relative custom-container bg-center bg-no-repeat bg-transparent bg-cover flex items-center`}
            >
                <img
                    src={props.bgImage}
                    className="absolute top-0 left-0 object-cover w-full h-full -z-10"
                    alt=""
                />

                <div className="py-16">
                    <h1 className="heading">{props.title}</h1>
                    <p className="mt-2 max-w-[700px] font-['loto'] text-lg">
                        Book a demo today to experience firsthand how our solutions can help solve
                        your business challenges.
                    </p>
                    <div className="mt-10">
                        <CustomButton1
                            label={<div className="mx-2 my-1">book Your Demo</div>}
                            className="bg-primary"
                            onClick={open}
                        />
                    </div>
                </div>
            </section>
            <BookDemoAside />
        </>
    );
}

export default BookDemo;
