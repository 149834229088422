import WhyUsImg1 from "../../assets/WhyUs1.svg";
import WhyUsImg2 from "../../assets/WhyUs2.svg";
import WhyUsImg3 from "../../assets/WhyUs3.svg";
import WhyUsImg4 from "../../assets/WhyUs4.svg";
import WhyUsImg5 from "../../assets/WhyUs5.svg";
import WhyUsImg6 from "../../assets/WhyUs6.svg";

function WhyUs() {
    function Card(props) {
        return (
            <div className="flex flex-col items-center min-w-[300px]">
                <div>
                    <img src={props.image} alt="" />
                </div>
                <div className="text-xl font-semibold">{props.title}</div>
                <div className="text-xs text-center max-w-[250px]">
                    {props.subtitle}
                </div>
            </div>
        );
    }

    return (
        <section>
            <div className="py-16 custom-container">
                <h1 className="text-center heading">Why Us</h1>
                <p className="mt-2 text-center max-w-[900px] mx-auto text-sm font-['montserrat']">
                    Choose us for innovative and reliable software solutions
                    that solve real-world problems with a customer-centric
                    approach and exceptional user experience.
                </p>
                <div className="grid grid-cols-1 mt-10 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-10">
                    <Card
                        image={WhyUsImg1}
                        title="Reliable service"
                        subtitle="We deliver dependable solutions that you can count on."
                    />
                    <Card
                        image={WhyUsImg2}
                        title="Experience & expertise"
                        subtitle="Our team has the knowledge and skills to tackle any project."
                    />
                    <Card
                        image={WhyUsImg3}
                        title="24x7 support"
                        subtitle="We offer round-the-clock assistance to ensure your success."
                    />
                    <Card
                        image={WhyUsImg4}
                        title="Comprehensive Planning"
                        subtitle="We plan every detail to ensure a successful outcome."
                    />
                    <Card
                        image={WhyUsImg5}
                        title="Customer-first approach"
                        subtitle="We prioritize your needs and satisfaction in every aspect of our work."
                    />
                    <Card
                        image={WhyUsImg6}
                        title="Cost & Time Saving"
                        subtitle="Our solutions save you money and time without sacrificing quality."
                    />
                </div>
            </div>
        </section>
    );
}

export default WhyUs;
