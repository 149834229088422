import * as React from "react";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";

import Blog1 from "../../assets/blog-1.svg";
import Blog2 from "../../assets/blog-2.svg";
import Blog3 from "../../assets/blog-3.svg";

import blogIcon1 from "../../assets/blogIcon1.svg";
import blogIcon2 from "../../assets/blogIcon2.svg";
import blogIcon3 from "../../assets/blogIcon3.svg";
import { useEffect, useRef } from "react";

function Blog() {
    function BlogBox(props) {
        const sliderRef = useRef(null);

        // Scroll to slider on first render
        useEffect(() => {
            if (sliderRef.current) {
                window.scrollTo({
                    top: sliderRef.current.offsetTop,
                    behavior: 'smooth',
                });
            }
        }, []);

        return (
            <NavLink
                to="/blogs"
                className="flex items-stretch md:flex-col overflow-hidden rounded-md md:w-[330px] bg-secondary"
            >
                <div ref={sliderRef} className="flex items-center justify-center w-full bg-secondary max-h-[250px]">
                    <img src={props.img} alt="" className="object-center" />
                </div>
                <div className="flex flex-col justify-center w-full p-5 text-white grow bg-primary">
                    <div className="flex items-stretch ">
                        <div className="flex items-center mr-2">{props.icon}</div>
                        <div className="ml-1">
                            <h1>{props.title}</h1>
                            <p className="text-xs">{props.subtitle}</p>
                        </div>
                    </div>
                    <div className="mt-5 text-xs md:text-base">
                        {props.content.length > 200
                            ? props.content.substring(0, 200) + "..."
                            : props.content}
                    </div>
                    <div className="justify-end hidden mt-2 ">
                        <Button
                            variant="contained"
                            size="small"
                            className="font-semibold capitalize bg-white text-primary"
                        >
                            Watch Video
                        </Button>
                    </div>
                </div>
            </NavLink>
        );
    }

    return (
        <div className="py-20 custom-container">
            <h1 className="text-center heading"> FEATURED BLOG </h1>
            <p className="mt-2 text-sm text-center font-['montserrat'] tracking-widest">
                Read to find out more about our services and solutions
            </p>
            <div className="flex flex-wrap items-stretch justify-center gap-8 mt-10">
                <BlogBox
                    img={Blog1}
                    icon={<img src={blogIcon1} />}
                    title="Online Exam Management"
                    subtitle="How to Streamline Your Testing Process"
                    content="One of the most significant changes has been the shift from traditional paper-based exams to online exams. Online exams offer many benefits, including greater convenience, improved security, and faster grading."
                />
                <BlogBox
                    img={Blog2}
                    icon={<img src={blogIcon2} />}
                    title="Accounting CRM"
                    subtitle="Why is CRM important for tax and accounting?"
                    content="Efficient client management is critical for the success of any tax and accounting firm. A CRM system can help to streamline client management by organizing client information and making it easily accessible to team members."
                />
                <BlogBox
                    img={Blog3}
                    icon={<img src={blogIcon3} />}
                    title="Robotic live streaming"
                    subtitle="benefits of robotic live streaming for hospitals"
                    content="Robotic technology can improve communication between healthcare providers and patients. Using robotic live streaming, healthcare providers can communicate with patients in real-time, and provide treatment."
                />
            </div>
        </div>
    );
}

export default Blog;
