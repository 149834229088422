import React, { useEffect, useRef, useState } from 'react'
import NavBar from '../NavBar.component';
import Slider from '../Slider.component';
import BookDemo from '../BookDemo.component';
import Footer from '../Footer.component';
// import Banner5 from "../../assets/SolutionsImage/34.jpg"
import Banner5 from "../../assets/Banner5.svg"
import ServiceBox4 from "../../assets/ServiceBox4.svg";
import Bg5 from "../../assets/bg5.svg";
import ServiceBox from '../ServiceBox.component';
import ServiceSlider from './ServiceSlider.component';
import ServicesVideo from './ServicesVideo.component';
import CustomScrollbar from '../CustomScrollbar.component';
let css = ``;

export default function Consulting() {
  const serviceBoxRef = useRef(null);

  // Scroll to the ServiceBox section when the page loads
  useEffect(() => {
    serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <section className="">
      {/* NAVIGATION BAR */}
      <section className="">
        <NavBar />
      </section>

      {/* NAV-CONTACT */}
      <section>{/* <NavContact/> */}</section>

      {/* HERO SLIDER */}
      <section>
        <Slider images={[Banner5]} showDemoButton={true} />
      </section>

      {/* BOOK DEMO */}
      {/* <section>
        <BookDemo
          bgImage={Bg5}
          title={
            <>
              Book a <span className="text-primary">Demo</span>{" "}
            </>
          }
        />
      </section> */}

      {/* SERVICE */}
      <section ref={serviceBoxRef}>
        <style>{css}</style>
        <section>
          <style>{css}</style>
          <div className="py-20 custom-container">
            <h1 className="text-center heading pt-5">SERVICES</h1>
            <p className="mx-auto max-w-[700px] text-center text-sm mt-2 font-['montserrat']">
              Accelerate your progress with all the tools you need in one go. Your
              one-stop-shop, Analytics Valley, offers a wide range of services to help you
              advance throughout time.
            </p>
            <div className="mt-16">
              <ServiceBox
                thumbnail={ServiceBox4}
                position={"right"}
                title={"Consulting"}
                content={
                  "Consulting services involve providing expert advice and guidance to organizations to solve specific business challenges or achieve strategic goals. Consultants leverage their domain expertise and industry knowledge to assess existing processes, identify areas for improvement, and develop tailored solutions. Consulting services can span various areas, including technology, operations, finance, marketing, and more. The goal of consulting is to drive organizational growth, improve efficiency, and enhance overall performance."
                }
              />
            </div>

            <section>
              <ServicesVideo
                videoSrc="https://www.youtube.com/embed/ITyukBvhEVU"
                videoTitle="Consulting Services Overview"
              />
            </section>

            <div className='pt-10'>
              <ServiceSlider />
            </div>
          </div>
        </section>
      </section>

      {/* FOOTER */}
      <section>
        <Footer />
      </section>

      <CustomScrollbar />
    </section>
  );
}
