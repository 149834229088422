import NavContact from "./NavContact.component";

import { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

import logo from "../assets/logo.svg";
import { HiMenu } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { BsCaretDownFill } from "react-icons/bs";
import { BsCaretRightFill } from "react-icons/bs";

function NavBar() {
    let asideRef = useRef(null);
    let overlayRef = useRef(null);
    const location = useLocation();

    // Array of paths under the "Solutions" dropdown
    const solutionsPaths = [
        "/solutions/sswayam-crm",
        // "/solutions/basic-sswayam-crm-sales",
        // "/solutions/basic-sswayam-crm-service",
        "/solutions/hospital-management",
        "/solutions/image-processing",
        "/solutions/machine-learning",
        "/solutions/offline-tax-management",
        "/solutions/online-tax-management",
        "/solutions/online-exam-module",
        "/solutions/online-survey-tool",
        "/solutions/robotic-uv-sanitizer",
        "/solutions/standard-sswayam-crm",
        // "/solutions/sswayam-crm-pro",
        "/solutions/school-management",
        "/solutions/verify-me",
    ];
    const isSolutionsActive = solutionsPaths.includes(location.pathname);

    const aboutUs = ["/about-our-story", "/board-of-advisory"];
    const isAboutUs = aboutUs.includes(location.pathname);

    const services = [
        "/services/aws",
        "/services/advanced-analytics",
        "/services/basic-analytics",
        "/services/consulting",
        "/services/data-science",
        "/services/iot-robotics",
        "/services/mobile-application",
        "/services/web-application",
    ];
    const isServices = services.includes(location.pathname);

    function open() {
        asideRef.current.style.overflow = "visible";
        asideRef.current.style.width = "50%";
        overlayRef.current.style.width = "100%";
    }
    function close() {
        asideRef.current.style.overflow = "hidden";
        asideRef.current.style.width = "0px";
        overlayRef.current.style.width = "0px";
    }
    function Aside() {
        return (
            <div>
                {/* ASIDE MAIN SECTION */}
                <div
                    className={`fixed overflow-hidden top-0 right-0 bg-white min-h-full w-0 z-20 shadow-2xl transition-all`}
                    ref={asideRef}
                >
                    {/* CROSS BUTTON CONTAINER */}
                    <div className="flex justify-between items-center h-[calc(80px+16px)] md:h-[calc(100px+16px)] custom-container">
                        <span></span>
                        <span onClick={close}>
                            <CgClose className="text-4xl text-primary active:scale-[0.95]" />
                        </span>
                    </div>

                    {/* NAVLINKS */}
                    <div className="flex flex-col items-center justify-start gap-10 mt-10 font-semibold">
                        <NavLink
                            className={`bg-primary text-white py-2 px-5 rounded shadow press`}
                            to={"/quote"}
                        >
                            REQUEST FOR QUOTE
                        </NavLink>
                        <NavLink
                            to="/home"
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold" : "press hover:text-primary"
                            }
                        >
                            HOME
                        </NavLink>
                        <div className={`relative group flex items-center justify-center`}>
                            <div
                                className={`${isAboutUs ? "text-primary underline font-bold" : "hover:text-primary"}`}
                            >
                                ABOUT US
                            </div>
                            <BsCaretRightFill className="inline-block ml-2 transition-all group-hover:rotate-180" />
                            <div className="absolute top-0 right-[120%] h-[120px] w-[0px] group-hover:w-[200px] transition-all bg-white custom-shadow rounded-lg overflow-hidden">
                                <div
                                    className={`flex justify-evenly items-start ps-2 flex-col h-full w-full`}
                                >
                                    {aboutUs.map((path) => (
                                        <div key={path} className="press">
                                            <NavLink
                                                to={path}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "press text-primary underline font-bold"
                                                        : "press hover:text-primary"
                                                }
                                            >
                                                {/* Map the correct name for each link */}
                                                {path
                                                    .replace("/", "")
                                                    .replace(/-/g, " ")
                                                    .toUpperCase()}
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={`relative group flex items-center justify-center`}>
                            <div
                                className={`${isSolutionsActive ? "text-primary underline font-bold" : "hover:text-primary"
                                    }`}
                            >
                                SOLUTIONS
                            </div>
                            <BsCaretRightFill className="inline-block ml-2 transition-all group-hover:rotate-180" />
                            <div className="absolute  top-0 right-[120%] overflow-auto h-[250px] w-[0px] group-hover:overflow-auto group-hover:w-[250px] transition-all bg-white custom-shadow rounded-lg custom-scrollbar">
                                <div
                                    className={`flex justify-evenly items-start ps-2 flex-col h-full w-full`}
                                >
                                    {solutionsPaths.map((path) => (
                                        <div key={path} className="press py-1.5">
                                            <NavLink
                                                to={path}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "press text-primary underline font-bold"
                                                        : "press hover:text-primary"
                                                }
                                            >
                                                {/* Map the correct name for each link */}

                                                {path
                                                    .replace("/solutions/", "") // Remove "/solutions/"
                                                    .replace(/-/g, " ") // Replace dashes with spaces
                                                    .toUpperCase() // Make text uppercase
                                                }
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={`relative group flex items-center justify-center`}>
                            <div
                                className={`${isServices ? "text-primary underline font-bold" : "hover:text-primary"
                                    }`}
                            >
                                SERVICES
                            </div>
                            <BsCaretRightFill className="inline-block ml-2 transition-all group-hover:rotate-180" />
                            <div className="absolute  top-0 right-[120%] overflow-auto h-[250px] w-[0px] group-hover:overflow-auto group-hover:w-[200px] transition-all bg-white custom-shadow rounded-lg custom-scrollbar">
                                <div
                                    className={`flex justify-evenly items-start ps-2 flex-col h-full w-full`}
                                >
                                    {services.map((path) => (
                                        <div key={path} className="press py-1.5">
                                            <NavLink
                                                to={path}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "press text-primary underline font-bold"
                                                        : "press hover:text-primary"
                                                }
                                            >
                                                {/* Map the correct name for each link */}
                                                {path
                                                    .replace("/services/", "") // Remove "/services/"
                                                    .replace(/-/g, " ") // Replace dashes with spaces
                                                    .toUpperCase() // Make text uppercase
                                                }
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <NavLink
                            to={"/careers"}
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold" : "press hover:text-primary"
                            }
                        >
                            CAREERS
                        </NavLink>
                        <NavLink
                            to={"/contact-us"}
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold" : "press hover:text-primary"
                            }
                        >
                            CONTACT US
                        </NavLink>
                        <NavLink
                            to={"/blogs"}
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold hover:text-primary hover:bg-gray-200 hover:rounded-lg hover:p-2" : "press hover:text-primary"
                            }
                        >
                            BLOGS
                        </NavLink>
                    </div>
                </div>
                {/* OVERLAY */}
                <div
                    className={`fixed overflow-hidden top-0 right-0 backdrop-blur-sm h-full w-[0px] z-10`}
                    onClick={close}
                    ref={overlayRef}
                ></div>
            </div>
        );
    }

    return (
        <>
            <section
                className={`whitespace-nowrap h-[10vh] fixed top-0 left-0 w-full bg-white z-50 shadow`}
            >
                {/* ASIDE */}
                <Aside />

                {/* NAVBAR */}
                <div className="flex items-center justify-between py-2 h-[10vh] custom-container">
                    {/* LEFT : LOGO SECTION */}
                    <div className="shrink-0">
                        <NavLink
                            to="/home"
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold" : "press hover:text-primary"
                            }
                        >
                            <img src={logo} className="" alt="logo" />

                        </NavLink>
                    </div>

                    {/* RIGHT : MENU ICON [ SMALL DEVICE ] */}
                    <div className="lg:hidden" onClick={open}>
                        <HiMenu className="text-4xl text-primary active:scale-[0.95]" />
                    </div>

                    {/* RIGHT :  NAVLINK [ LARGE DEVICE ]  */}
                    <div className="hidden lg:flex m-1 p-1 justify-between items-center font-semibold grow max-w-[1000px] ml-10">
                        <NavLink
                            to="/home"
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold" : "press hover:text-primary"
                            }
                        >
                            HOME
                        </NavLink>
                        <div className={`relative group flex items-center justify-center`}>
                            <NavLink
                                // to="/solutions"
                                className={() =>
                                    isAboutUs ? "text-primary underline font-bold" : "hover:text-primary"
                                }
                            >
                                ABOUT US
                            </NavLink>
                            <BsCaretDownFill className="inline-block ml-2 transition-all group-hover:rotate-180" />

                            <div className="absolute left-0 top-[120%] h-[0px] group-hover:h-[120px] w-[200px] transition-all bg-white custom-shadow rounded-lg overflow-hidden z-50">
                                <div
                                    className={`flex justify-evenly items-start ps-3 flex-col h-full w-full`}
                                >
                                    {aboutUs.map((path) => (
                                        <div key={path} className="press">
                                            <NavLink
                                                to={path}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "press text-primary underline font-bold"
                                                        : "press hover:text-primary"
                                                }
                                            >
                                                {/* Map the correct name for each link */}
                                                {path
                                                    .replace("/", "")
                                                    .replace(/-/g, " ")
                                                    .toUpperCase()}
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={`relative group flex items-center justify-center`}>
                            <NavLink
                                to="/solutions"
                                className={() =>
                                    isSolutionsActive ? "text-primary underline font-bold" : "hover:text-primary"
                                }
                            >
                                SOLUTIONS
                            </NavLink>
                            <BsCaretDownFill className="inline-block ml-2 transition-all group-hover:rotate-180" />

                            <div className="absolute left-0 top-[120%] h-[0px] overflow-auto group-hover:h-[250px] w-[250px] group-hover:overflow-auto transition-all bg-white custom-shadow rounded-lg  z-50 custom-scrollbar">
                                <div
                                    className={`flex justify-evenly items-start ps-3 flex-col h-full w-full`}
                                >
                                    {solutionsPaths.map((path) => (
                                        <div key={path} className="press py-1.5">
                                            <NavLink
                                                to={path}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "press text-primary underline font-bold"
                                                        : "press hover:text-primary"
                                                }
                                            >
                                                {/* Map the correct name for each link */}
                                                {path
                                                    .replace("/solutions/", "") // Remove "/solutions/"
                                                    .replace(/-/g, " ") // Replace dashes with spaces
                                                    .toUpperCase() // Make text uppercase
                                                }
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={`relative group flex items-center justify-center`}>
                            <NavLink
                                // to="/solutions"
                                className={() =>
                                    isServices ? "text-primary underline font-bold" : "hover:text-primary"
                                }
                            >
                                SERVICES
                            </NavLink>
                            <BsCaretDownFill className="inline-block ml-2 transition-all group-hover:rotate-180" />

                            <div className="absolute left-0 top-[120%] h-[0px] overflow-auto group-hover:h-[250px] w-[220px] group-hover:overflow-auto transition-all bg-white custom-shadow rounded-lg z-50 custom-scrollbar">
                                <div
                                    className={`flex justify-evenly items-start ps-3 flex-col h-full w-full`}
                                >
                                    {services.map((path) => (
                                        <div key={path} className="press py-1.5">
                                            <NavLink
                                                to={path}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? "press text-primary underline font-bold"
                                                        : "press hover:text-primary"
                                                }
                                            >
                                                {/* Map the correct name for each link */}
                                                {path
                                                    .replace("/services/", "") // Remove "/services/"
                                                    .replace(/-/g, " ") // Replace dashes with spaces
                                                    .toUpperCase() // Make text uppercase
                                                }
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <NavLink
                            to={"/careers"}
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold" : "press hover:text-primary"
                            }
                        >
                            CAREERS
                        </NavLink>
                        <NavLink
                            to={"/contact-us"}
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold" : "press hover:text-primary"
                            }
                        >
                            CONTACT US
                        </NavLink>
                        <NavLink
                            to={"/blogs"}
                            className={({ isActive }) =>
                                isActive ? "press text-primary underline font-bold " : "press hover:text-primary"
                            }
                        >
                            BLOGS
                        </NavLink>
                        <NavLink
                            className={`bg-primary text-white py-2 px-5 rounded shadow hover:bg-[#1d5cb5] transition duration-300 ease-in-out`}
                            to={`/quote`}
                        >
                            REQUEST FOR QUOTE
                        </NavLink>
                    </div>
                </div>

                <NavContact />
            </section>
        </>
    );
}

export default NavBar;
