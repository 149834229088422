import ReactDOM from "react-dom";

import { useState, useRef } from "react";
import { GrFormAdd } from "react-icons/gr";
import { GrFormSubtract } from "react-icons/gr";
import CustomButton1 from "../CustomButton1.component";
import NavBar from "../NavBar.component";
import Footer from "../Footer.component";
import { useEffect } from "react";
import CustomScrollbar from "../CustomScrollbar.component";

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

function Faqs() {
    let [activeTab, setActiveTab] = useState(null);
    let iconContainerRef = [];
    let answerRef = [];
    let current = null;

    const sliderRef = useRef(null);

    // Scroll to slider on first render
    useEffect(() => {
        if (sliderRef.current) {
            window.scrollTo({
                top: sliderRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    }, []);

    function Question(props) {
        answerRef[props.index] = useRef(null);
        iconContainerRef[props.index] = useRef(null);

        function Open() {
            if (current === null) {
                ReactDOM.render(<GrFormSubtract />, iconContainerRef[props.index].current);
                current = props.index;
                answerRef[props.index].current.style.height = "130px";
            } else if (current === props.index) {
                ReactDOM.render(<GrFormAdd />, iconContainerRef[current].current);
                current = null;
                answerRef[props.index].current.style.height = "0px";
            } else {
                ReactDOM.render(<GrFormAdd />, iconContainerRef[current].current);
                answerRef[current].current.style.height = "0px";
                current = props.index;
                answerRef[props.index].current.style.height = "130px";
                ReactDOM.render(<GrFormSubtract />, iconContainerRef[props.index].current);
            }
        }

        return (
            <section ref={sliderRef}  className="mb-5">
                <div
                    className="flex items-center justify-between pb-2 font-semibold border-b border-black active:scale-[0.99]"
                    onClick={Open}
                >
                    <div className="">{props.question}</div>
                    <div className="pl-3" ref={iconContainerRef[props.index]}>
                        {" "}
                        {<GrFormAdd />}
                    </div>
                </div>
                <div
                    className="pt-2 font-light text-justify h-[0px] overflow-hidden transition-['height']"
                    ref={answerRef[props.index]}
                >
                    {props.answer}
                </div>
            </section>
        );
    }

    return (
        <>
            <section className="">
                <section className="">
                    <NavBar />
                </section>

                <section>
                    <style>{css}</style>
                    <section className="bg-[url('./pages/assets/faq.svg')]">
                        <div className="py-16 custom-container mt-28">
                            <div className="heading">FAQs</div>
                            <div className="max-w-[700px] mt-10">
                                <Question
                                    index={0}
                                    question="What kind of software solutions does your company provide?"
                                    answer="Our company provides a wide range of software solutions, including custom software development, web application development, mobile app development, cloud computing solutions, and e-commerce solutions."
                                />
                                <Question
                                    index={1}
                                    question="Can you develop customized software solutions based on specific requirements?"
                                    answer="Yes, we specialize in developing customized software solutions based on specific business requirements. Our team of experienced developers works closely with clients to understand their needs and develop solutions that are tailored to their unique needs."
                                />
                                <Question
                                    index={2}
                                    question="What is the typical process for developing and delivering a software solution?"
                                    answer="Our software development process typically involves several stages, including requirements gathering, design and architecture, development, testing, and deployment. We also provide ongoing support and maintenance to ensure that the solution continues to meet the client's needs over time."
                                />
                                <Question
                                    index={3}
                                    question="How do you ensure the quality and reliability of the software solutions you provide?"
                                    answer="We take quality and reliability very seriously and follow a rigorous testing and quality assurance process to ensure that our software solutions are of the highest quality. This includes automated testing, manual testing, and code reviews, among other methods."
                                />
                                <Question
                                    index={4}
                                    question="What kind of support and maintenance services do you offer for the software solutions developed by your company?"
                                    answer="We offer comprehensive support and maintenance services for all software solutions developed by our company. This includes regular software updates, bug fixes, and technical support to ensure that the solution continues to function smoothly over time."
                                />
                                <div className="flex justify-center mt-10">
                                    <CustomButton1 label="Enquire" className="bg-primary" />
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <section>
                    <Footer />
                </section>

            <CustomScrollbar />
            </section>
        </>

    );
}

export default Faqs;
