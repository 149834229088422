import { parsePhoneNumberFromString } from 'libphonenumber-js';
import toast from "react-hot-toast";

export function ValidatePhoneNumber(phone1, phone2) {
    const phoneNumber = parsePhoneNumberFromString(`${phone1}${phone2}`);
    if (!phoneNumber) {
        toast.error("Invalid phone number format");
        return false;
    }
    if (!phoneNumber.isValid()) {
        toast.error("Invalid phone number");
        return false;
    }
    return true;
}
