import React, { useEffect, useRef, useState } from 'react'
import NavBar from '../NavBar.component';
import Slider from '../Slider.component';
import BookDemo from '../BookDemo.component';
import Footer from '../Footer.component';
import Banner5 from "../../assets/Banner5.svg"

import ServiceBox1 from "../../assets/ServiceBox1.svg";
import ServiceBox2 from "../../assets/ServiceBox2.svg";
import Bg5 from "../../assets/bg5.svg";
import ServiceBox from '../ServiceBox.component';
// import ServiceBoxLink from '../ServiceBoxLink.component';
import ServiceSlider from './ServiceSlider.component';
import SolutionVideo from '../Solution/SolutionVideo.component';
import ServicesVideo from './ServicesVideo.component';
import CustomScrollbar from '../CustomScrollbar.component';

let css = ``;

export default function Aws() {
  const serviceBoxRef = useRef(null);

  // Scroll to the ServiceBox section when the page loads
  useEffect(() => {
    serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <section className="">
      {/* NAVIGATION BAR */}
      <section className="">
        <NavBar />
      </section>

      {/* NAV-CONTACT */}
      <section>{/* <NavContact/> */}</section>

      {/* HERO SLIDER */}
      <section>
        <Slider images={[Banner5]} showDemoButton={true} />
      </section>

      {/* BOOK DEMO */}
      {/* <section>
        <BookDemo
          bgImage={Bg5}
          title={
            <>
              Book a <span className="text-primary">Demo</span>{" "}
            </>
          }
        />
      </section> */}

      {/* SERVICE */}
      <section ref={serviceBoxRef}>
        <style>{css}</style>
        <section>
          <style>{css}</style>
          <div className="py-20 custom-container">
            <h1 className="text-center heading pt-5">SERVICES</h1>
            <p className="mx-auto max-w-[700px] text-center text-sm mt-2 font-['montserrat']">
              Accelerate your progress with all the tools you need in one go. Your
              one-stop-shop, Analytics Valley, offers a wide range of services to help you
              advance throughout time.
            </p>
            <div className="mt-16">
              <ServiceBox
                thumbnail={ServiceBox1}
                position={"left"}
                title={"AWS"}
                content={
                  "We provide a comprehensive cloud computing platform that provides a wide range of scalable and cost-effective services. It enables businesses to leverage cloud infrastructure, storage, databases, analytics, and other tools to build, deploy, and manage applications securely. With its extensive service offerings, AWS empowers organizations to enhance flexibility, scalability, and agility while reducing the need for on-premises infrastructure."
                }
              />
            </div>

            <section>
              {/* https://www.youtube.com/watch?v=HhlWDXjX9kM&t=11s */}
              <ServicesVideo
                videoSrc="https://www.youtube.com/embed/HhlWDXjX9kM?start=11"
                videoTitle="AWS Cloud Services Overview"
              />
            </section>
            {/* <div className='flex justify-end'>
              <ServiceBoxLink
                img={ServiceBox2}
                title="Advanced Analytics"
                content="We provide sophisticated techniques..."
                redirectUrl="/services/advanced-analytics" // Pass the URL for redirection
              />
            </div> */}
            <div className='pt-10'>
              <ServiceSlider />
            </div>
          </div>
        </section>
      </section>

      {/* FOOTER */}
      <section>
        <Footer />
      </section>

      <CustomScrollbar />
    </section>
  );
}
