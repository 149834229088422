import Button from "@mui/material/Button";

import Solution1 from "../../assets/solution-1.svg";
import Solution2 from "../../assets/solution-2.svg";
import Solution3 from "../../assets/solution-3.svg";
import line from "../../assets/line.svg";

function Solutions() {
    function SolutionTab(props) {
        function clickHandlerSubmit(link) {
            let a = document.createElement("a");
            a.href = link || "https://youtu.be/HcqblRPsBes";
            a.target = "_blank";
            a.click();
        }

        if (props.side === "left") {
            return (
                <div className={` m-5 max-w-[450px] `}>
                    <div className={`flex flex-col items-end ${props.translate} `}>
                        <h2
                            className={`font-extrabold text-xs lg:text-base font-['lato'] text-tertiary`}
                        >
                            {props.title}
                        </h2>
                        <p className={`my-2 text-xs text-right `}>{props.content}</p>
                    </div>

                    <div className={` flex justify-between w-full`}>
                        <Button
                            variant="contained"
                            size="small"
                            className="py-1 text-xs capitalize whitespace-nowrap bg-primary"
                        >
                            Watch Video
                            <div
                                className="absolute top-0 left-0 w-full h-full"
                                onClick={() => clickHandlerSubmit(props.href)}
                            ></div>
                        </Button>
                    </div>
                </div>
            );
        }
        if (props.side === "right") {
            return (
                <div className={`flex flex-col m-5 max-w-[450px] `}>
                    <div className={`flex flex-col ${props.translate} `}>
                        <h2 className="text-xs font-extrabold lg:text-base font-['lato'] text-tertiary">
                            {props.title}
                        </h2>
                        <p className="my-2 text-xs">{props.content}</p>
                    </div>

                    <div className="flex justify-end">
                        <Button
                            variant="contained"
                            size="small"
                            className="py-1 text-xs capitalize whitespace-nowrap bg-primary"
                        >
                            Watch Video
                            <div
                                className="absolute top-0 left-0 w-full h-full"
                                onClick={() => clickHandlerSubmit(props.href)}
                            ></div>
                        </Button>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="py-20 custom-container">
            <h1 className="text-center heading font-['lato']">SOLUTIONS</h1>
            <p className="mt-1 text-sm text-center font-['montserrat']">
                Trusted by those who matter most - our clients speak to our unwavering commitment to
                excellence.
            </p>

            {/* MOBILE */}
            <div className="mt-10 lg:hidden">
                {/* UPPER */}
                <div className="flex items-stretch ">
                    <img src={Solution1} className="" alt="Solution1" />
                    <div>
                        <SolutionTab
                            side="right"
                            title="Sswayam CRM"
                            content="Contract management, sales tracking, analytics, automation, and customer service tools to streamline business processes."
                            translate=" translate-x-[-40%] sm:translate-x-[-20%] "
                        />
                        <SolutionTab
                            side="right"
                            title="Verify Me"
                            content="Tracking your field agent's task and verification process for all authentication procedures"
                        />
                        <SolutionTab
                            side="right"
                            title="School Management"
                            content="Online exam and course scheduling management with an efficient dashboard to manage student records and E-learning opportunities."
                        />
                        <SolutionTab
                            side="right"
                            title="Hospital Management"
                            content="Enabling sales friendly interface to track referral patients, UV sanitization, and patient live-streaming features for the healthcare organization"
                            translate=" translate-x-[-40%] sm:translate-x-[-20%] "
                        />
                    </div>
                </div>

                {/* LOWER */}
                <div className="flex items-stretch justify-end mt-10">
                    <div>
                        <SolutionTab
                            side="left"
                            title="Online Survey Tool"
                            content="Easy to use and understand customer feedback platform for people from all walks of life to upscale business platforms."
                            translate=" translate-x-[40%] sm:translate-x-[20%] "
                        />
                        <SolutionTab
                            side="left"
                            title="Online Tax Management"
                            content="Tax preparation, filing, tracking, and reporting tools to simplify tax compliance for businesses."
                        />
                        <SolutionTab
                            side="left"
                            title="Robotic Applications"
                            content="UV live streaming and UV sanitization feature for quick, accessible, and efficient business processes."
                        />
                        <SolutionTab
                            side="left"
                            title="Big Data/ Artificial Intelligence"
                            content="Analyzing big data, image processing, and business transactions to uncover patterns and trends to improve the customer interaction"
                            translate=" translate-x-[40%] sm:translate-x-[20%] "
                        />
                    </div>
                    <img src={Solution2} className="" alt="Solution1" />
                </div>
            </div>

            {/* DESKTOP */}
            <div className="items-stretch justify-center hidden mt-10 lg:flex">
                <div>
                    <SolutionTab
                        side="left"
                        title="Online Survey Tool"
                        content="Easy to use and understand customer feedback platform for people from all walks of life to upscale business platforms."
                        translate={" translate-x-[30%] "}
                        href="https://youtu.be/iaT_3AHOgK8"
                    />
                    <SolutionTab
                        side="left"
                        title="Online Tax Management"
                        content="Tax preparation, filing, tracking, and reporting tools to simplify tax compliance for businesses."
                        translate={" translate-x-[10%] "}
                    />
                    <SolutionTab
                        side="left"
                        title="Image Processing"
                        content="Image processing involves manipulating digital images using algorithms and mathematical operations. Analyze images to extract information or detect anomalies."
                    />

                    <SolutionTab
                        side="left"
                        title="Robotic UV Sanitizer/ Live Streaming"
                        content="UV live streaming and UV sanitization feature for quick, accessible, and efficient business processes."
                        translate={" translate-x-[10%] "}
                    />
                    <SolutionTab
                        side="left"
                        title="Sales booster"
                        content="A solution that tracks your sales field agents, their location, area covered and leads collected."
                        translate={" translate-x-[30%] "}
                        href="https://youtu.be/bm8Q8Db5ONI"
                    />
                </div>
                <img src={Solution3} className="max-w-[40%]" alt="" />
                <div>
                    <SolutionTab
                        side="right"
                        title="Sswayam CRM"
                        content="Contract management, sales tracking, analytics, automation, and customer service tools to streamline business processes."
                        translate={" translate-x-[-30%] "}
                        href="https://youtu.be/CVCyyAPWqFU"
                    />
                    <SolutionTab
                        side="right"
                        title="Verify Me"
                        content="Tracking your field agent's task and verification process for all authentication procedures"
                        translate={" translate-x-[-10%] "}
                        href="https://youtu.be/cwzCvgsGnLY"
                    />
                    <SolutionTab
                        side="right"
                        title="School Management"
                        content="Online exam and course scheduling management with an efficient dashboard to manage student records and E-learning opportunities."
                    />
                    <SolutionTab
                        side="right"
                        title="Hospital Management"
                        content="Enabling sales friendly interface to track referral patients, UV sanitization, and patient live-streaming features for the healthcare organization"
                        translate={" translate-x-[-10%] "}
                    />
                    <SolutionTab
                        side="right"
                        title="Online Exam Module"
                        content="Online exam software provides a platform to create and conduct exams, with various question types, customization options, and real-time reporting."
                        translate={" translate-x-[-30%] "}
                    />
                </div>
            </div>

            <div className="flex flex-col items-center gap-7">
                <div className="border-b border-black press">MANY MORE</div>
                <img src={line} alt="" />
            </div>
        </div>
    );
}

export default Solutions;
