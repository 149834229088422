import * as React from "react";
import Button from "@mui/material/Button";

let css = ` `;

function CustomButton1(props) {
    return (
        <div className="">
            <style>{css}</style>
            <Button
                size="small"
                variant={props.variant || "contained"}
                className={`${props.className} px-5 capitalize tracking-wide`}
            >
                {props.label}
                <div className="absolute top-0 left-0 w-full h-full " onClick={props.onClick}></div>
            </Button>
        </div>
    );
}

export default CustomButton1;
