import * as React from "react";
import Button from "@mui/material/Button";
import { useState } from "react";

function CustomFileUpload1(props) {
    function clickHandler() {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = "application/pdf";
        input.click();

        input.addEventListener("change", (e) => {
            props.setState(e.target.files[0]);
            if (e.target?.files[0]?.name.length > 20) {
                props.setFileName(e.target?.files[0]?.name.substring(0, 20) + "...");
            } else {
                props.setFileName(e.target?.files[0]?.name);
            }
        });
    }

    return (
        <>
            <div className={`flex flex-col ${props.className1} `}>
                <label htmlFor="id" className="[font-weight:500;]">
                    {props.label1}
                </label>
                <Button
                    size="small"
                    variant={props.variant || "contained"}
                    className={`${props.className} capitalize tracking-wide`}
                >
                    {props.fileName || props.label2}
                    <div
                        className="absolute top-0 left-0 w-full h-full "
                        onClick={clickHandler}
                    ></div>
                </Button>
            </div>
        </>
    );
}

export default CustomFileUpload1;
