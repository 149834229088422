import { BsCheck } from "react-icons/bs";

function CustomChecBox1(props) {
    function ClickHandler() {
        props.setState(!props.state);
    }

    return (
        <label className="flex items-center gap-3" onClick={ClickHandler}>
            <div className="border w-[20px] h-[20px] rounded flex justify-center items-center">
                {props.state ? <BsCheck className="text-white" /> : null}
            </div>
            <div className="press">{props.label}</div>
        </label>
    );
}

export default CustomChecBox1;
