import { useState } from "react";

import MissionVisionImg1 from "../../assets/MissionVision1.svg";
import MissionVisionImg2 from "../../assets/MissionVision2.svg";

function MissionVision() {
    let [activeTab, setActiveTab] = useState(0);

    function TabContainer(props) {
        return (
            <section>
                <div className="flex justify-around border-b border-gray-400">
                    {props.children}
                </div>
            </section>
        );
    }

    function Tab(props) {
        function clickhandler() {
            props.setActiveTab(props.index);
        }

        return (
            <div
                className={`text-center border-b-[3px] font-bold font-['lato'] text-2xl ${
                    activeTab === props.index
                        ? " border-pink-400 "
                        : " border-transparent "
                } px-5 md:px-10 lg:px-16 py-2 press`}
                onClick={clickhandler}
            >
                {props.label}
            </div>
        );
    }

    function TabContent(props) {
        return (
            <>
                {props.index === props.activeTab ? (
                    <div>{props.children}</div>
                ) : null}
            </>
        );
    }

    return (
        <section>
            <div className="py-16 custom-container bg-[#bbdfe9]">
                <TabContainer>
                    <Tab
                        index={0}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        label="Our Mission"
                    />
                    <Tab
                        index={1}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        label="Our Vision"
                    />
                </TabContainer>
                <TabContent
                    index={0}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                >
                    <div className="flex mt-10">
                        <p className="shrink text-xl font-['montserrat'] mt-5 text-justify">
                            Our mission is to empower businesses and individuals
                            through innovative and reliable software solutions.
                            We are dedicated to delivering exceptional quality
                            and exceeding client expectations by leveraging the
                            latest technologies and industry best practices. Our
                            goal is to help our clients thrive in the digital
                            age by providing customized software that optimizes
                            efficiency, enhances productivity, and drives
                            growth. We are committed to fostering long-term
                            partnerships, collaborating closely with our clients
                            to understand their unique needs and challenges.
                            Through our expertise and dedication, we aim to
                            transform their visions into reality and contribute
                            to their success.
                        </p>
                        <div className="hidden mx-10 md:block">
                            <img
                                src={MissionVisionImg1}
                                className="shrink-0 min-w-[200px] h-[200px]"
                                alt=""
                            />
                        </div>
                    </div>
                </TabContent>
                <TabContent
                    index={1}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                >
                    <div className="flex mt-10">
                        <p className="shrink text-xl font-['montserrat'] mt-5 text-justify">
                            Our vision is to be a globally recognized leader in
                            software development, renowned for our
                            transformative solutions and unwavering commitment
                            to client satisfaction. We aspire to shape the
                            future of technology by pushing boundaries and
                            exploring new frontiers. With a strong focus on
                            continuous learning and innovation, we aim to build
                            a dynamic and inclusive ecosystem that attracts top
                            talent and fosters creativity. We envision a world
                            where our software empowers businesses,
                            revolutionizes industries, and enhances the lives of
                            individuals. By embracing emerging technologies and
                            delivering cutting-edge solutions, we strive to make
                            a positive impact on the world and drive sustainable
                            growth for our clients.
                        </p>
                        <div className="hidden mx-10 md:block">
                            <img
                                src={MissionVisionImg2}
                                className="shrink-0 min-w-[200px] h-[200px]"
                                alt=""
                            />
                        </div>
                    </div>
                </TabContent>
            </div>
        </section>
    );
}

export default MissionVision;
