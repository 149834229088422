import { useRef } from "react";

function AvBusiness() {
    let sectionRef = useRef(null);
    let happyClientRef = useRef(null);
    let clientSatisfactionRef = useRef(null);
    let projectCompleteRef = useRef(null);
    let revenueGrothRef1 = useRef(null);
    let revenueGrothRef2 = useRef(null);
    let started = false;

    function StartCount(element, goal, increment, decimalDegit, time) {
        let interval = (increment * time) / goal;

        let timer = setInterval(() => {
            element.textContent = (
                Number(element.textContent) + increment
            ).toFixed(decimalDegit);
            if (element.textContent >= goal) {
                clearInterval(timer);
            }
        }, interval);
    }

    window.addEventListener("scroll", function () {
        if (window.scrollY >= sectionRef.current?.offsetTop - 500 && !started) {
            started = true;
            StartCount(happyClientRef.current, 99, 1, 0, 4 * 1000);
            StartCount(clientSatisfactionRef.current, 4.8, 0.1, 1, 4 * 1000);
            StartCount(projectCompleteRef.current, 150, 1, 0, 4 * 1000);
            StartCount(revenueGrothRef1.current, 80, 1, 0, 4 * 1000);
            StartCount(revenueGrothRef2.current, 95, 1, 0, 4 * 1000);
        }
    });

    return (
        <section ref={sectionRef}>
            <div className="custom-container bg-[url('./pages/assets/bg2.svg')] bg-cover py-20 font-['lato']">
                <h1 className="text-center heading text-primary">
                    SEE EVERYTHING ABOUT ANALYTICS VALLEY BUSINESS
                </h1>
                <div className="flex flex-wrap items-center justify-center gap-16 mt-16 md:gap-28">
                    <div className="flex flex-col items-center">
                        <h1 className="text-4xl font-semibold">
                            <span ref={happyClientRef}>0</span>%
                        </h1>
                        <p>Happy Clients</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <h1 className="text-4xl font-semibold">
                            {" "}
                            <span ref={clientSatisfactionRef}> 0.0</span>/5
                        </h1>
                        <p>Clients Satisfaction</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <h1 className="text-4xl font-semibold">
                            <span ref={projectCompleteRef}>0</span>+
                        </h1>
                        <p>Project Complete</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <h1 className="text-4xl font-semibold">
                            <span ref={revenueGrothRef1}>0</span>%{" "}
                            <span className="inline-block px-2 text-2xl font-light translate-y-1">
                                to
                            </span>
                            <span ref={revenueGrothRef2}>0</span>%
                        </h1>
                        <p>Revenue Growth</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AvBusiness;
