function CustomTextArea1(props) {
    return (
        <div className="flex flex-col ">
            <label htmlFor="id" className="[font-weight:500;]">
                {props.label}
            </label>
            <textarea
                id="id"
                rows={5}
                className={` ${props.className} outline-none border mt-2 px-2 py-2 rounded text-xs bg-transparent`}
                value={props.state}
                onChange={(e) => props.setState(e.target.value)}
            ></textarea>
        </div>
    );
}

export default CustomTextArea1;
