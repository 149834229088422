import { useState } from "react";

// Tab Icons
import { MdOutlineAccountBalance } from "react-icons/md";
import { MdCastForEducation } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdFastfood } from "react-icons/md";
import { HiDocument } from "react-icons/hi";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { MdContentPasteSearch } from "react-icons/md";
import { MdPublic } from "react-icons/md";
import { AiFillShop } from "react-icons/ai";
import { RiBusFill } from "react-icons/ri";
import { BsBricks } from "react-icons/bs";
import { GiPapers } from "react-icons/gi";

// Tab Content Stepper
import industryStepper1 from "../../assets/industryStepper1.svg";
import industryStepper2 from "../../assets/industryStepper2.svg";
import industryStepper3 from "../../assets/industryStepper3.svg";
import industryStepper4 from "../../assets/industryStepper4.svg";
import industryStepper5 from "../../assets/industryStepper5.svg";
import industryStepper6 from "../../assets/industryStepper6.svg";
import industryStepper7 from "../../assets/industryStepper7.svg";
import industryStepper8 from "../../assets/industryStepper8.svg";
import industryStepper9 from "../../assets/industryStepper9.svg";
import industryStepper10 from "../../assets/industryStepper10.svg";
import industryStepper11 from "../../assets/industryStepper11.svg";

// Video Thumbnail
import industoryThumbnail0 from "../../assets/industoryThumbnail0.svg";
import industoryThumbnail1 from "../../assets/industoryThumbnail1.svg";
import industoryThumbnail2 from "../../assets/industoryThumbnail2.svg";
import industoryThumbnail3 from "../../assets/industoryThumbnail3.svg";
import industoryThumbnail5 from "../../assets/industoryThumbnail5.svg";
import industoryThumbnail6 from "../../assets/industoryThumbnail6.svg";
import industoryThumbnail7 from "../../assets/industoryThumbnail7.svg";
import industoryThumbnail8 from "../../assets/industoryThumbnail8.svg";
import industoryThumbnail9 from "../../assets/industoryThumbnail9.svg";
import industoryThumbnail10 from "../../assets/industoryThumbnail10.svg";
import industoryThumbnail11 from "../../assets/industoryThumbnail11.svg";
import industoryThumbnail12 from "../../assets/industoryThumbnail12.svg";

let [activeTab, setActiveTab] = [null, null];

function Tab(props) {
    let tabStyleActive = {
        color: "white",
        backgroundColor: "#174997",
    };
    return (
        <div
            onClick={() => setActiveTab(props.index)}
            style={activeTab === props.index ? { ...tabStyleActive } : {}}
            className={`py-3 px-8 w-full my-[3px] flex justify-start items-center gap-[20px] [clip-path:polygon(85%_0,100%_50%,85%_100%,0_100%,0_0)] md:[clip-path:polygon(92%_0,100%_50%,92%_100%,0_100%,0_0)]`}
        >
            <span className="text-2xl">{props.icon}</span>
            <span className="hidden md:inline-block font-['lato']">{props.title}</span>
        </div>
    );
}

function TabContent(props) {
    function Heading(props) {
        return (
            <div className={"text-xl my-[20px] md:my-[30px] flex justify-start items-center gap-5"}>
                {" "}
                <span className={"text-3xl"}>{props.icon}</span>
                <span className="font-['lato'] text-2xl">{props.title}</span>
            </div>
        );
    }

    function Paragraph1(props) {
        return (
            <div className={"text-xs md:text-base font-['montserrat'] text-justify"}>
                {props.paragraph1}
            </div>
        );
    }

    function Stepper(props) {
        return (
            <div className={"hidden lg:block mt-7"}>
                <img src={props.steps} className="w-full" alt="" />
                {/* <div className="flex items-start w-full overflow-x-auto justify-evenly">
                    <div className="flex flex-col items-center justify-center max-w-[150px]">
                        <div className="font-semibold text-center">
                            {props.stepperContent[0][0]}
                        </div>
                        <p className="text-xs text-center">
                            {props.stepperContent[0][1]}
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center max-w-[150px]">
                        <div className="font-semibold text-center">
                            {props.stepperContent[1][0]}
                        </div>
                        <p className="text-xs text-center">
                            {props.stepperContent[1][1]}
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center max-w-[150px]">
                        <div className="font-semibold text-center">
                            {props.stepperContent[2][0]}
                        </div>
                        <p className="text-xs text-center">
                            {props.stepperContent[2][1]}
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center max-w-[150px]">
                        <div className="font-semibold text-center">
                            {props.stepperContent[3][0]}
                        </div>
                        <p className="text-xs text-center">
                            {props.stepperContent[3][1]}
                        </p>
                    </div>
                </div> */}
            </div>
        );
    }

    function Paragraph2() {
        return (
            <div className={"mt-7"}>
                <img src={props.videoThumbnail} className="float-left mb-3 mr-5" alt="" />
                {/* <div className="h-[200px] w-full max-w-[400px] bg-gray-400 "></div> */}
                <div className="text-xs md:text-base font-['montserrat'] text-justify">
                    {props.paragraph2}
                </div>
            </div>
        );
    }

    return (
        <div
            style={activeTab === props.index ? { display: "block" } : { display: "none" }}
            className="overflow-y-auto h-[750px] pr-5"
        >
            <Heading icon={props.icon} title={props.title}></Heading>

            {/* PARA : 1 */}
            <Paragraph1 paragraph1={props.paragraph1} />

            {/* STEPPER */}
            <Stepper stepperContent={props.stepperContent} steps={props.steps} />

            {/* PARA + VIDEO */}
            <Paragraph2 paragraph1={props.paragraph2} />
        </div>
    );
}

function IndustoryTabsSection() {
    [activeTab, setActiveTab] = useState(0);

    return (
        <div className="pb-12 pt-14 bg-primary custom-container">
            <h1 className="text-center text-white heading">INDUSTRIES</h1>
            <p className="mt-3 text-sm text-center text-white font-['montserrat']">
                No matter your industry, our powerful software solutions are tailored to meet your
                unique needs and drive your success
            </p>

            <div className="flex overflow-hidden bg-white h-[750px] scale-90 mt-5">
                {/* 11 TABS CONTAINER */}
                <div>
                    <div className="flex flex-col items-start gap-2 py-3 custom-shadow whitespace-nowrap px-[10px] h-[750px]">
                        <Tab index={0} icon={<MdOutlineAccountBalance />} title={<>Accounting</>} />
                        <Tab index={1} icon={<MdCastForEducation />} title={<>Education</>} />
                        <Tab index={2} icon={<FaMoneyCheckAlt />} title={<>Finance</>} />
                        <Tab index={3} icon={<BsBricks />} title={<>Ceramic</>} />
                        <Tab index={11} icon={<GiPapers />} title={<>Paper</>} />
                        <Tab index={4} icon={<HiDocument />} title={<>FMCG</>} />
                        <Tab
                            index={5}
                            icon={<MdOutlineHealthAndSafety />}
                            title={<>Healthcare</>}
                        />

                        <Tab index={6} icon={<FaUsers />} title={<>Human Resource</>} />
                        <Tab index={7} icon={<MdContentPasteSearch />} title={<>IT Service</>} />
                        <Tab index={8} icon={<MdPublic />} title={<>Govt. Managed Resources</>} />
                        <Tab index={9} icon={<AiFillShop />} title={<>Retail & E-commerce</>} />
                        <Tab index={10} icon={<RiBusFill />} title={<>Travel & Hospitality</>} />
                    </div>
                </div>

                {/* 11 TABS CONTENT */}
                <div className="pl-5 md:px-[30px] grow bg-[#dee6f1]">
                    <TabContent
                        index={0}
                        icon={<MdOutlineAccountBalance />}
                        title={<>Accounting</>}
                        paragraph1={
                            <>
                                The accounting industry is one of the most data-driven industries
                                that impact every corner of the modern business world. It works with
                                multidimensional financial operations to improve financial control,
                                reduce errors and compliance risks, and drive revenue growth.
                                Accounting management software can automate many of these processes,
                                reducing the time and effort required to complete them and
                                minimizing the risk of errors. Accounting management software can
                                help businesses to manage their financial operations more
                                efficiently,
                            </>
                        }
                        stepperContent={[
                            ["Tax & Account CRM", "Easy Reporting and Ticketing for your team"],
                            ["Verify me", "Privacy and security maintenance for clients"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Artificial intelligence", "For smooth data interpretation"],
                        ]}
                        steps={industryStepper1}
                        videoThumbnail={industoryThumbnail1}
                        paragraph2={
                            <>
                                {" "}
                                By providing real-time visibility into financial data, allowing
                                businesses to make informed decisions and optimize their resources.
                                The software can improve financial control by providing insights
                                into cash flow, revenue, and expenses. This can help businesses to
                                identify areas for improvement and make informed decisions to drive
                                growth. Additionally, accounting management software can help
                                businesses to stay compliant with regulatory requirements such as
                                taxation rules and accounting standards. The software can help
                                businesses to stay competitive in a rapidly evolving industry by
                                providing insights into market trends, competitor performance, and
                                customer behavior.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={1}
                        icon={<MdCastForEducation />}
                        title={<>Education</>}
                        paragraph1={
                            <>
                                Education and E-learning Management Software is a digital solution
                                designed to help educational institutions manage their academic
                                activities and administrative operations effectively. The software
                                provides a range of features, including Student Information
                                Management, Attendance Management, Online Test Management, Online
                                Feedback Survey Management, Robotic Live Steaming, UV Sanitization,
                                Analytics, Reporting tools, Course Scheduling, Online Learning
                                Tools, Communication, and Collaboration Tools.
                            </>
                        }
                        stepperContent={[
                            ["Online exam management", "Hassle-free exams and tests"],
                            ["Verify me", "Privacy and security maintenance for clients"],
                            ["UV Sanitization", "Hygiene maintenance with UV Rays"],
                            ["Feedback", " Easy Reporting and Ticketing for your team"],
                        ]}
                        steps={industryStepper2}
                        videoThumbnail={industoryThumbnail2}
                        paragraph2={
                            <>
                                {" "}
                                Education and E-learning Management Software is essential for
                                educational institutions as it enables them to automate and
                                streamline administrative tasks, allowing teachers and
                                administrators to focus on providing quality education to students.
                                The software also facilitates distance learning and e-learning,
                                making education accessible to students who are unable to attend
                                classes physically. automate and streamline administrative tasks,
                                allowing teachers and administrators to focus on providing quality
                                education to students. The software also facilitates distance
                                learning and e-learning, making education accessible to students who
                                are unable to attend classes physically.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={2}
                        icon={<FaMoneyCheckAlt />}
                        title={<>Finance</>}
                        paragraph1={
                            <>
                                Banking and Insurance Management Software is a comprehensive digital
                                solution designed to help financial institutions manage their
                                operations efficiently. The software provides a range of features,
                                including Customer Data Management, Account Management, Transactions
                                Tracking, Insurance Policies Management, Sales Management, Online
                                Feedback Survey Management, Robotic Live Steaming, Analytics, and
                                Reporting tools.
                            </>
                        }
                        stepperContent={[
                            ["Verify me", "Privacy and security maintenance for clients"],
                            ["Tax & Account CRM", "Easy Reporting and Ticketing for your team"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Feedback", "For smooth data interpretation"],
                        ]}
                        steps={industryStepper3}
                        videoThumbnail={industoryThumbnail3}
                        paragraph2={
                            <>
                                {" "}
                                Bank and Insurance Management Software is essential for financial
                                institutions as it enables them to automate and streamline their
                                operations, reduce errors, and improve customer experience. The
                                software enables financial institutions to manage their customer
                                accounts effectively, track transactions, and provide personalized
                                services to their customers. Additionally, the software provides
                                real-time insights into the performance of financial operations,
                                enabling institutions to make data-driven decisions to improve their
                                profitability and customer satisfaction.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={3}
                        icon={<BsBricks />}
                        title={<>Ceramic</>}
                        paragraph1={
                            <>
                                The ceramic industry is a dynamic sector that involves various
                                intricate processes and operations. To effectively manage and
                                navigate the complexities of this industry, a robust management or
                                workflow software is essential. This software serves as a
                                centralized platform that enables seamless coordination and
                                streamlines operations across different departments, ensuring
                                optimal efficiency and productivity.
                            </>
                        }
                        stepperContent={[
                            ["UV Sanitization", "Hygiene maintenance for offices"],
                            ["CRM", "Easy Reporting and Ticketing for your team"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Artificial intelligence", "For smooth data interpretation"],
                        ]}
                        steps={industryStepper4}
                        videoThumbnail={industoryThumbnail12}
                        paragraph2={
                            <>
                                {" "}
                                <p>
                                    One of the key advantages of a management software in the
                                    ceramic industry is real-time inventory tracking. With diverse
                                    raw materials and components involved, tracking inventory levels
                                    becomes crucial. A software solution provides real-time
                                    visibility into inventory, allowing businesses to optimize stock
                                    levels, minimize wastage, and ensure timely procurement. This
                                    feature helps in streamlining the supply chain, reducing costs,
                                    and maintaining uninterrupted production.
                                </p>
                                <p className="mt-4">
                                    Furthermore, a management software facilitates production
                                    planning, scheduling, and monitoring. It provides tools to
                                    manage production orders, allocate resources, and track
                                    progress. With real-time updates on production status,
                                    businesses can identify potential bottlenecks, make informed
                                    decisions, and optimize workflow for increased productivity.
                                </p>
                                <p className="mt-4">
                                    Quality control is paramount in the ceramic industry, and a
                                    software solution can greatly assist in this aspect. Quality
                                    control modules enable businesses to establish and maintain
                                    product consistency, adhere to industry standards, and monitor
                                    quality parameters throughout the manufacturing process. This
                                    ensures that the final products meet the required
                                    specifications, enhancing customer satisfaction and reducing the
                                    risk of costly rework or product recalls.
                                </p>
                                <p className="mt-4">
                                    A management or workflow software is indispensable for the
                                    ceramic industry. By leveraging such a solution, businesses can
                                    enhance productivity, reduce costs, improve customer
                                    satisfaction, and ultimately thrive in a competitive market. It
                                    provides the necessary tools and functionalities to streamline
                                    operations, optimize resources, and maintain consistent quality
                                    throughout the production process. Embracing technology and
                                    implementing a management software is key to achieving
                                    operational excellence and maximizing profitability in the
                                    dynamic ceramic industry.
                                </p>
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={4}
                        icon={<HiDocument />}
                        title={<>FMCG</>}
                        paragraph1={
                            <>
                                The FMCG (Fast Moving Consumer Goods) industry needs management
                                software to improve its operational efficiency, streamline
                                processes, and optimize supply chain management. Management software
                                can help FMCG companies manage their inventory, sales, and
                                distribution channels, which is critical to maintaining a
                                competitive advantage in the market.
                            </>
                        }
                        stepperContent={[
                            ["CRM", "Easy Reporting and Ticketing for your team"],
                            ["Verify me", "Privacy and security maintenance for clients"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Artificial intelligence", "For smooth data interpretation"],
                        ]}
                        steps={industryStepper5}
                        videoThumbnail={industoryThumbnail5}
                        paragraph2={
                            <>
                                {" "}
                                Inventory management software can help FMCG companies track their
                                stock levels, identify slow-moving products, and optimize their
                                supply chain. Sales management software can help companies analyze
                                sales data and forecast future demand, enabling them to make
                                informed decisions about inventory levels and production capacity.
                                Distribution management software can help companies manage their
                                distribution channels, ensuring that products are timely delivered
                                to retailers and consumers. Therefore, management software is
                                crucial for the success of FMCG companies in today's highly
                                competitive market.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={5}
                        icon={<MdOutlineHealthAndSafety />}
                        title={<>Healthcare</>}
                        paragraph1={
                            <>
                                Running a Healthcare organization is a complex and demanding task.
                                From managing patient records to scheduling appointments, countless
                                tasks need to be completed efficiently and accurately to provide the
                                best possible care to patients. Besides that, there are lots of
                                operational and administrative tasks that need to be taken care of.
                                Our Healthcare Management Software is a comprehensive digital
                                solution enabling healthcare institutions to effectively manage
                                their day-to-day operations and workflows. We offer different
                                solutions to boost your sales and customer loyalty and a uniform
                                task management dashboard for the overall efficiency and
                                profitability of the hospital.
                            </>
                        }
                        stepperContent={[
                            ["Sales booster ", "Track field agents for referral patients."],
                            ["UV Sanitization", "Hygiene maintenance with UV Rays"],
                            [" Robotic Live Streaming", "Live Streaming of your loved ones"],
                            ["Feedback", "Easy Reporting and Ticketing for your team"],
                        ]}
                        steps={industryStepper6}
                        videoThumbnail={industoryThumbnail6}
                        paragraph2={
                            <>
                                {" "}
                                The solution offers a range of features, including Patient Data
                                Management, Appointment Scheduling, Sales management, Online
                                Feedback Survey Management, Robotic Live Steaming, UV Sanitization,
                                Analytics, and Reporting tools. Hospital management software is
                                crucial for streamlining hospital operations and improving patient
                                care. By automating the sales tasks, the software reduces the burden
                                on field sales agents, allowing them to focus on getting quality
                                referral patients.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={6}
                        icon={<FaUsers />}
                        title={<>Human Resource</>}
                        paragraph1={
                            <>
                                Human resource management is a challenging and multi-faceted
                                discipline that requires a combination of technical, interpersonal,
                                and strategic skills to master. HR managers are responsible for
                                managing one of the most complex and unpredictable assets of an
                                organization - its employees. Each employee has unique needs,
                                expectations, and challenges, which can make managing them a
                                daunting task. HR managers need to stay updated with constantly
                                changing regulations and compliance requirements related to hiring.
                            </>
                        }
                        stepperContent={[
                            ["Attendance management", "Regular data management for employees"],
                            ["CRM", "Easy Reporting and Ticketing for your team"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Feedback", "For smooth data interpretation"],
                        ]}
                        steps={industryStepper7}
                        videoThumbnail={industoryThumbnail7}
                        paragraph2={
                            <>
                                {" "}
                                Human resource management software solves various problems such as
                                streamlining HR processes, automating administrative tasks,
                                improving data accuracy, enhancing employee engagement, and enabling
                                strategic decision-making. It is important because it simplifies HR
                                operations, reduces manual errors, saves time and effort, increases
                                productivity, enhances communication and collaboration, ensures
                                compliance with regulations, and provides valuable insights for
                                informed workforce management. By leveraging HR management software,
                                organizations can optimize their HR functions, boost efficiency, and
                                ultimately create a more effective and satisfied workforce.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={7}
                        icon={<MdContentPasteSearch />}
                        title={<>IT Service</>}
                        paragraph1={
                            <>
                                The IT industry is incredibly busy due to the constant demand for
                                technological solutions, software development, system maintenance,
                                and support. The industry operates in a fast-paced and dynamic
                                environment with multiple projects and tasks running concurrently.
                                IT Management software is essential to handle the complexity of
                                these operations, enabling efficient project management, task
                                tracking, and collaboration. It helps streamline workflows, improve
                                productivity, ensure effective communication, and maintain
                                high-quality deliverables, making it indispensable in managing the
                                busy and demanding nature of the IT industry.
                            </>
                        }
                        stepperContent={[
                            ["Robotic Live Streaming", "Live Streaming for your meetings"],
                            ["Verify me", "Privacy and security maintenance for clients"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Artificial intelligence", "For smooth data interpretation"],
                        ]}
                        steps={industryStepper8}
                        videoThumbnail={industoryThumbnail8}
                        paragraph2={
                            <>
                                {" "}
                                Project management software is crucial in tracking project progress,
                                assigning tasks to team members, and managing project timelines.
                                Resource allocation software helps managers assign resources to
                                projects based on their availability and skillset. Budgeting
                                software assists in managing finances, tracking expenses, and
                                generating financial reports. Customer relationship management
                                software is essential in managing customer interactions and ensuring
                                customer satisfaction. Without management software, the IT industry
                                would struggle to manage projects, allocate resources effectively,
                                and track expenses accurately. Thus, management software is critical
                                for IT companies to operate efficiently and successfully.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={8}
                        icon={<MdPublic />}
                        title={<>Govt. Managed Resources (Public Places)</>}
                        paragraph1={
                            <>
                                Management software is essential in maintaining public places, such
                                as parks, museums, and other public facilities. These places require
                                efficient management to ensure that they operate smoothly and
                                provide a positive experience for visitors.
                            </>
                        }
                        stepperContent={[
                            ["Robotic Live Streaming", "Live Streaming for all occasions"],
                            ["UV Sanitization", "Hygiene maintenance with UV Rays"],
                            ["Verify me", "Privacy and security maintenance for clients"],

                            ["CRM", "Easy Reporting and Ticketing for your team"],
                        ]}
                        steps={industryStepper9}
                        videoThumbnail={industoryThumbnail9}
                        paragraph2={
                            <>
                                {" "}
                                Management software can help schedule and manage staff, track
                                maintenance and repairs, and monitor visitor traffic. For instance,
                                in a museum, management software can help manage exhibits, schedule
                                tours, and track visitor attendance. Similarly, in a park,
                                management software can assist in managing park maintenance,
                                scheduling events, and managing reservations. Without management
                                software, keeping track of staff schedules, visitor traffic, and
                                maintenance schedules can be challenging, resulting in
                                inefficiencies and inconvenience to visitors. Moreover, the lack of
                                proper management can lead to safety hazards, property damage, and
                                other issues that could negatively impact the public's perception of
                                the facility.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={9}
                        icon={<AiFillShop />}
                        title={<>Retail & E-commerce</>}
                        paragraph1={
                            <>
                                Public places, such as parks, museums, stadiums, and transportation
                                hubs, can greatly benefit from management software. Firstly, it
                                enables efficient facility management by automating tasks like
                                scheduling, maintenance, and security. This ensures smooth
                                operations, minimizes downtime, and enhances visitor experience.
                                Secondly, management software enables effective crowd management,
                                allowing real-time monitoring, capacity management, and crowd flow
                                optimization. This helps prevent overcrowding, improve safety, and
                                provide a pleasant environment for visitors. Additionally, it
                                facilitates ticketing and reservation systems, enabling seamless
                                transactions and reducing queues. Moreover, data analytics provided
                                by the software helps identify trends, optimize resource allocation,
                                and improve overall operational efficiency. In essence, management
                                software empowers public places to deliver better services, improve
                                visitor satisfaction, and enhance overall management capabilities.
                            </>
                        }
                        stepperContent={[
                            ["CRM", "Easy Reporting and Ticketing for your team"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Artificial intelligence", "For smooth data interpretation"],
                            ["Feedback", "For smooth data interpretation"],
                        ]}
                        steps={industryStepper10}
                        videoThumbnail={industoryThumbnail10}
                        paragraph2={
                            <>
                                {" "}
                                Public places benefit greatly from management software for several
                                reasons. Firstly, it simplifies administrative tasks by automating
                                processes like scheduling staff, managing shifts, and handling
                                payroll.This streamlines operations, reduces paperwork, and
                                increases efficiency,and it also enhances communication and
                                coordination among different departments or teams within a public
                                place. It enables real-time collaboration, facilitates effective
                                task assignment and tracking, and improves overall teamwork.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={10}
                        icon={<RiBusFill />}
                        title={<>Travel & Hospitality</>}
                        paragraph1={
                            <>
                                The Travel & Hospitality industry involves a multitude of complex
                                and interconnected processes that can be time-consuming and
                                error-prone when managed manually. Travel management software can
                                automate many of these processes, reducing the time and effort
                                required to complete them and minimizing the risk of errors. Travel
                                management software can help businesses to manage their operations
                                more efficiently, by providing real-time visibility into inventory
                                levels, booking status, and customer information.
                            </>
                        }
                        stepperContent={[
                            ["CRM", "Easy Reporting and Ticketing for your team"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Artificial intelligence", "For smooth data interpretation"],
                            ["Verify me", "Privacy and security maintenance for clients"],
                        ]}
                        steps={industryStepper11}
                        videoThumbnail={industoryThumbnail11}
                        paragraph2={
                            <>
                                {" "}
                                The travel and hospitality sector greatly benefits from management
                                software due to its complex and dynamic nature. It simplifies
                                reservation and booking processes, allowing customers to make online
                                reservations, manage bookings, and access real-time availability
                                information. This improves customer convenience and reduces
                                administrative workload. The management software enables efficient
                                inventory and resource management, optimizing room allocations,
                                tracking amenities, and managing housekeeping tasks. This
                                streamlines operations, improves staff productivity, and enhances
                                overall guest satisfaction. Additionally, management software
                                provides robust reporting and analytics capabilities, allowing
                                businesses to analyze performance metrics, track revenue, and make
                                informed decisions for pricing, marketing strategies, and resource
                                allocation.
                            </>
                        }
                    ></TabContent>
                    <TabContent
                        index={11}
                        icon={<GiPapers />}
                        title={<>Paper Industry</>}
                        paragraph1={
                            <>
                                Efficiently managing the intricacies of the paper industry demands a
                                powerful management or workflow software solution. With its diverse
                                processes spanning from raw material procurement to production
                                planning and quality control, a centralized platform becomes
                                essential. Embracing such software enables seamless coordination and
                                streamlined operations across departments, propelling businesses
                                towards operational excellence.
                            </>
                        }
                        stepperContent={[
                            ["CRM", "Easy Reporting and Ticketing for your team"],
                            ["Online Survey Feedback", "Easy-to-use feedback interface"],
                            ["Artificial intelligence", "For smooth data interpretation"],
                            ["Verify me", "Privacy and security maintenance for clients"],
                        ]}
                        steps={industryStepper11}
                        videoThumbnail={industoryThumbnail12}
                        paragraph2={
                            <>
                                <p>
                                    Harnessing the potential of management software, paper industry
                                    players gain real-time visibility into inventory levels, a vital
                                    advantage in this dynamic sector. By tracking inventory in
                                    real-time, businesses can optimize stock levels, minimize
                                    wastage, and ensure timely procurement. This capability
                                    streamlines the supply chain, drives cost reductions, and
                                    sustains uninterrupted production.
                                </p>
                                <p className="mt-4">
                                    Beyond inventory management, management software facilitates
                                    production planning, scheduling, and monitoring. It empowers
                                    businesses to manage production orders, allocate resources
                                    efficiently, and track progress in real-time. With this
                                    comprehensive overview, decision-makers can identify
                                    bottlenecks, make informed decisions, and optimize workflows to
                                    maximize productivity.
                                </p>
                                <p className="mt-4">
                                    Beyond inventory management, management software facilitates
                                    production planning, scheduling, and monitoring. It empowers
                                    businesses to manage production orders, allocate resources
                                    efficiently, and track progress in real-time. With this
                                    comprehensive overview, decision-makers can identify
                                    bottlenecks, make informed decisions, and optimize workflows to
                                    maximize productivity.
                                </p>
                                <p className="mt-4">
                                    Quality control is paramount in the paper industry, and a
                                    software solution plays a pivotal role in ensuring consistent
                                    product excellence. By leveraging quality control modules,
                                    businesses establish and maintain product consistency, adhere to
                                    industry standards, and monitor quality parameters throughout
                                    the manufacturing process. This meticulous approach safeguards
                                    customer satisfaction and minimizes costly rework or potential
                                    product recalls.
                                </p>
                                <p className="mt-4">
                                    Moreover, management software offers comprehensive analytics and
                                    reporting capabilities, providing valuable insights into key
                                    performance metrics. Decision-makers can access real-time data
                                    on production output, resource utilization, and inventory
                                    turnover. Armed with this information, businesses can identify
                                    areas for improvement, optimize processes, and drive
                                    data-informed decisions for sustainable growth.
                                </p>
                                <p className="mt-4">
                                    The paper industry thrives on operational excellence, and
                                    management software is the catalyst to unlock its full
                                    potential. By embracing this technology, businesses can optimize
                                    inventory management, streamline production processes, ensure
                                    quality compliance, and make informed decisions. With management
                                    software as their ally, paper industry players can drive
                                    efficiency, reduce costs, and achieve unparalleled success in a
                                    competitive market.
                                </p>
                            </>
                        }
                    ></TabContent>
                </div>
            </div>
        </div>
    );
}

export default IndustoryTabsSection;
