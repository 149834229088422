import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function NavContact() {
    return (
        <div className="flex items-center justify-between text-xs text-white bg-tertiary custom-container md:text-sm h-[4vh] select-text">
            <div className="flex items-center border-white">
                <MdEmail className="mr-2 text-base" />
                info@analyticsvalley.com
            </div>
            <div className="flex items-center border-white">
                <BsFillTelephoneFill className="mr-2 text-white" /> +91 9727397555
            </div>
        </div>
    );
}

export default NavContact;
