import * as React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function ServiceBoxLink(props) {
    const navigate = useNavigate();

    const handleViewMore = () => {
        navigate(props.redirectUrl); // Use the URL passed in the props
    };

    return (
        <div className="flex items-stretch md:flex-col overflow-hidden rounded-md md:w-[330px] bg-secondary mb-10">
            <div className="flex items-center justify-center w-full bg-secondary max-h-[250px]">
                <img src={props.img} alt="" className="object-center" />
            </div>
            <div className="flex flex-col justify-center w-full p-5 text-white grow bg-primary">
                <div className="flex items-stretch ">
                    <div className="text-lg">
                        <p className="text-[26px]">{props.title}</p>
                    </div>
                </div>
                <div className="mt-4 text-xs md:text-base">
                    {props.content.length > 200
                        ? props.content.substring(0, 200) + "..."
                        : props.content}
                </div>
                <div className="flex justify-center mt-4">
                    <Button
                        variant="contained"
                        size="small"
                        className="font-semibold capitalize bg-white text-primary"
                        onClick={handleViewMore}
                    >
                        Learn More
                    </Button>
                </div>
            </div>
        </div>
    );
}
