import { NavLink } from "react-router-dom";

import { IoLocation } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BsFacebook } from "react-icons/bs";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";

import * as React from "react";
import Button from "@mui/material/Button";

function Footer() {
    return (
        <>
            <div className="flex flex-wrap justify-center py-20 text-white gap-y-16 bg-tertiary font-['montserrat']">
                {/* HEAD OFFICE */}
                <div className="flex justify-center w-[250px]">
                    <div className="flex flex-col content-center gap-5 px-5">
                        <h1 className="text-xl font-semibold">Head Office</h1>
                        <div className="flex items-center gap-3 ">
                            <IoLocation className="text-2xl min-w-[25px] text-white" />
                            <span className="text-xs ">
                                12, 1st Floor, Sigma Arcade Marathahalli, Bengaluru, Karnataka,
                                India,560037
                            </span>
                        </div>
                        <div className="flex items-center gap-3 ">
                            <IoCallSharp className="text-2xl w-[25px] text-white" />
                            <span className="text-xs">+91 9727397555</span>
                        </div>
                        <div className="flex items-center gap-3">
                            <MdEmail className="text-2xl min-w-[25px] text-white" />
                            <span className="text-xs">info@analyticsvalley.com</span>
                        </div>
                        <div className="flex items-center gap-5">
                            <a href="https://www.youtube.com/@AnalyticsValley" target="_blank">
                                <AiFillYoutube className="text-3xl min-w-[25px] text-red-500  " />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/analytics-valley/"
                                target="_blank"
                            >
                                <FaLinkedinIn className="text-2xl min-w-[25px] bg-[#0077B5] text-white" />
                            </a>
                            <a href="https://twitter.com/swayamtwit" target="_blank">
                                <FaTwitter className="text-2xl min-w-[25px] text-[#1DA1F2] " />
                            </a>
                            <a href="https://www.facebook.com/AnalyticsValley" target="_blank">
                                <BsFacebook className="[font-size:22px] min-w-[25px] text-[#316FF6] bg-white" />
                            </a>
                        </div>
                    </div>
                </div>

                {/* OUR SERVICE */}
                <div className="flex justify-center w-[220px]">
                    <div className="flex flex-col content-center gap-3 px-5 text-xs">
                        <h1 className="mb-2 text-xl font-semibold">Our Service</h1>
                        <div>Consulting</div>
                        <div>Mobile App</div>
                        <div>Web Application</div>
                        <div>Data Science</div>
                        <div>Basic Analytics</div>
                        <div>Advanced Analytics</div>
                    </div>
                </div>

                {/* OUR SOLUTION */}
                <div className="flex justify-center w-[220px]">
                    <div className="flex flex-col content-center gap-3 px-5 text-xs">
                        <h1 className="mb-2 text-xl font-semibold">Our Solution</h1>
                        <div>Sswayam CRM</div>
                        <div>Online Survey/Feedback</div>
                        <div>Verify Me</div>
                        <div>Robotic Live Streaming</div>
                        <div>Home UV Sanitization</div>
                        <div>Hospital Management </div>
                    </div>
                </div>

                {/* COMPANY */}
                <div className="flex justify-center w-[220px]">
                    <div className="flex flex-col content-center gap-3 px-5 text-xs">
                        <h1 className="mb-2 text-xl font-semibold">Company</h1>
                        <div className="cursor-pointer press">
                            <NavLink to="/about-our-story">About Us</NavLink>
                        </div>
                        <div className="cursor-pointer press">
                            <NavLink to="/careers">Career</NavLink>
                        </div>
                        <div className="cursor-pointer press">
                            <NavLink to="/blogs">Blogs</NavLink>
                        </div>
                        <div className="cursor-pointer press">
                            <NavLink to="/faqs">FAQs</NavLink>
                        </div>
                    </div>
                </div>

                {/* FOLLOW */}
                <div className="flex justify-center w-[220px]">
                    <div className="flex flex-col items-center content-center gap-5 px-5 text-xs">
                        <h1 className="text-xl font-semibold ">Follow</h1>
                        <Button variant="contained" className="capitalize bg-primary text-white">
                            {" "}
                            <div className="px-2">Contact Us</div>{" "}
                            <NavLink
                                to="/contact-us"
                                className="absolute top-0 left-0 w-full h-full"
                            ></NavLink>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
