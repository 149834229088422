import { HiUsers } from "react-icons/hi";
import { AiFillSetting } from "react-icons/ai";
import { TbBulbFilled } from "react-icons/tb";

import whatWeOffer1 from "../../assets/whatWeOffer1.svg";
import whatWeOffer2 from "../../assets/whatWeOffer2.svg";
import whatWeOffer3 from "../../assets/whatWeOffer3.svg";

function WeOffer() {
    return (
        <section className="py-4 md:py-16 custom-container bg-secondary">
            <div className="font-['lato']">
                <h1 className="text-2xl font-bold text-center md:text-[40px] text-primary">
                    WHAT WE OFFER
                </h1>
                <p className="mt-2 text-sm text-center font-['montserrat']">
                    Learn what Analytics Valley can do for you
                </p>
                <p className="mt-8 text-xs text-center sm:text-2xl max-w-[700px] mx-auto">
                    We believe in revolutionizing your marketing, sales, support, and IT teams with
                    a frictionless, easy-to-use workflow.
                </p>
                <div className="flex flex-wrap items-center justify-center mt-8 text-base md:text-3xl gap-x-[60px] gap-y-4 font-semibold">
                    <span className="flex items-center gap-2">
                        <img src={whatWeOffer1} className="w-[30px] md:w-[40px]" />
                        Consulting
                    </span>
                    <span className="flex items-center gap-2">
                        <img src={whatWeOffer2} className="w-[30px] md:w-[40px]" />
                        Services
                    </span>
                    <span className="flex items-center gap-2">
                        <img src={whatWeOffer3} className="w-[30px] md:w-[40px]" />
                        Pre-Build Solution / Products
                    </span>
                </div>
            </div>
        </section>
    );
}

export default WeOffer;
