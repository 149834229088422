import profile1 from "../../assets/profile1.svg";

function LeadershipProfile() {
    return (
        <section>
            <div className="py-16 custom-container bg-[#f4f6f9] md:bg-[#fff] md:bg-[url('./pages/assets/bg6.svg')]">
                <h1 className="text-center heading">Leadership Profile</h1>
                <p className="mt-2 max-w-[800px] mx-auto text-center text-sm font-['montserrat']">
                    Exploring the vision, drive, and accomplishments of our
                    inspiring leaders: Meet the faces behind our success story
                </p>
                <div className="mt-20">
                    {/* GRAY CONTAINER */}
                    <div className="text-white border rounded-lg bg-tertiary">
                        {/* HEADING LINE */}
                        <div className="flex gap-3 font-['lato']">
                            {/* PROFILE PHOTO */}
                            <div className="translate-y-[-50%]">
                                <img
                                    src={profile1}
                                    className="w-[100px]"
                                    alt=""
                                />
                            </div>

                            {/* NAME + DESIGNATION */}
                            <div className="mt-3">
                                {/* NAME */}
                                <div className="text-xl font-bold">
                                   <span className="mr-1" >|</span> Sswayam P Ranjan
                                </div>

                                {/* DESIGNATION */}
                                <div className="mt-2 text-xs">
                                    Chief Executive Officer
                                </div>
                            </div>
                        </div>

                        {/* CONTENT */}
                        <div className="translate-y-[-10px] px-7 pb-8 text-xs text-justify md:text-base font-['montserrat']" > 
                            Sswayam, an alumni of ISB Hyderabad and IIITM
                            Gwalior, is one of the co-founders of the
                            organization and has varied experience across
                            domains like Telecom, Retail and Airlines. He
                            specializes in Predictive Modeling and
                            Visualization, leveraging his OLTP CRM experience to
                            customer analytics. Having worked with Big Data
                            Analytics Practice in Huawei, he has an in-depth
                            knowledge in SAS EMiner and Business Analytics. He
                            has a deep understanding of Statistical Analysis
                            Methods, Linear and Discrete Optimization, Time
                            Series Analysis, Multiple Regression Analysis and
                            Real Time Analytics. Being a diligent and
                            self-motivated technocrat having rich & qualitative
                            experience majorly in Project Execution, Application
                            Management, Team management, Project Delivery and
                            CRM, Sswayam brings immense value to the leadership
                            team.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default LeadershipProfile;
