import NavBar from "./components/NavBar.component";
import Slider from "./components/Slider.component";
import BookDemo from "./components/BookDemo.component";
import Services from "./components/Service/Services.component";
import Footer from "./components/Footer.component";

import Banner5 from "./assets/Banner5.svg";

import Bg5 from "./assets/bg5.svg";
import CustomScrollbar from "./components/CustomScrollbar.component";

function Service() {
    return (
        <section className="">
            {/* NAVIGATION BAR */}
            <section className="">
                <NavBar />
            </section>

            {/* NAV-CONTACT */}
            <section>{/* <NavContact/> */}</section>

            {/* HERO SLIDER */}
            <section>
                <Slider images={[Banner5]} showDemoButton={true} />
            </section>

            {/* BOOK DEMO */}
            <section>
                <BookDemo
                    bgImage={Bg5}
                    title={
                        <>
                            Book a <span className="text-primary">Demo</span>{" "}
                        </>
                    }
                />
            </section>

            {/* SERVICE */}
            <section>
                <Services />
            </section>

            {/* FOOTER */}
            <section>
                <Footer />
            </section>

            <CustomScrollbar />
        </section>
    );
}

export default Service;
