import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Home from "./pages/Home.pages";
import Quote from "./pages/Quote.page";
import Solution from "./pages/Solution.page";
import Service from "./pages/Service.page";
import AboutOutStory from "./pages/AboutOutStory.page";
import BoardOfAdvisory from "./pages/BoardOfAdvisory.page";
import ContactUs from "./pages/ContactUs.page";
import Career from "./pages/Career.page";
import Blogs from "./pages/Blogs.page";
import Aws from "./pages/components/Service/Aws.component";
import AdvanceAnalytics from "./pages/components/Service/AdvanceAnalytics.component";
import BasicAnalytics from "./pages/components/Service/BasicAnalytics.component";
import DataScience from "./pages/components/Service/DataScience.component";
import MobileApp from "./pages/components/Service/MobileApp.component";
import WebApp from "./pages/components/Service/WebApp.component";
import Lot_Robotics from "./pages/components/Service/Lot_Robotics.component";
import Consulting from "./pages/components/Service/Consulting.component";
import BasicCrmSales from "./pages/components/Solution/BasicCrmSales.component";
import BasicCrmService from "./pages/components/Solution/BasicCrmService.component";
import HospitalManagement from "./pages/components/Solution/HospitalManagement.component";
import ImageProcessing from "./pages/components/Solution/ImageProcessing.component";
import MachineLearning from "./pages/components/Solution/MachineLearning.component";
import OflineTaxManagement from "./pages/components/Solution/OflineTaxManagement.component";
import OnlineTaxManagement from "./pages/components/Solution/OnlineTaxManagement.component";
import OnlineExamModule from "./pages/components/Solution/OnlineExamModule.component";
import OnlineSurveyTool from "./pages/components/Solution/OnlineSurveyTool.component";
import RoboticUvSanitizer from "./pages/components/Solution/RoboticUvSanitizer.component";
import StandardSswayam from "./pages/components/Solution/StandardSswayam.component";
import SswayamCrmPro from "./pages/components/Solution/SswayamCrmPro.component";
import SchoolManagement from "./pages/components/Solution/SchoolManagement.component";
import VerifyMe from "./pages/components/Solution/VerifyMe.component";
import SswayamCrm from "./pages/components/Solution/SswayamCrm.component";
import Faqs from "./pages/components/Solution/Faqs.components";
import PageNoFound from "./pages/PageNoFound";

function App() {
    return (
        <div className="app">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/quote" element={<Quote />} />
                <Route path="/about-our-story" element={<AboutOutStory />} />
                <Route path="/board-of-advisory" element={<BoardOfAdvisory />} />
                <Route path="/solutions" element={<Solution />} />
                <Route path="/solutions/sswayam-crm" element={<SswayamCrm />} />
                <Route path="/solutions/basic-sswayam-crm-sales" element={<BasicCrmSales />} />
                <Route path="/solutions/basic-sswayam-crm-service" element={<BasicCrmService />} />
                <Route path="/solutions/hospital-management" element={<HospitalManagement />} />
                <Route path="/solutions/image-processing" element={<ImageProcessing />} />
                <Route path="/solutions/machine-learning" element={<MachineLearning />} />
                <Route path="/solutions/offline-tax-management" element={<OflineTaxManagement />} />
                <Route path="/solutions/online-tax-management" element={<OnlineTaxManagement />} />
                <Route path="/solutions/online-exam-module" element={<OnlineExamModule />} />
                <Route path="/solutions/online-survey-tool" element={<OnlineSurveyTool />} />
                <Route path="/solutions/robotic-uv-sanitizer" element={<RoboticUvSanitizer />} />
                <Route path="/solutions/standard-sswayam-crm" element={<StandardSswayam />} />
                <Route path="/solutions/sswayam-crm-pro" element={<SswayamCrmPro />} />
                <Route path="/solutions/school-management" element={<SchoolManagement />} />
                <Route path="/solutions/verify-me" element={<VerifyMe />} />

                <Route path="/services" element={<Service />} />
                <Route path="/services/aws" element={<Aws />} />
                <Route path="/services/advanced-analytics" element={<AdvanceAnalytics />} />
                <Route path="/services/basic-analytics" element={<BasicAnalytics />} />
                <Route path="/services/consulting" element={<Consulting />} />
                <Route path="/services/data-science" element={<DataScience />} />
                <Route path="/services/iot-robotics" element={<Lot_Robotics />} />
                <Route path="/services/mobile-application" element={<MobileApp />} />
                <Route path="/services/web-application" element={<WebApp />} />

                <Route path="/careers" element={<Career />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="*" element={<PageNoFound />} />
            </Routes>
            <Toaster position="top-right" reverseOrder={false} />
        </div>
    );
}

export default App;
