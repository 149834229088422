import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need10 from "../../assets/need-10.svg";
import NeedImg16 from "../../assets/NeedImg16.svg";
import Clientele from '../Home/Clientele.component'
import thumbImg from '../../assets/SolutionsImage/vectorImages/3d-rendering-biorobots-concept.jpg'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function RoboticUvSanitizer() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0  max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={9}
                                    icon={Need10}
                                    thumbnail={thumbImg}
                                    title={
                                        "Robotic UV Sanitizer and Robotic Live Streaming"
                                    }
                                    content={[
                                        `Robotic UV sanitizers and robotic live streaming are two technological advancements that have revolutionized how we live, work, and interact with the world around us.`,

                                        `Robotic UV sanitizers are designed to eliminate harmful germs and bacteria from surfaces using ultraviolet light. This technology has become increasingly important in the wake of the COVID-19 pandemic as businesses and individuals seek ways to maintain a clean and safe environment. Robotic UV sanitizers can be used in hospitals, hotels, schools, offices, and other public spaces, providing a fast and effective solution to hygiene concerns. These robots can operate autonomously, reducing the risk of human error and increasing efficiency.`,

                                        `On the other hand, robotic live streaming is a technology that enables remote broadcasting of events in real time. This technology has become increasingly popular in recent years, particularly in the entertainment industry. Robotic cameras can capture live events, such as concerts and sports games, and broadcast them to audiences worldwide. This technology provides a unique and immersive experience, enabling viewers to feel part of the action.`,
                                    ]}
                                    image={NeedImg16}
                                    caption={["Education", "Healthcare", "Finance"]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>

                {/* SOLUTION VIDEO */}
                {/* <section>
                    <SolutionVideo />
                </section> */}

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
