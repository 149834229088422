import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need12 from "../../assets/need-12.svg";
import NeedImg12 from "../../assets/NeedImg12.svg";
import thumbImg from '../../assets/SolutionsImage/vectorImages/image (2).png'


let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function SswayamCrmPro() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // // Scroll to the ServiceBox section when the page loads
    // useEffect(() => {
    //     serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    // }, []);
    return (
        <>
            <section className="">
                <section>
                    <section>
                        <style>{css}</style>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={11}
                                    icon={Need12}
                                    thumbnail={thumbImg}
                                    title={"Sswayam CRM Pro+"}
                                    content={[
                                        "With Machine Learning (ML) and Artificial Intelligence (AI) we bring the power to revolutionize the way businesses operate in the modern world. These cutting-edge technologies are making it possible to analyze vast amounts of data and provide intelligent insights and predictions that can help drive business decisions. ML and AI algorithms are used in diverse industries, from healthcare and finance to retail and manufacturing, and can help automate mundane tasks, increase productivity, and reduce costs. With advancements in computing power and data storage capabilities, ML and AI are becoming more accessible to businesses of all sizes, unlocking new possibilities for growth and innovation.",
                                    ]}
                                    image={NeedImg12}
                                    caption={[
                                        "IT infrastructure support, Networking and Security.",
                                    ]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>
            </section>
        </>
    )
}
