import React, { useEffect, useRef, useState } from 'react'
import NavBar from '../NavBar.component';
import Slider from '../Slider.component';
import BookDemo from '../BookDemo.component';
import Footer from '../Footer.component';
// import Banner5 from "../../assets/SolutionsImage/wer.jpg"
import Banner5 from "../../assets/Banner5.svg"
import ServiceBox7 from "../../assets/ServiceBox7.svg";
import Bg5 from "../../assets/bg5.svg";
import ServiceBox from '../ServiceBox.component';
import ServiceSlider from './ServiceSlider.component';
import ServicesVideo from './ServicesVideo.component';
import CustomScrollbar from '../CustomScrollbar.component';
let css = ``;

export default function MobileApp() {
  const serviceBoxRef = useRef(null);

  // Scroll to the ServiceBox section when the page loads
  useEffect(() => {
    serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <section className="">
      {/* NAVIGATION BAR */}
      <section className="">
        <NavBar />
      </section>

      {/* NAV-CONTACT */}
      <section>{/* <NavContact/> */}</section>

      {/* HERO SLIDER */}
      <section>
        <Slider images={[Banner5]} showDemoButton={true} />
      </section>

      {/* BOOK DEMO */}
      {/* <section>
        <BookDemo
          bgImage={Bg5}
          title={
            <>
              Book a <span className="text-primary">Demo</span>{" "}
            </>
          }
        />
      </section> */}

      {/* SERVICE */}
      <section ref={serviceBoxRef}>
        <style>{css}</style>
        <section>
          <style>{css}</style>
          <div className="py-20 custom-container">
            <h1 className="text-center heading pt-5">SERVICES</h1>
            <p className="mx-auto max-w-[700px] text-center text-sm mt-2 font-['montserrat']">
              Accelerate your progress with all the tools you need in one go. Your
              one-stop-shop, Analytics Valley, offers a wide range of services to help you
              advance throughout time.
            </p>
            <div className="mt-16">
              <ServiceBox
                thumbnail={ServiceBox7}
                position={"left"}
                title={"Mobile Application"}
                content={
                  " A mobile application, commonly known as a mobile app, is a software application designed to run on mobile devices such as smartphones and tablets. Mobile apps offer specialized functionalities and user experiences tailored to the mobile platform. They can range from productivity tools, social media platforms, gaming, e-commerce, and more. Mobile applications enable businesses to reach a wider audience, enhance customer engagement, and provide on-the-go access to services and information."
                }
              />
            </div>

            <section>
              <ServicesVideo
                videoSrc="https://www.youtube.com/embed/x7zr_kCujAs"
                videoTitle="Mobile Application Overview"
              />
            </section>

            <div className='pt-10'>
              <ServiceSlider />
            </div>
          </div>
        </section>
      </section>

      {/* FOOTER */}
      <section>
        <Footer />
      </section>

      <CustomScrollbar />
    </section>
  );
}
