import applyhere1 from "../../assets/apply-here-1.svg";
import ReactDOM from "react-dom";

import CloudUpload from "../../assets/cloud-upload.svg";
import CustomTextField1 from "../CustomTextField2.component";
import CustomFileUpload1 from "../CustomFileUpload1.component";
import CustomButton1 from "../CustomButton1.component";

import configObj from "../../email/config.js";
import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { GrFormAdd } from "react-icons/gr";
import { GrFormSubtract } from "react-icons/gr";

import {
    validateSelectOpetion,
    validateString,
    validateEmail,
    validePhoneNumber,
    validateFile,
    validateCaptcha,
} from "../../validation/validation.js";
import toast from "react-hot-toast";
import { FaCheckCircle } from "react-icons/fa";

function ApplyHere() {
    let [activeTab, setActiveTab] = useState(null);
    let iconContainerRef = [];
    let answerRef = [];
    let current = null;

    let [name, setName] = useState();
    let [phone, setPhone] = useState();
    let [email, setEmail] = useState();
    let [jobCategory, setJobCategory] = useState();
    let [coverLetter, setCoverLetter] = useState();
    let [coverLetterName, setCoverLetterName] = useState();
    let [cv, setCv] = useState();
    let [cvName, setCvName] = useState();

    const recaptchaRef = useRef();

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    async function sendMail() {
        let dateTime = new Date();
        let date = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        let time = `${dateTime.getHours()}:${dateTime.getMinutes()}`;

        let coverLetterBase64 = await toBase64(coverLetter);
        let cvBase64 = await toBase64(cv);

        let body = `
            <h1>Job Application</h1>
            <p>Date: ${date}</p>
            <p>Time: ${time}</p>
            <p>Name: ${name}</p>
            <p>Phone: ${phone}</p>
            <p>Email: ${email}</p>
            <p>Job Category: ${jobCategory}</p>
        `;

        let mailstatus = window.Email.send({
            ...configObj,
            Subject: "Job Appication [form website] ",
            Body: body,
            Attachments: [
                {
                    name: coverLetterName,
                    data: coverLetterBase64,
                },
                {
                    name: cvName,
                    data: cvBase64,
                },
            ],
        });

        toast.promise(
            mailstatus,
            {
                loading: "Sending Email...",
                success: "Email sent successfully, Analytic Valley Team will contact you soon.",
                error: "Failed to send Email",
            },
            {
                duration: 10000,
            }
        );

        mailstatus.then((status) => {
            if (status === "OK") {
                setName("");
                setPhone("");
                setEmail("");
                setJobCategory(-1);
                setCoverLetter("");
                setCoverLetterName("");
                setCv("");
                setCvName("");
            }
        });
    }

    function clickHandlerSubmit() {
        console.log(name);
        console.log(phone);
        console.log(email);
        console.log(jobCategory);
        console.log(coverLetter);
        console.log(cv);

        if (!validateString("Please enter valid Name", name, 3, 50)) return;
        if (!validePhoneNumber("Please enter a valid Phone number", phone)) return;
        if (!validateEmail("Please enter valid Email Address", email)) return;
        if (!validateSelectOpetion("Please Select Job Category", jobCategory)) return;
        if (!validateFile("Please select Cover Letter", coverLetter)) return;
        if (!validateFile("Please select CV", cv)) return;
        if (!validateCaptcha("Please fill the Captcha", recaptchaRef.current.getValue())) return;

        sendMail();
    }

    function Question(props) {
        const [openIndex, setOpenIndex] = useState(null);
        const answerRef = useRef(null);

        const toggleOpen = () => {
            setOpenIndex(prevIndex => (prevIndex === props.index ? null : props.index));
        };

        // Split qualifications into an array and render with icons
        const qualificationsArray = (props.qualifications || "").split('\n').filter(q => q.trim() !== "");

        return (
            <section className="mb-5">
                <div
                    className="flex items-center justify-between pb-2 font-semibold border-b border-black cursor-pointer"
                    onClick={toggleOpen}
                >
                    <div className="text-xl">{props.question}</div>
                    <div className="pl-3">
                        {openIndex === props.index ? <GrFormSubtract /> : <GrFormAdd />}
                    </div>
                </div>

                <div
                    ref={answerRef}
                    style={{
                        maxHeight: openIndex === props.index ? `${answerRef.current.scrollHeight}px` : '0px',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease-in-out',
                    }}
                >
                    <div className="pt-2 font-light text-justify">
                        <h1 className="font-semibold">Role Description</h1>
                        <p>{props.description}</p>
                        <h1 className="font-semibold mt-4">Qualifications</h1>
                        <ul className="list-none">
                            {qualificationsArray.map((qualification, index) => (
                                <li key={index} className="flex items-center mb-2">
                                    <FaCheckCircle className="mr-2 text-green-600 text-lg" /> {/* Set a consistent size */}
                                    <span className="flex-1">{qualification}</span> {/* Ensure text can wrap without affecting icon size */}
                                </li>
                            ))}
                        </ul>
                        <h1 className="font-semibold mt-4">Work Experience</h1>
                        <p>{props.exp}</p>
                        <h1 className="font-semibold mt-4">Job Locations</h1>
                        <p>{props.location}</p>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="grid grid-cols-1 lg:grid-cols-2">
            <div className="overflow-hidden overflow-y-auto bg-quaternary">
                {/* <img src={applyhere1} className="object-cover w-full h-full scale-105 " alt="" /> */}
                {/* BUSINESS DEVELOPMENT EXECUTIVE */}
                <div className="px-10 pt-10 pb-5">
                    <Question index={0}
                        question="Full Stack Web Developer (React / Laravel)"
                        description="This is a full-time hybrid role as a Full Stack Web Developer (React / Laravel) at Analytics Valley Technology Private Limited.
                     "
                        qualifications={`Proficiency in HTML, CSS, JavaScript, and front-end frameworks like React
                        Experience with back-end programming languages like PHP and frameworks like Laravel
                        Strong knowledge of database systems and SQL
                        Experience in using JavaScript, along with modern libraries and tooling such as Redux, Webpack, React Hooks, and Context API
                        Should have proven working experience on React (Functional Comp preferred). Experience on Create React App OR Next JS is a plus. 
                        Should have experience with Typescript, SSR, REST API Consumption
                        Experience with version control systems like Git
                        Understanding of web application security best practices
                        Knowledge of RESTful API design and integration
                        Experience with Agile development methodologies
                        Excellent problem-solving skills and attention to detail
                        Good communication and collaboration skills
                        Bachelor's degree in Computer Science or a related field (or equivalent work experience)`}
                        exp="3 to 5 years"
                        location="Banglore"
                    />

                    <Question index={1}
                        question="Dotnet Developer"

                        description=" This is a full-time hybrid role for a Dotnet Developer. The Dotnet Developer will be responsible for working on software development using Object-Oriented Programming (OOP) principles. The developer will work with .NET Core framework, ASP.NET MVC, and other programming technologies. The role is based in Bengaluru but allows for some remote work. "

                        qualifications={`Strong Object-Oriented Programming (OOP) skills
                        Experience in Software Development
                        Proficient with .NET Core and ASP.NET MVC
                        Excellent programming skills
                        Bachelor's degree in Computer Science or related field
                        Experience with cloud-based technologies such as AWS or Azure is a plus
                        Ability to work independently and in a team-oriented, collaborative environment
                        Good problem-solving skills`}

                        exp="3 to 5 years"
                        location="Banglore"
                    />

                    <Question index={2}
                        question="Business Development Executive"

                        description="This is a full-time hybrid role for a Business Development Executive at Analytics Valley. The role involves tasks such as new business development, lead generation, business communication, and account management. While based in Bengaluru, this role offers flexibility for some remote work."

                        qualifications={`New Business Development and Lead Generation skills
                        Strong business acumen and communication skills
                        Ability to manage accounts effectively
                        Experience in sales or business development
                        Proven track record of meeting or exceeding targets
                        Excellent negotiation and relationship-building skills
                        Bachelor's degree in Business Administration or related field
                        Experience in the technology industry is a plus`}

                        exp="3 to 5 years"
                        location="Banglore"
                    />
                </div>
            </div>
            <div className="py-16 custom-container bg-primary">
                <h1 className="text-center text-white heading">Apply Here</h1>
                <div className="grid grid-cols-2 gap-5 mt-10 text-white gap-y-7">
                    <CustomTextField1
                        label="Your Full Name"
                        placeholder="Enter"
                        state={name}
                        setState={setName}
                    />
                    <CustomTextField1
                        label="Phone Number"
                        placeholder="Enter"
                        state={phone}
                        setState={setPhone}
                    />
                    <CustomTextField1
                        label="Email"
                        placeholder="Enter"
                        state={email}
                        setState={setEmail}
                    />
                    <div>
                        <label htmlFor="" className="flex flex-col text-sm font-medium text-white">
                            <span>Job Category</span>
                            <select
                                className="px-2 py-2 mt-2 text-xs bg-transparent border-b outline-none"
                                value={jobCategory}
                                onChange={(e) => setJobCategory(e.target.value)}
                            >
                                <option className="text-gray-400" value="-1">
                                    Select Position
                                </option>
                                <option className="text-gray-400">Full Time</option>
                                <option className="text-gray-400">Internship</option>
                                <option className="text-gray-400">Contract</option>
                                <option className="text-gray-400">Freelancer</option>
                            </select>
                        </label>
                    </div>
                    <CustomFileUpload1
                        label1="Cover letter"
                        label2={
                            <div className="flex items-center justify-between w-full">
                                <span className="text-gray-500">choose file</span>{" "}
                                <img className="scale-90" src={CloudUpload} />{" "}
                            </div>
                        }
                        className="mt-2 text-black bg-white"
                        state={coverLetter}
                        setState={setCoverLetter}
                        fileName={coverLetterName}
                        setFileName={setCoverLetterName}
                    />
                    <div>
                        <CustomFileUpload1
                            label1="Drop CV"
                            label2={
                                <div className="flex items-center justify-between w-full">
                                    <span className="text-gray-500">choose file</span>{" "}
                                    <img className="scale-90" src={CloudUpload} />{" "}
                                </div>
                            }
                            className="mt-2 text-black bg-white"
                            state={cv}
                            setState={setCv}
                            fileName={cvName}
                            setFileName={setCvName}
                        />
                    </div>
                    <div className="flex justify-center w-full col-span-2 mt-10">
                        <ReCAPTCHA
                            sitekey="6LchLL8mAAAAAC4y_7vYBWdN-U1z0OYHWMmOVKXz"
                            ref={recaptchaRef}
                        />
                    </div>
                    <div className="flex justify-center col-span-2 mt-2">
                        <CustomButton1
                            label="Send Message"
                            className="text-black bg-white"
                            onClick={clickHandlerSubmit}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ApplyHere;
