function CustomPhone1(props) {
    return (
        <div className="flex flex-col">
            <label htmlFor="id" className="[font-weight:500;]">
                {props.label}
            </label>
            <div className="flex gap-2 text-xs">
                <input
                    type={props.type || "text"}
                    id="id"
                    placeholder={props.placeholder1}
                    className={` ${props.className1} w-[60px] outline-none border mt-2 px-2 py-2 rounded bg-transparent`}
                    value={props.state1}
                    onChange={(e) => props.setState1(e.target.value)}
                />
                <input
                    type={props.type || "number"}
                    id="id"
                    placeholder={props.placeholder2}
                    className={` ${props.className2} outline-none border mt-2 px-2 py-2 rounded grow bg-transparent`}
                    value={props.state2}
                    onChange={(e) => props.setState2(e.target.value)}
                />
            </div>
        </div>
    );
}

export default CustomPhone1;
