import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need4 from "../../assets/need-4.svg";
import NeedImg4 from "../../assets/NeedImg4.svg";
import Clientele from '../Home/Clientele.component'
import thumbImg from '../../assets/SolutionsImage/vectorImages/Image Processing/27.jpg'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function ImageProcessing() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={3}
                                    icon={Need4}
                                    title={"Image Processing"}
                                    thumbnail={thumbImg}
                                    content={[
                                        `Image processing software is a powerful tool that can significantly improve businesses' productivity and efficiency. It is designed to enhance, manipulate, and analyze digital images, making them more useful and valuable for businesses. It can be used in various industries, including healthcare, entertainment, marketing, and more. This software helps businesses to manage their images and data more effectively.`,

                                        `One of the most significant benefits of it is the time-saving feature. With this, businesses can automate many repetitive and time-consuming image-processing tasks previously performed manually. This helps free up staff time, enabling them to focus on more important tasks requiring attention. It also eliminates the need for manual image editing, as everything can be processed digitally, which reduces the risk of errors and inaccuracies.`,

                                        `Image processing software (IPS) is a versatile tool that can be used in various industries to improve efficiency, accuracy, and productivity.`,
                                    ]}
                                    image={NeedImg4}
                                    caption={[
                                        "Diagnostic",
                                        "Healthcare",
                                        "Manufacturing",
                                        "Retail",
                                        "Hotel",
                                        "Education",
                                        "Research and development",
                                    ]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>

                {/* SOLUTION VIDEO */}
                <section>
                    <SolutionVideo
                        videoSrc="https://www.youtube.com/embed/4vkY5Tnr8Gc"
                        videoTitle="Image Processing"
                    />
                </section>

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
