import React, { useEffect, useRef, useState } from 'react'
import NavBar from '../NavBar.component';
import Slider from '../Slider.component';
import BookDemo from '../BookDemo.component';
import Footer from '../Footer.component';
// import Banner5 from "../../assets/SolutionsImage/26.jpg"
import Banner5 from "../../assets/Banner5.svg"
import ServiceBox2 from "../../assets/ServiceBox2.svg";
import Bg5 from "../../assets/bg5.svg";
import ServiceBox from '../ServiceBox.component';
import ServiceSlider from './ServiceSlider.component';
import ServicesVideo from './ServicesVideo.component';
import CustomScrollbar from '../CustomScrollbar.component';
let css = ``;

export default function AdvanceAnalytics() {
  const serviceBoxRef = useRef(null);

  // Scroll to the ServiceBox section when the page loads
  useEffect(() => {
    serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <section className="">
      {/* NAVIGATION BAR */}
      <section className="">
        <NavBar />
      </section>

      {/* NAV-CONTACT */}
      <section>{/* <NavContact/> */}</section>

      {/* HERO SLIDER */}
      <section>
        <Slider images={[Banner5]} showDemoButton={true} />
      </section>

      {/* BOOK DEMO */}
      {/* <section>
        <BookDemo
          bgImage={Bg5}
          title={
            <>
              Book a <span className="text-primary">Demo</span>{" "}
            </>
          }
        />
      </section> */}

      {/* SERVICE */}
      <section ref={serviceBoxRef}>
        <style>{css}</style>
        <section>
          <style>{css}</style>
          <div className="py-20 custom-container">
            <h1 className="text-center heading pt-5">SERVICES</h1>
            <p className="mx-auto max-w-[700px] text-center text-sm mt-2 font-['montserrat']">
              Accelerate your progress with all the tools you need in one go. Your
              one-stop-shop, Analytics Valley, offers a wide range of services to help you
              advance throughout time.
            </p>
            <div className="mt-16">
              <ServiceBox
                thumbnail={ServiceBox2}
                position={"right"}
                title={"Advanced Analytics"}
                content={
                  "We provide sophisticated techniques and algorithms to extract valuable insights from large and complex datasets. It encompasses various statistical modeling, data mining, machine learning, and predictive analytics techniques. By leveraging advanced analytics, businesses can uncover hidden patterns, trends, and correlations in their data, enabling them to make informed decisions, optimize processes, mitigate risks, and gain a competitive edge in their industry."
                }
              />
            </div>

            <section>
              <ServicesVideo
                videoSrc="https://www.youtube.com/embed/xfnT2mLaNuQ"
                videoTitle="Advanced Analytics Overview"
              />
            </section>

            <div className='mt-10'>
              <ServiceSlider />
            </div>
          </div>
        </section>
      </section>

      {/* FOOTER */}
      <section>
        <Footer />
      </section>

      <CustomScrollbar />
    </section>
  );
}
