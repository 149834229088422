import { useRef, useState } from "react";
import configObj from "./email/config.js";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import Bg3 from "./assets/bg3.svg";
import CustomTextField1 from "./components/CustomTextField1.component";
import CustomRenge1 from "./components/CustomRange1.component";
import CustomSelect1 from "./components/CustomSelect1.component";
import CustomPhone1 from "./components/CustomPhone1.component";
import CustomButton1 from "./components/CustomButton1.component";
import {
    validateSelectOpetion,
    validateString,
    validateEmail,
    validateCountryCode,
    validePhoneNumber,
    validateNumber,
    validateCaptcha,
} from "./validation/validation.js";
import { ValidatePhoneNumber } from "./components/ValidatePhoneNumber.component.jsx";
function Quote() {
    let navigator = useNavigate();
    const recaptchaRef = useRef();

    // Form Data
    let [industry, setIndustry] = useState();
    let [time1, setTime1] = useState();
    let [time2, setTime2] = useState();
    let [budget, setBudget] = useState();
    let [name, setName] = useState();
    let [country, setCountry] = useState();
    let [state, setState] = useState();
    let [email, setEmail] = useState();
    let [phone1, setPhone1] = useState();
    let [phone2, setPhone2] = useState();
    let [remarks, setRemarks] = useState();

    async function sendMail() {
        let dateTime = new Date();
        let date = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        let time = `${dateTime.getHours()}:${dateTime.getMinutes()}`;

        let body = `
            <h1>Request for quote</h1>
            <p>Date: ${date}</p>
            <p>Time: ${time}</p>
            <p>Industry: ${industry}</p>
            <p>Months: ${time1} to ${time2}</p>
            <p>Budget: ${budget}</p>
            <p>Name: ${name}</p>
            <p>Country: ${country}</p>  
            <p>State: ${state}</p>
            <p>Email: ${email}</p>
            <p>Phone: ${phone1} ${phone2}</p>
            <p>Remarks: ${remarks || ""}</p>
        `;

        let mailstatus = window.Email.send({
            ...configObj,
            Subject: "Request for quote [form website] ",
            Body: body,
        });

        toast.promise(
            mailstatus,
            {
                loading: "Sending Email...",
                success: "Email sent successfully, Analytic Valley Team will contact you soon.",
                error: "Failed to send Email",
            },
            {
                duration: 10000,
            }
        );

        mailstatus.then((status) => {
            if (status === "OK") {
                setTimeout(() => {
                    navigator("/");
                }, 0);
            }
        });
    }

    function clickHandlerSubmit() {
        if (!validateSelectOpetion("Please Select Industry", industry)) return;
        if (!validateNumber("Please enter valid Budget", budget, 1000, 10000000)) return;
        if (!validateString("Please enter valid Name", name, 3, 50)) return;
        if (!validateString("Please enter valid Country", country, 3, 20)) return;
        if (!validateString("Please enter valid State", state, 3, 20)) return;
        if (!validateEmail("Please enter valid Email Address", email)) return;
        // if (!validateCountryCode("Please enter a valid Country code", phone1)) return;
        // if (!validePhoneNumber("Please enter a valid Phone number", phone2)) return;
        if (!ValidatePhoneNumber(phone1, phone2)) return;  // Updated validation here
        if (!validateString("Remarks can not container more than 1000 character", remarks, 0, 1000))
            return;
        if (!validateCaptcha("Please fill the Captcha", recaptchaRef.current.getValue())) return;

        sendMail();
    }

    return (
        <>
            <section className="flex w-full h-screen">
                {/* LEFT BLUE BOX */}
                <div className="w-[250px] rounded-tr-3xl bg-primary overflow-hidden md:flex items-end hidden ">
                    <img src={Bg3} className="scale-[3] rotate-[-10deg]" alt="" />
                </div>

                {/* RIGHT */}
                <div className="h-screen overflow-y-auto grow text-tertiary">
                    <div className="flex flex-col justify-center min-h-full p-7">
                        <h1 className="text-3xl font-semibold">Request for quote</h1>
                        <p className="mt-2 text-xs">
                            Fill in the below form !!! It becomes amazing when we get together.
                        </p>
                        <div className="grid grid-cols-1 mt-10 lg:grid-cols-2 gap-y-7 gap-x-10 xl:grid-cols-3">
                            <div>
                                <CustomSelect1
                                    label="Industry"
                                    placeholder="Select Industry"
                                    state={industry}
                                    setState={setIndustry}
                                >
                                    <option className="text-gray-400">E-LEARNING</option>
                                    <option className="text-gray-400">AUTOMOTIVE</option>
                                    <option className="text-gray-400">DIAGONISTIC</option>
                                    <option className="text-gray-400">ENTERTAINMENT</option>
                                    <option className="text-gray-400">EDUCATION</option>
                                    <option className="text-gray-400">FASHION & LIFESTYLE</option>
                                    <option className="text-gray-400">HEALTHCARE</option>
                                    <option className="text-gray-400">RETAIL</option>
                                    <option className="text-gray-400">TOURISM</option>
                                </CustomSelect1>
                            </div>
                            <div>
                                <CustomRenge1
                                    label={`How Much Time (Months : ${time1} to ${time2}) ?`}
                                    state1={time1}
                                    setState1={setTime1}
                                    state2={time2}
                                    setState2={setTime2}
                                />
                            </div>
                            <div>
                                <CustomTextField1
                                    type="number"
                                    label="Set Your Budget"
                                    placeholder="Enter Budget (min 1000)"
                                    state={budget}
                                    setState={setBudget}
                                />
                            </div>
                            <div>
                                <CustomTextField1
                                    label="Name"
                                    placeholder="First  Middle  Last"
                                    state={name}
                                    setState={setName}
                                    className="capitalize"
                                />
                            </div>
                            <div>
                                <CustomTextField1
                                    label="Country"
                                    placeholder="Country"
                                    state={country}
                                    setState={setCountry}
                                    className="capitalize"
                                />
                            </div>
                            <div>
                                <CustomTextField1
                                    label="State"
                                    placeholder="State"
                                    state={state}
                                    setState={setState}
                                    className="capitalize"
                                />
                            </div>
                            <div>
                                <CustomTextField1
                                    type="email"
                                    label="Email"
                                    placeholder="xyz@gmail.com"
                                    state={email}
                                    setState={setEmail}
                                />
                            </div>

                            <div>
                                <div className="flex flex-col">
                                    <label htmlFor="id" className="[font-weight:500;]">
                                        Phone Number
                                    </label>
                                    <div className="flex gap-2 text-xs">
                                        <input
                                            type={"text"}
                                            id="id"
                                            placeholder={"Eg. +91"}
                                            className={` w-[60px] outline-none border mt-2 px-2 py-2 rounded bg-transparent`}
                                            value={phone1}
                                            onChange={(e) => setPhone1(e.target.value)}
                                        />
                                        <input
                                            type={"number"}
                                            id="id"
                                            placeholder={"Enter Number"}
                                            className={`outline-none border mt-2 px-2 py-2 rounded grow bg-transparent`}
                                            value={phone2}
                                            onChange={(e) => setPhone2(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <CustomTextField1
                                    label="Remarks"
                                    placeholder="Remarks"
                                    state={remarks}
                                    setState={setRemarks}
                                />
                            </div>
                        </div>
                        <div className="flex justify-center mt-10">
                            <ReCAPTCHA
                                sitekey="6LchLL8mAAAAAC4y_7vYBWdN-U1z0OYHWMmOVKXz"
                                ref={recaptchaRef}
                            />
                        </div>
                        <div className="mt-10">
                            <div className="flex justify-center gap-10">
                                <CustomButton1
                                    label="Submit"
                                    className="bg-primary"
                                    onClick={clickHandlerSubmit}
                                />
                                <CustomButton1
                                    variant="outlined"
                                    label="Cancel"
                                    className="border-primary text-primary"
                                    onClick={() => navigator("/")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Quote;
