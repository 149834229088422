import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need8 from "../../assets/need-8.svg";
import NeedImg8 from "../../assets/NeedImg8.svg";
import Clientele from '../Home/Clientele.component'
import thumbImg from '../../assets/SolutionsImage/vectorImages/customer-satisfaction-online-survey-form.jpg'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function OnlineSurveyTool() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={7}
                                    icon={Need8}
                                    thumbnail={thumbImg}
                                    title={"Online Survey Tool"}
                                    content={[
                                        "Our online feedback survey tool offers customers a unique and engaging way to provide feedback. With options to record feedback using emojis, images, thumbs up, and even audio, customers can express themselves in a natural and comfortable way. Our software is also equipped with live reports, allowing businesses to receive real-time feedback and improve quickly. This tool is not just limited to schooled customers, as its user-friendly interface makes it easy for anyone to provide feedback. Our feedback survey tool is a powerful and versatile tool that can help businesses improve customer satisfaction and overall success.",
                                    ]}
                                    image={NeedImg8}
                                    caption={[
                                        "Healthcare",
                                        "Finance",
                                        "Accounting",
                                        "Human Resource",
                                        "Retail and E-commerce",
                                        "Food Industry",
                                    ]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>

                {/* SOLUTION VIDEO */}
                <section>
                    <SolutionVideo
                        videoSrc="https://www.youtube.com/embed/iaT_3AHOgK8"
                        videoTitle="Online Survey Tool"
                    />
                </section>

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
