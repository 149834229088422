import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need2 from "../../assets/need-2.svg";
import NeedImg2 from "../../assets/NeedImg2.svg";
import thumbImg from '../../assets/SolutionsImage/vectorImages/customer-relationship-management-concept (1).jpg'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function BasicCrmService() {
    let scrollRef = useRef();

    return (
        <>
            <section className="">
                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section >
                    <section>
                        <style>{css}</style>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={1}
                                    icon={Need2}
                                    thumbnail={thumbImg}
                                    title={"Basic Sswayam CRM (Service)"}
                                    content={[
                                        "Sswayam CRM for Service is a comprehensive CRM software designed to streamline customer service tasks and improve customer satisfaction. With this software, businesses can manage customer queries, client tickets, and SLA management with ease. It also comes with powerful admin management features to help businesses better manage their workflow. Sswayam CRM for Service provides real-time data and insights, empowering businesses to make data-driven decisions and improve their bottom line. The software is easy to use and comes with customizable dashboards, automated reports, and integrations with popular business tools.",
                                    ]}
                                    image={NeedImg2}
                                    caption={[
                                        "IT infrastructure support, Networking and Security.",
                                    ]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>
            </section>
        </>
    )
}
