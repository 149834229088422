import React, { useState } from 'react'
import CustomButton1 from './CustomButton1.component';

export default function ServiceBox(props) {
    let [isOpened, setIsOpened] = useState(false);
    return (
        <section
            className={`flex ${props.position === "left" ? "" : "flex-row-reverse"
                } gap-5 my-10`}
        >
            <div>
                <img src={props.thumbnail} className="w-[30vw] max-w-[320px]" alt="" />
            </div>
            <div>
                <h1 className="text-2xl font-semibold text-primary font-['lato']">
                    {props.title}
                </h1>
                <p
                    className={`mt-3  ${isOpened ? "h-auto" : " h-[130px] max-h-[150px] "
                        } overflow-hidden text-xs md:text-xl lg:text-2xl text-justify`}
                >
                    {props.content}
                </p>
                <div
                    className={`mt-5 flex ${props.position === "left" ? " justify-end " : ""} `}
                >
                    <CustomButton1
                        label={isOpened ? "View Less" : "View More"}
                        className="bg-primary"
                        onClick={() => setIsOpened(!isOpened)}
                    />
                </div>
            </div>
        </section>
    );
}
