import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need13 from "../../assets/need-13.svg";
import NeedImg13 from "../../assets/NeedImg13.svg";
import Clientele from '../Home/Clientele.component'
import thumbImg from '../../assets/SolutionsImage/vectorImages/city-committed-education-collage-concept.jpg'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function SchoolManagement() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={12}
                                    icon={Need13}
                                    title={"School Management"}
                                    thumbnail={thumbImg}
                                    content={[
                                        `School management software is a powerful tool that can significantly improve the management of educational institutions. It is designed to streamline and automate various school management tasks, including admissions, attendance tracking, grade reporting, scheduling, and communication. This software helps school administrators manage the institution's administrative and academic functions more effectively.`,

                                        `One of the most significant benefits of this software is the time-saving feature. With this software, schools can automate many repetitive and time-consuming tasks previously performed manually. This helps free up staff time, enabling them to focus on more important tasks requiring attention. It also eliminates the need for paperwork, as everything can be stored digitally, which reduces the risk of data loss and damage.`,

                                        `Another important benefit of school management software is the enhanced communication it offers. It allows parents, students, and teachers to communicate in real-time. It offers a platform for parents to stay updated on their children's academic progress, attendance records, and other important information about the school. SMS also enables teachers to share feedback with parents on their child's performance, allowing them to work together to improve academic outcomes`,
                                    ]}
                                    image={NeedImg13}
                                    caption={["Education"]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>

                {/* SOLUTION VIDEO */}
                <section>
                    {/* <SolutionVideo
                        videoSrc="https://www.youtube.com/embed/cwzCvgsGnLY"
                        videoTitle="School Management"
                    /> */}
                </section>

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
