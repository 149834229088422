import NavBar from "./components/NavBar.component";
import Slider from "./components/Slider.component";
import BookDemo from "./components/BookDemo.component";
import BusinessNeeds from "./components/Solution/BusinessNeeds.components";
import SolutionVideo from "./components/Solution/SolutionVideo.component";
import WhatYouGet from "./components/Solution/WhatYouGet.component";
import Faqs from "./components/Solution/Faqs.components";
import Footer from "./components/Footer.component";

import Banner4 from "./assets/Banner4.svg";

import Bg4 from "./assets/bg4.svg";
import CustomScrollbar from "./components/CustomScrollbar.component";

function Home() {
    return (
        <section className="">
            {/* NAVIGATION BAR */}
            <section className="">
                <NavBar />
            </section>

            {/* NAV-CONTACT */}
            <section>{/* <NavContact/> */}</section>

            {/* HERO SLIDER */}
            <section>
                <Slider images={[Banner4]} />
            </section>

            {/* BOOK DEMO */}
            <section>
                <BookDemo
                    bgImage={Bg4}
                    title={
                        <>
                            Book a <span className="text-primary">Demo</span>{" "}
                        </>
                    }
                />
            </section>

            {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
            <section>
                <BusinessNeeds />
            </section>

            {/* SOLUTION VIDEO */}
            <section>
                <SolutionVideo />
            </section>

            {/* WHAT YOU GET */}
            <section>
                <WhatYouGet />
            </section>

            {/* FAQS */}
            <section>
                <Faqs />
            </section>

            {/* FOOTER */}
            <section>
                <Footer />
            </section>

            <CustomScrollbar />
        </section>
    );
}

export default Home;
