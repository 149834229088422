import toast from "react-hot-toast";

function validateSelectOpetion(message, value) {
    if (value == -1) {
        toast.error(message || "Please select an option");
        return false;
    }
    return true;
}

function validateString(message, value = "", minLength, maxLength) {
    if (value.length < minLength || value.length > maxLength) {
        toast.error(message || "Please enter a valid value");
        return false;
    }
    return true;
}

function validateNumber(message, value, min, max) {
    value = value || 0;
    console.log(value, min, max);
    if (value < min || value > max) {
        toast.error(message || "Please enter a valid number");
        return false;
    }
    return true;
}

function validateEmail(message, value = "") {
    if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        toast.error(message || "Please enter a valid Email");
        return false;
    }
    return true;
}

function validateCountryCode(message, value) {
    if (!`${value}`.match(/^\+\d{1,3}$/)) {
        toast.error(message || "Please enter a valid country code");
        return false;
    }
    return true;
}

function validePhoneNumber(message, value = "") {
    if (!(`${value}`.length === 10)) {
        toast.error(message || "Please enter a valid phone number");
        return false;
    }
    return true;
}

function validateCaptcha(message, value) {
    if (!value) {
        toast.error(message || "Please fill the captcha");
        return false;
    }
    return true;
}

function validateFile(message, file) {
    if (!file) {
        toast.error(message || "Please select File");
        return false;
    }
    return true;
}

export {
    validateSelectOpetion,
    validateString,
    validateEmail,
    validateCountryCode,
    validePhoneNumber,
    validateNumber,
    validateCaptcha,
    validateFile,
};
