import JoiningProcess2 from "../../assets/joining-process-2.svg";
import JoiningProcess3 from "../../assets/joining-process-3.svg";

function JoiningProcess() {
    return (
        <section>
            <div className="py-16 bg-[#bbdfe9]">
                <h1 className="text-center heading">Our joining process</h1>
                <p className="mt-2 text-sm text-center font-['montserrat']">
                    Streamlined steps from application to selection for a
                    hassle-free onboarding
                </p>
                <div className="flex justify-center px-10 mt-14">
                    {/* Desktop Image */}
                    <img
                        src={JoiningProcess3}
                        className="hidden md:block max-w-[1200px] container"
                        alt=""
                    />

                    {/* Mobile Device Image */}
                    <img
                        src={JoiningProcess2}
                        className="md:hidden max-w-[550px]"
                        alt=""
                    />
                </div>
            </div>
        </section>
    );
}

export default JoiningProcess;
