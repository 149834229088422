import { useEffect, useRef } from "react";

import career1 from "../../assets/career1.svg";
import career2 from "../../assets/career2.svg";
import career3 from "../../assets/career3.svg";
import career4 from "../../assets/career4.svg";
import career5 from "../../assets/career5.svg";

function Backgrounds(){

    let backgroundRef = useRef(null);

    useEffect(function(){
        backgroundRef.current.addEventListener("mousemove", function(e){
            backgroundRef.current.scrollLeft += e.movementX*(0.5);
        }) 
    },[])

    function BackgroundImage(props){
        return(
            <div className="min-w-[250px] max-w-[250px] w-[250px] max-h-[211px] min-h-[211px] h-[211px] md:min-w-[350px] md:max-w-[350px] md:w-[350px] md:max-h-[296px] md:min-h-[296px] md:h-[296px] overflow-hidden" >
                <img className="w-[350px] h-[296px] object-cover" src={props.image} alt="" />
            </div>
        )   
    }

    return(
        <section className="flex max-w-full overflow-auto hide-scrollbar" ref={backgroundRef} >
            <BackgroundImage image={career1} />
            <BackgroundImage image={career2} />
            <BackgroundImage image={career3} />
            <BackgroundImage image={career4} />
            <BackgroundImage image={career5} />
        </section>
    )
}

export default Backgrounds;