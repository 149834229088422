import React, { useEffect, useRef, useState } from 'react'
import NavBar from '../NavBar.component';
import Slider from '../Slider.component';
import BookDemo from '../BookDemo.component';
import Footer from '../Footer.component';
// import Banner5 from "../../assets/SolutionsImage/27.jpg"
import Banner5 from "../../assets/Banner5.svg"
import ServiceBox5 from "../../assets/ServiceBox5.svg";
import Bg5 from "../../assets/bg5.svg";
import ServiceBox from '../ServiceBox.component';
import ServiceSlider from './ServiceSlider.component';
import ServicesVideo from './ServicesVideo.component';
import CustomScrollbar from '../CustomScrollbar.component';
let css = ``;

export default function DataScience() {
  const serviceBoxRef = useRef(null);

  // Scroll to the ServiceBox section when the page loads
  useEffect(() => {
    serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <section className="">
      {/* NAVIGATION BAR */}
      <section className="">
        <NavBar />
      </section>

      {/* NAV-CONTACT */}
      <section>{/* <NavContact/> */}</section>

      {/* HERO SLIDER */}
      <section>
        <Slider images={[Banner5]} showDemoButton={true} />
      </section>

      {/* BOOK DEMO */}
      {/* <section>
        <BookDemo
          bgImage={Bg5}
          title={
            <>
              Book a <span className="text-primary">Demo</span>{" "}
            </>
          }
        />
      </section> */}

      {/* SERVICE */}
      <section ref={serviceBoxRef}>
        <style>{css}</style>
        <section>
          <style>{css}</style>
          <div className="py-20 custom-container">
            <h1 className="text-center heading pt-5">SERVICES</h1>
            <p className="mx-auto max-w-[700px] text-center text-sm mt-2 font-['montserrat']">
              Accelerate your progress with all the tools you need in one go. Your
              one-stop-shop, Analytics Valley, offers a wide range of services to help you
              advance throughout time.
            </p>
            <div className="mt-16">
              <ServiceBox
                thumbnail={ServiceBox5}
                position={"left"}
                title={"Data Science"}
                content={
                  "Data science is an interdisciplinary field that combines statistical analysis, machine learning, and domain expertise to extract insights and knowledge from data. Data scientists employ techniques such as data mining, predictive modeling, and data visualization to discover patterns, solve complex problems, and make data-driven decisions. Data science plays a crucial role in areas like business intelligence, risk assessment, customer behavior analysis, and optimizing processes."
                }
              />
            </div>

            <section>
              <ServicesVideo
                videoSrc="https://www.youtube.com/embed/WyJ-cBPiGxU"
                videoTitle="Data Science Overview"
              />
            </section>

            <div className='pt-10'>
              <ServiceSlider />
            </div>
          </div>
        </section>
      </section>

      {/* FOOTER */}
      <section>
        <Footer />
      </section>

      <CustomScrollbar />
    </section>
  );
}
