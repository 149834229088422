import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need11 from "../../assets/need-11.svg";
import NeedImg11 from "../../assets/NeedImg11.svg";
import Clientele from '../Home/Clientele.component'
import thumbImg from '../../assets/SolutionsImage/vectorImages/customer-relationship-management-concept.jpg'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function StandardSswayam() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0  max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className=" p-5 max-h-[770px]  overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={10}
                                    icon={Need11}
                                    thumbnail={thumbImg}
                                    title={"Standard Sswayam (CRM)"}
                                    content={[
                                        "Standard Sswayam CRM - the ultimate CRM software solution for businesses looking to streamline their sales and service operations. With the sales module, the managers can track field agents , their work, including expenses, daily sales reports, locations, leads collected, and more. Meanwhile, the service module allows businesses to manage customer queries, client tickets, SLA management, and admin management. Standard Sswayam CRM provides real-time data and insights, empowering businesses to make data-driven decisions and improve their bottom line. It's easy to use, customizable, and comes with automated reports and integrations with popular business tools.",
                                    ]}
                                    image={NeedImg11}
                                    caption={[
                                        "IT infrastructure support, Networking and Security.",
                                    ]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>

                {/* SOLUTION VIDEO */}
                {/* <section>
                    <SolutionVideo />
                </section> */}

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
