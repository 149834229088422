import { useEffect, useRef } from "react";

import Need1 from "../../assets/need-1.svg";
import Need2 from "../../assets/need-2.svg";
import Need3 from "../../assets/need-3.svg";
import Need4 from "../../assets/need-4.svg";
import Need5 from "../../assets/need-5.svg";
import Need6 from "../../assets/need-6.svg";
import Need7 from "../../assets/need-7.svg";
import Need8 from "../../assets/need-8.svg";
import Need9 from "../../assets/need-9.svg";
import Need10 from "../../assets/need-10.svg";
import Need11 from "../../assets/need-11.svg";
import Need12 from "../../assets/need-12.svg";
import Need13 from "../../assets/need-13.svg";
import Need14 from "../../assets/need-14.svg";

import NeedImg1 from "../../assets/NeedImg1.svg";
import NeedImg2 from "../../assets/NeedImg2.svg";
import NeedImg3 from "../../assets/NeedImg3.svg";
import NeedImg4 from "../../assets/NeedImg4.svg";
import NeedImg5 from "../../assets/NeedImg5.svg";
import NeedImg7 from "../../assets/NeedImg7.svg";
import NeedImg8 from "../../assets/NeedImg8.svg";
import NeedImg9 from "../../assets/NeedImg9.svg";
import NeedImg11 from "../../assets/NeedImg11.svg";
import NeedImg12 from "../../assets/NeedImg12.svg";
import NeedImg13 from "../../assets/NeedImg13.svg";
import NeedImg14 from "../../assets/NeedImg14.svg";
import NeedImg15 from "../../assets/NeedImg15.svg";
import NeedImg16 from "../../assets/NeedImg16.svg";

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

function BusinessNeeds() {
    let tabRef = [];
    let sectionRef = [];
    let scrollRef = useRef();
    let currentTab = null;

    useEffect(() => {
        tabRef[0].current.classList.add("active-tab");
        currentTab = 0;
    }, []);

    function Tab(props) {
        tabRef[props.index] = useRef();

        function clickHandler() {
            if (currentTab !== null) {
                tabRef[currentTab].current.classList.remove("active-tab");
            }
            currentTab = props.index;
            tabRef[props.index].current.classList.add("active-tab");
            scrollRef.current.scrollBy(
                0,
                sectionRef[props.index].current.getBoundingClientRect().y -
                    scrollRef.current.getBoundingClientRect().y -
                    10
            );
        }
        return (
            <div
                className="flex items-center gap-2 pl-5 py-2 mb-2 [clip-path:polygon(85%_0,100%_50%,85%_100%,0_100%,0_0)] lg:[clip-path:polygon(95%_0,100%_50%,95%_100%,0_100%,0_0)]"
                onClick={clickHandler}
                ref={tabRef[props.index]}
            >
                <img src={props.icon} alt="" className="w-[30px]" />
                <p className="hidden lg:block">{props.label}</p>
            </div>
        );
    }

    function TabContent(props) {
        sectionRef[props.index] = useRef();
        return (
            <div ref={sectionRef[props.index]} className="mb-10">
                <div className="flex gap-2 text-xl">
                    <img src={props.icon} className="invert" alt="" />
                    <h1 className="font-['lato'] text-2xl">{props.title}</h1>
                </div>
                <div className="mt-5 text-xs lg:text-sm">
                    {props.content.map((element, index) => {
                        return (
                            <p className="mt-5 font-['montserrat'] font-[300] text-justify">
                                {element}
                            </p>
                        );
                    })}
                </div>
                <div className="flex flex-col items-center justify-center mt-10">
                    <img src={props.image} alt="" />
                </div>
            </div>
        );
    }

    return (
        <section>
            <style>{css}</style>
            <div className="py-16 custom-container">
                <h1 className="text-center heading">
                    Solution for your unique business needs
                </h1>
                <p className="mt-3 text-sm text-center font-['montserrat']">
                    Explore our comprehensive range of solutions designed to
                    solve your specific challenges.
                </p>
                <div className="flex mt-16 border">
                    <div className="min-w-[100px] w-[100px] lg:w-[350px] lg:min-w-[350px] p-2">
                        <Tab
                            index={0}
                            icon={Need1}
                            label="Basic Sswayam CRM(Sales)"
                        />
                        <Tab
                            index={1}
                            icon={Need2}
                            label="Basic Sswayam CRM (Service)"
                        />
                        <Tab
                            index={2}
                            icon={Need3}
                            label="Hospital Management"
                        />
                        <Tab index={3} icon={Need4} label="Image Processing" />
                        <Tab index={4} icon={Need5} label="Machine learning" />
                        <Tab
                            index={5}
                            icon={Need7}
                            label="Offline Tax Management "
                        />
                        <Tab
                            index={6}
                            icon={Need6}
                            label="Online Tax Management "
                        />

                        <Tab
                            index={7}
                            icon={Need8}
                            label="On Line Exam Module"
                        />
                        <Tab
                            index={8}
                            icon={Need9}
                            label="Online Survey Tool"
                        />
                        <Tab
                            index={9}
                            icon={Need10}
                            label="Robotic UV Sanitizer "
                        />
                        <Tab
                            index={10}
                            icon={Need11}
                            label="Standard Sswayam (CRM)"
                        />
                        <Tab
                            index={11}
                            icon={Need12}
                            label="Sswayam CRM Pro+"
                        />
                        <Tab
                            index={12}
                            icon={Need13}
                            label="School Management"
                        />
                        <Tab index={13} icon={Need14} label="Verify Me" />
                    </div>
                    <div
                        className="bg-tertiary text-white p-5 max-h-[770px] overflow-y-auto scroll-smooth"
                        ref={scrollRef}
                    >
                        <TabContent
                            index={0}
                            icon={Need1}
                            title={"Basic Sswayam CRM (Sales)"}
                            content={[
                                "Sswayam CRM for Sales is a top-of-the-line CRM software designed specifically for sales teams. With this software, businesses can streamline their sales operations and keep everything unified in one platform. Managers can track the work of field agents, including expenses, daily sales reports, locations, leads collected, and more. The software provides real-time data and insights, empowering businesses to make data-driven decisions and improve their bottom line. Sswayam CRM is easy to use and has powerful features, including customizable dashboards, automated reports, and integrations with popular business tools.",
                            ]}
                            image={NeedImg1}
                            caption={[
                                "IT infrastructure support, Networking and Security.",
                            ]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={1}
                            icon={Need2}
                            title={"Basic Sswayam CRM (Service)"}
                            content={[
                                "Sswayam CRM for Service is a comprehensive CRM software designed to streamline customer service tasks and improve customer satisfaction. With this software, businesses can manage customer queries, client tickets, and SLA management with ease. It also comes with powerful admin management features to help businesses better manage their workflow. Sswayam CRM for Service provides real-time data and insights, empowering businesses to make data-driven decisions and improve their bottom line. The software is easy to use and comes with customizable dashboards, automated reports, and integrations with popular business tools.",
                            ]}
                            image={NeedImg2}
                            caption={[
                                "IT infrastructure support, Networking and Security.",
                            ]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={2}
                            icon={Need3}
                            title={"Hospital Management"}
                            content={[
                                `Hospital Management Software (HMS) is designed to streamline and automate various administrative and clinical tasks, including patient records, appointment scheduling, billing, inventory management, etc. This software helps administrators manage the hospital's functions more effectively and efficiently.`,

                                `One of the most significant benefits of HMS is the time-saving feature. With HMS, hospitals can automate many of the repetitive and time-consuming tasks that were previously performed manually. This helps free up staff time, enabling them to focus on more important tasks requiring attention. HMS also eliminates the need for paperwork, as everything can be stored digitally, which reduces the risk of data loss and damage.`,

                                `Another important benefit of HMS is the enhanced communication it offers. HMS allows doctors, nurses, patients, and administrative staff to communicate in real time. It offers a platform for patients to access their medical records, schedule appointments, and communicate with healthcare providers. HMS also enables healthcare providers to share feedback with patients, allowing them to work together to improve.`,
                            ]}
                            image={NeedImg3}
                            caption={["Hospitals"]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={3}
                            icon={Need4}
                            title={"Image Processing"}
                            content={[
                                `Image processing software is a powerful tool that can significantly improve businesses' productivity and efficiency. It is designed to enhance, manipulate, and analyze digital images, making them more useful and valuable for businesses. It can be used in various industries, including healthcare, entertainment, marketing, and more. This software helps businesses to manage their images and data more effectively.`,

                                `One of the most significant benefits of it is the time-saving feature. With this, businesses can automate many repetitive and time-consuming image-processing tasks previously performed manually. This helps free up staff time, enabling them to focus on more important tasks requiring attention. It also eliminates the need for manual image editing, as everything can be processed digitally, which reduces the risk of errors and inaccuracies.`,

                                `Image processing software (IPS) is a versatile tool that can be used in various industries to improve efficiency, accuracy, and productivity.`,
                            ]}
                            image={NeedImg4}
                            caption={[
                                "Diagnostic",
                                "Healthcare",
                                "Manufacturing",
                                "Retail",
                                "Hotel",
                                "Education",
                                "Research and development",
                            ]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={4}
                            icon={Need5}
                            title={
                                "Machine Learning and Artificial Intelligence"
                            }
                            content={[
                                "With Machine Learning (ML) and Artificial Intelligence (AI) we bring the power to revolutionize the way businesses operate in the modern world. These cutting-edge technologies are making it possible to analyze vast amounts of data and provide intelligent insights and predictions that can help drive business decisions. ML and AI algorithms are used in diverse industries, from healthcare and finance to retail and manufacturing, and can help automate mundane tasks, increase productivity, and reduce costs. With advancements in computing power and data storage capabilities, ML and AI are becoming more accessible to businesses of all sizes, unlocking new possibilities for growth and innovation.",
                            ]}
                            image={NeedImg5}
                            caption={[
                                "Healthcare",
                                "Finance",
                                "Accounting",
                                "Human Resource",
                                "Retail and E-commerce",
                                "Food Industry",
                            ]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={5}
                            icon={Need6}
                            title={"Offline Tax Management "}
                            content={[
                                `
                                Our Online and offline tax management software is designed to help individuals and businesses manage their taxes and finance efficiently. While both types of software serve the same purpose, they differ in how they operate and the benefits they offer.
                                `,
                                `
                                Our Offline tax management software offers benefits such as offline access, increased security, and the ability to work on large files without internet limitations. Here we help legacy and B2B businesses record and manage all their databases securely.and more efficiently.
                                `,
                            ]}
                            image={NeedImg15}
                            caption={["CA Industry"]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={6}
                            icon={Need7}
                            title={"Online Tax Management"}
                            content={[
                                `Our Online and offline tax management software is designed to help individuals and businesses manage their taxes and finance efficiently. While both types of software serve the same purpose, they differ in how they operate and the benefits they offer.`,
                                `
                                Online tax management software is cloud-based and accessible through the Internet. This software allows users to access their tax information from any device with an internet connection. Online software offers website and dashboard support to manage one-time services, manage recurring services admin, manage users, manage addresses, and online registered users; additionally, online software offers features such as e-filing management faster and more efficiently. With our online interface, you will get pan India access and an instant payment feature.`,
                            ]}
                            image={NeedImg7}
                            caption={["CA Industry"]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={7}
                            icon={Need8}
                            title={"Online Survey Tool"}
                            content={[
                                "Our online feedback survey tool offers customers a unique and engaging way to provide feedback. With options to record feedback using emojis, images, thumbs up, and even audio, customers can express themselves in a natural and comfortable way. Our software is also equipped with live reports, allowing businesses to receive real-time feedback and improve quickly. This tool is not just limited to schooled customers, as its user-friendly interface makes it easy for anyone to provide feedback. Our feedback survey tool is a powerful and versatile tool that can help businesses improve customer satisfaction and overall success.",
                            ]}
                            image={NeedImg8}
                            caption={[
                                "Healthcare",
                                "Finance",
                                "Accounting",
                                "Human Resource",
                                "Retail and E-commerce",
                                "Food Industry",
                            ]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={8}
                            icon={Need9}
                            title={"Online Exam Module"}
                            content={[
                                `The Online Exam Management Software is an innovative solution that enables students to take exams from anywhere, anytime, and on any device. It provides a secure and efficient platform that allows teachers to manage and conduct exams, reducing the time and effort required to create and grade assessments manually.`,

                                `The software offers a range of question formats, including multiple-choice, single-choice, and descriptive questions. It provides instant answers for MCQs, and answer validation for exams, ensuring a fair and transparent evaluation process.`,

                                `With this software, students can take exams without having to be physically present in a specific location. This feature makes it convenient for students, especially those who live in remote areas or have a busy schedule. Additionally, the software saves teachers' time by automating the exam creation and grading process, allowing them to focus on other essential aspects of teaching.`,

                                `Online Exam Management Software is a powerful tool that offers flexibility, convenience, and efficiency to both students and teachers, making the exam process easier and more accessible for all.`,
                            ]}
                            image={NeedImg9}
                            caption={["Education"]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={9}
                            icon={Need10}
                            title={
                                "Robotic UV Sanitizer and Robotic Live Streaming"
                            }
                            content={[
                                `Robotic UV sanitizers and robotic live streaming are two technological advancements that have revolutionized how we live, work, and interact with the world around us.`,

                                `Robotic UV sanitizers are designed to eliminate harmful germs and bacteria from surfaces using ultraviolet light. This technology has become increasingly important in the wake of the COVID-19 pandemic as businesses and individuals seek ways to maintain a clean and safe environment. Robotic UV sanitizers can be used in hospitals, hotels, schools, offices, and other public spaces, providing a fast and effective solution to hygiene concerns. These robots can operate autonomously, reducing the risk of human error and increasing efficiency.`,

                                `On the other hand, robotic live streaming is a technology that enables remote broadcasting of events in real time. This technology has become increasingly popular in recent years, particularly in the entertainment industry. Robotic cameras can capture live events, such as concerts and sports games, and broadcast them to audiences worldwide. This technology provides a unique and immersive experience, enabling viewers to feel part of the action.`,
                            ]}
                            image={NeedImg16}
                            caption={["Education", "Healthcare", "Finance"]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={10}
                            icon={Need11}
                            title={"Standard Sswayam (CRM)"}
                            content={[
                                "Standard Sswayam CRM - the ultimate CRM software solution for businesses looking to streamline their sales and service operations. With the sales module, the managers can track field agents , their work, including expenses, daily sales reports, locations, leads collected, and more. Meanwhile, the service module allows businesses to manage customer queries, client tickets, SLA management, and admin management. Standard Sswayam CRM provides real-time data and insights, empowering businesses to make data-driven decisions and improve their bottom line. It's easy to use, customizable, and comes with automated reports and integrations with popular business tools.",
                            ]}
                            image={NeedImg11}
                            caption={[
                                "IT infrastructure support, Networking and Security.",
                            ]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={11}
                            icon={Need12}
                            title={"Sswayam CRM Pro+"}
                            content={[
                                "With Machine Learning (ML) and Artificial Intelligence (AI) we bring the power to revolutionize the way businesses operate in the modern world. These cutting-edge technologies are making it possible to analyze vast amounts of data and provide intelligent insights and predictions that can help drive business decisions. ML and AI algorithms are used in diverse industries, from healthcare and finance to retail and manufacturing, and can help automate mundane tasks, increase productivity, and reduce costs. With advancements in computing power and data storage capabilities, ML and AI are becoming more accessible to businesses of all sizes, unlocking new possibilities for growth and innovation.",
                            ]}
                            image={NeedImg12}
                            caption={[
                                "IT infrastructure support, Networking and Security.",
                            ]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={12}
                            icon={Need13}
                            title={"School Management"}
                            content={[
                                `School management software is a powerful tool that can significantly improve the management of educational institutions. It is designed to streamline and automate various school management tasks, including admissions, attendance tracking, grade reporting, scheduling, and communication. This software helps school administrators manage the institution's administrative and academic functions more effectively.`,

                                `One of the most significant benefits of this software is the time-saving feature. With this software, schools can automate many repetitive and time-consuming tasks previously performed manually. This helps free up staff time, enabling them to focus on more important tasks requiring attention. It also eliminates the need for paperwork, as everything can be stored digitally, which reduces the risk of data loss and damage.`,

                                `Another important benefit of school management software is the enhanced communication it offers. It allows parents, students, and teachers to communicate in real-time. It offers a platform for parents to stay updated on their children's academic progress, attendance records, and other important information about the school. SMS also enables teachers to share feedback with parents on their child's performance, allowing them to work together to improve academic outcomes`,
                            ]}
                            image={NeedImg13}
                            caption={["Education"]}
                            captionWidth={"200px"}
                        />
                        <TabContent
                            index={13}
                            icon={Need14}
                            title={"Verify Me"}
                            content={[
                                `Verify Me software is a valuable tool used by verifying companies to track the work of their field agents who conduct on-location verification tasks. With Verify Me, companies can monitor the status of ongoing verifications, view agent locations in real time, and track progress through Geotagging check-ins. The software allows field agents to document their work on the dashboard easily. This increases transparency and reduces the likelihood of errors or fraudulent activity. Verify Me also streamlines the verification process by automating report generation and submission, saving time and improving efficiency. Ultimately, Verify Me software helps verifying companies ensure the accuracy and integrity of their verification process while improving overall workflow and productivity.`,
                            ]}
                            image={NeedImg14}
                            caption={["Loan Verification", "HR Verification"]}
                            captionWidth={"200px"}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BusinessNeeds;
