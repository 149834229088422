import NavBar from "./components/NavBar.component";
import Slider from "./components/Slider.component";
import WeOffer from "./components/Home/WeOffer.component";
import Industory from "./components/Home/Industory.component";
import BookYourDemo from "./components/Home/BookYourDemo.component";
import Service from "./components/Home/Service.component";
import AvBusiness from "./components/Home/AvBusiness.component";
import Solutions from "./components/Home/Solutions.component";
import Clientele from "./components/Home/Clientele.component";
import Review from "./components/Home/Review.component";
import Blog from "./components/Home/Blog.component";
import Footer from "./components/Footer.component";

import Banner1 from "./assets/Banner1.svg";
import Banner2 from "./assets/Banner2.svg";
import Banner3 from "./assets/Banner3.svg";
import WhatYouGet from "./components/Solution/WhatYouGet.component";
import { useEffect, useRef } from "react";
import CustomScrollbar from "./components/CustomScrollbar.component";

function Home() {
    const sliderRef = useRef(null);

    // Scroll to slider on first render
    useEffect(() => {
        if (sliderRef.current) {
            window.scrollTo({
                top: sliderRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    }, []);

    return (
        <section>
            {/* NAVIGATION BAR */}
            <section>
                <NavBar />
            </section>

            {/* HERO SLIDER */}
            <section ref={sliderRef}>
                <Slider images={[Banner1, Banner2, Banner3]} />
            </section>

            {/* WHAT WE OFFER */}
            <section>
                <WeOffer />
            </section>

            {/* INDUSTRY */}
            <section>
                <Industory />
            </section>

            {/* BOOK YOUR DEMO */}
            <section>
                <BookYourDemo />
            </section>

            {/* SERVICE */}
            <section>
                <Service />
            </section>

            {/* AV BUSINESS */}
            <section>
                <AvBusiness />
            </section>

            {/* SOLITIONS */}
            <section>
                <Solutions />
            </section>

            {/* CLIENTELE */}
            <section>
                <Clientele />
            </section>

            {/* REVIEW */}
            <section>
                {/* <Review /> */}
                <WhatYouGet />
            </section>

            {/* FEATURED BLOGS */}
            <section>
                <Blog />
            </section>

            {/* FOOTER */}
            <section>
                <Footer />
            </section>

            <CustomScrollbar />
        </section>
    );
}

export default Home;
