import React, { useEffect, useRef } from 'react'
import NavBar from '../NavBar.component'
import Slider from '../Slider.component'
import BookDemo from '../BookDemo.component'
import SolutionVideo from './SolutionVideo.component'
import WhatYouGet from './WhatYouGet.component'
import ServiceBox1 from "../../assets/ServiceBox1.svg";

import Faqs from './Faqs.components'
import Footer from '../Footer.component'

import Banner4 from "../../assets/Banner4.svg";
import Bg4 from "../../assets/bg4.svg";
import TabContent from '../TabContent.component'

import Need14 from "../../assets/need-14.svg";
import NeedImg14 from "../../assets/NeedImg14.svg";
import Clientele from '../Home/Clientele.component'
import thumbImg from '../../assets/SolutionsImage/vectorImages/5041145.jpg'
import CustomScrollbar from '../CustomScrollbar.component'

let css = `
    .active-tab{
        color: #fff;
        background-color: #174997;
    }
    .active-tab img{
        filter: invert(1);
    }
`;

export default function VerifyMe() {
    let scrollRef = useRef();
    const serviceBoxRef = useRef(null);

    // Scroll to the ServiceBox section when the page loads
    useEffect(() => {
        serviceBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section className="">
                {/* NAVIGATION BAR */}
                <section className="">
                    <NavBar />
                </section>

                {/* NAV-CONTACT */}
                <section>{/* <NavContact/> */}</section>

                {/* HERO SLIDER */}
                <section>
                    <Slider images={[Banner4]} showDemoButton={true} />
                </section>

                {/* BOOK DEMO */}
                {/* <section>
                    <BookDemo
                        bgImage={Bg4}
                        title={
                            <>
                                Book a <span className="text-primary">Demo</span>{" "}
                            </>
                        }
                    />
                </section> */}

                {/* SOLUTION FOR YOUR UNIQUE BUSINESS NEEDS */}
                <section ref={serviceBoxRef}>
                    <section>
                        <style>{css}</style>
                        <div className="pt-16 custom-container">
                            <h1 className="text-center heading">
                                Solution for your unique business needs
                            </h1>
                            <p className="mt-3 text-sm text-center font-['montserrat']">
                                Explore our comprehensive range of solutions designed to
                                solve your specific challenges.
                            </p>
                        </div>
                        <div className="flex mb-12 mt-0 max-w-[80%] justify-center mx-auto items-center">
                            <div
                                className="p-5 max-h-[770px] overflow-y-auto scroll-smooth"
                                ref={scrollRef}
                            >
                                <TabContent
                                    index={13}
                                    icon={Need14}
                                    thumbnail={thumbImg}
                                    title={"Verify Me"}
                                    content={[
                                        `Verify Me software is a valuable tool used by verifying companies to track the work of their field agents who conduct on-location verification tasks. With Verify Me, companies can monitor the status of ongoing verifications, view agent locations in real time, and track progress through Geotagging check-ins. The software allows field agents to document their work on the dashboard easily. This increases transparency and reduces the likelihood of errors or fraudulent activity. Verify Me also streamlines the verification process by automating report generation and submission, saving time and improving efficiency. Ultimately, Verify Me software helps verifying companies ensure the accuracy and integrity of their verification process while improving overall workflow and productivity.`,
                                    ]}
                                    image={NeedImg14}
                                    caption={["Loan Verification", "HR Verification"]}
                                    captionWidth={"200px"}
                                />
                            </div>
                        </div>
                    </section>
                </section>
                {/* SOLUTION VIDEO */}
                <section>
                    <SolutionVideo
                        videoSrc="https://www.youtube.com/embed/cwzCvgsGnLY"
                        videoTitle="Verify Me"
                    />
                </section>

                {/* WHAT YOU GET */}
                {/* <section>
                    <WhatYouGet />
                </section> */}

                {/* CLIENTELE */}
                <section>
                    <Clientele />
                </section>

                {/* FAQS */}
                {/* <section>
                    <Faqs />
                </section> */}

                {/* FOOTER */}
                <section>
                    <Footer />
                </section>

                <CustomScrollbar />
            </section>
        </>
    )
}
