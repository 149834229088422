import NavBar from "./components/NavBar.component";
import Slider from "./components/Slider.component";
import BookDemo from "./components/BookDemo.component";
import OurAddress from "./components/ContactUs/OurAddress.component";
import GetInTouch from "./components/ContactUs/GetInTouch.component";
import Footer from "./components/Footer.component";

import Banner8 from "./assets/Banner8.svg";

import Bg7 from "./assets/bg7.svg";
import { useEffect, useRef } from "react";
import CustomScrollbar from "./components/CustomScrollbar.component";

function ContactUs() {
    const sliderRef = useRef(null);

    // Scroll to slider on first render
    useEffect(() => {
        if (sliderRef.current) {
            window.scrollTo({
                top: sliderRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    }, []);

    return (
        <section className="">
            {/* NAVIGATION BAR */}
            <section className="">
                <NavBar />
            </section>

            {/* NAV-CONTACT */}
            <section>{/* <NavContact /> */}</section>

            {/* HERO SLIDER */}
            <section ref={sliderRef}>
                <Slider images={[Banner8]} />
            </section>

            {/* BOOK DEMO */}
            <section>
                <BookDemo
                    bgImage={Bg7}
                    title={
                        <>
                            Book a <span className="text-primary">Demo</span>{" "}
                        </>
                    }
                />
            </section>

            {/* OUR ADDRESS */}
            <section>
                <OurAddress />
            </section>

            {/* GET IN TOUCH */}
            <section>
                <GetInTouch />
            </section>

            {/* FOOTER */}
            <section>
                <Footer />
            </section>

            <CustomScrollbar />
        </section>
    );
}

export default ContactUs;
